import { Path, useMatches } from 'react-router';
import { z } from 'zod';

const schema = z.object({
  environmentSettingsMenuItems: z
    .function()
    .args(z.any())
    .returns(
      z.object({
        header: z.string(),
        items: z.array(
          z.object({
            label: z.string(),
            to: z
              .object({
                pathname: z.string(),
                search: z.string(),
                hash: z.string(),
              })
              .optional(),
            onAction: z.function().returns(z.void()).optional(),
          }),
        ),
      }),
    )
    .optional(),
});

export function useEnvironmentMenuItems() {
  const matches = useMatches();
  const reversedMatches = [...matches].reverse();

  let section: { header: string; items: Array<{ label: string; to?: Path; onAction?: () => void }> } | undefined;
  for (const match of reversedMatches) {
    const parse = schema.safeParse(match.handle);
    if (parse.success) {
      if (parse.data.environmentSettingsMenuItems !== undefined) {
        const { header, items } = parse.data.environmentSettingsMenuItems({ data: match.data });
        section = { header, items };
      }
    }
  }

  if (section === undefined) {
    return;
  }

  return section;
}

import { matchRoutes, NavLink, RouteObject, useLocation } from 'react-router';
import { matchGetStarted, matchInviteMembers, matchQuickstart, matchSetupCLI } from '@gonfalon/navigator';
import cx from 'clsx';

import 'stylesheets/components/Breadcrumbs.css';

export type BreadcrumbsProps = {
  breadcrumbs: RouteObject[];
};

export function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  const { pathname } = useLocation();
  const location = useLocation();
  const routes = matchRoutes(breadcrumbs, pathname);
  const inIAExceptQuickstart = !(
    matchGetStarted(location) ||
    matchQuickstart(location) ||
    matchSetupCLI(location) ||
    matchInviteMembers(location)
  );

  if (!routes || inIAExceptQuickstart) {
    return null;
  }

  return (
    <nav aria-label="Breadcrumb navigation for this page." className="Breadcrumbs">
      {routes.map((route, index) => (
        <NavLink
          key={route.pathnameBase}
          className={({ isActive }) => cx('Breadcrumbs-item', { 'Breadcrumbs-item--active': isActive })}
          to={{ pathname: route.pathnameBase }}
          end={index < routes.length - 1}
        >
          {route.route.element}
        </NavLink>
      ))}
    </nav>
  );
}

import { useEffect } from 'react';
import { ProgressBar } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import cx from 'clsx';
import { IconButton } from 'launchpad';

import { CounterHeader } from 'components/ui/counterHeader';
import { PaginationClick, trackPaginationEvent } from 'utils/paginationUtils';

import './styles.css';

// Pagination control that is agnostic of backend pagination
// will be pluralized based on count
const PaginationResourceLabels = {
  applications: 'application',
  applicationVersions: 'version',
  approvals: 'approval',
  environments: 'environment',
  experiments: 'experiment',
  holdouts: 'holdout',
  flags: 'flag',
  members: 'member',
  payloadFilters: 'payload filter',
  projects: 'project',
  releasePipelines: 'release pipeline',
  segments: 'segment',
  teams: 'team',
  userFlags: 'flag',
  metricsAndGroups: 'metrics and metric group',
  lastSeenMetricEvents: 'last seen metric event',
  monitoringIssues: 'issue',
  monitoringErrors: 'error',
  monitoringIssueBreadcrumbs: 'breadcrumb',
  roles: 'role',
};

type PaginationResource = keyof typeof PaginationResourceLabels;

export type PaginationControlProps = {
  currentPageSize: number;
  isReady: boolean;
  limit: number;
  offset: number;
  onPageClick: (actionHint: PaginationClick) => void;
  resourceKind: PaginationResource;
  totalCount?: number;
  className?: string;
  hasInfinitePagination?: boolean;
};

function PaginationControl({
  currentPageSize,
  offset,
  isReady,
  limit,
  onPageClick,
  resourceKind,
  totalCount,
  className,
  hasInfinitePagination = false,
}: PaginationControlProps) {
  const hasNoResults = hasInfinitePagination ? !currentPageSize : !totalCount;
  const nextPageOffset = offset + limit;
  const previousPageOffset = Math.max(offset - limit, 0);
  const hasPreviousPage = previousPageOffset >= 0 && offset > 0;
  const hasNextPage = hasInfinitePagination ? currentPageSize >= limit : nextPageOffset < (totalCount ?? 0);
  const renderPaginationText = () => {
    const from = offset + 1;
    const to = !hasInfinitePagination
      ? totalCount && Math.min(offset + currentPageSize, totalCount)
      : offset + currentPageSize || 0;
    if (!isReady) {
      return (
        <div>
          <ProgressBar aria-label="Loading…" isIndeterminate />
        </div>
      );
    }

    if (hasNoResults) {
      return <strong data-test-id="pagination-no-results">No results</strong>;
    }

    return (
      <div className="PaginationText">
        <strong>
          {from}-{to}
        </strong>
        {!hasInfinitePagination && (
          <>
            {' '}
            of <strong>{totalCount}</strong>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={cx('PaginationControl', className)}>
      <div className="PaginationControl--body">
        <IconButton
          disabled={!hasPreviousPage}
          className={hasPreviousPage ? '' : 'PaginationControl--disabled'}
          onClick={() => onPageClick(PaginationClick.FIRST)}
          size="small"
          icon={<Icon name="chevrons-left" size="small" />}
          aria-label={`first ${resourceKind} page`}
        />
        <IconButton
          disabled={!hasPreviousPage}
          className={hasPreviousPage ? '' : 'PaginationControl--disabled'}
          onClick={() => onPageClick(PaginationClick.PREV)}
          size="small"
          icon={<Icon name="chevron-left" size="small" />}
          aria-label={`previous ${resourceKind} page`}
        />
        {renderPaginationText()}
        <IconButton
          disabled={!hasNextPage}
          className={hasNextPage ? '' : 'PaginationControl--disabled'}
          onClick={() => onPageClick(PaginationClick.NEXT)}
          size="small"
          icon={<Icon name="chevron-right" size="small" />}
          aria-label={`next ${resourceKind} page`}
        />
        <IconButton
          disabled={!hasNextPage || hasInfinitePagination}
          className={hasNextPage || !hasInfinitePagination ? '' : 'PaginationControl--disabled'}
          onClick={() => onPageClick(PaginationClick.LAST)}
          size="small"
          icon={<Icon name="chevrons-right" size="small" />}
          aria-label={`last ${resourceKind} page`}
        />
      </div>
    </div>
  );
}

export type PaginationControlWrapperProps = Omit<PaginationControlProps, 'onPageClick'> & {
  children?: React.ReactNode;
  firstPageOffset?: number;
  prevPageOffset?: number;
  nextPageOffset?: number;
  lastPageOffset?: number;
  onPageClick: (actionHint: PaginationClick, newOffset: number) => void;
  limit: number;
  disableAutomaticBottomPagination?: boolean;
  disableAutomaticTopPagination?: boolean;
  isInfinitePagination?: boolean;
};

export function PaginationControlWrapper({
  children,
  currentPageSize,
  isReady,
  prevPageOffset,
  nextPageOffset,
  lastPageOffset,
  limit,
  offset,
  onPageClick,
  resourceKind,
  totalCount,
  className,
  disableAutomaticBottomPagination,
  disableAutomaticTopPagination = false,
  hasInfinitePagination = false,
}: PaginationControlWrapperProps) {
  useEffect(() => {
    trackPaginationEvent('view');
  }, []);

  const itemCountWithinLimit = totalCount && totalCount <= limit;
  const shouldRenderBottomPaginationControl = !itemCountWithinLimit && children && !disableAutomaticBottomPagination;

  const calculateNewOffset = (direction: PaginationClick) => {
    if (direction === PaginationClick.FIRST) {
      return 0;
    } else if (direction === PaginationClick.PREV) {
      return prevPageOffset || 0;
    } else if (direction === PaginationClick.NEXT) {
      return nextPageOffset || 0;
    } else if (direction === PaginationClick.LAST) {
      return lastPageOffset || 0;
    }
    return 0;
  };

  return (
    <>
      {!disableAutomaticTopPagination && (
        <>
          {itemCountWithinLimit ? (
            <CounterHeader
              className={cx('PaginationControl-CounterHeader', className)}
              resource={PaginationResourceLabels[resourceKind]}
              resourceCount={totalCount}
            />
          ) : (
            <PaginationControl
              offset={offset}
              currentPageSize={currentPageSize}
              resourceKind={resourceKind}
              isReady={isReady}
              onPageClick={(direction: PaginationClick) => onPageClick(direction, calculateNewOffset(direction))}
              totalCount={totalCount}
              limit={limit}
              className={className}
              hasInfinitePagination={hasInfinitePagination}
            />
          )}
        </>
      )}
      {children}
      {shouldRenderBottomPaginationControl && (
        <PaginationControl
          offset={offset}
          currentPageSize={currentPageSize}
          resourceKind={resourceKind}
          isReady={isReady}
          onPageClick={(direction: PaginationClick) => onPageClick(direction, calculateNewOffset(direction))}
          totalCount={totalCount}
          limit={limit}
          className={className}
          hasInfinitePagination={hasInfinitePagination}
        />
      )}
    </>
  );
}

import qs from 'qs';

import { FilterType, UsageType } from 'components/usage/types';
import http, { middleware as mw } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import Logger from 'utils/logUtils';
import { convertMauFilters, SDKVersion } from 'utils/usageUtils';

const logger = Logger.get('UsageAPI');

export const getStreamUsage = async (
  filters: FilterType,
  { typeOfUsage }: { typeOfUsage: UsageType },
): Promise<RechartsData> =>
  http
    .get(`/api/v2/usage/streams/${typeOfUsage}?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

export const getStreamUsageBySdkVersion = async (
  filters: FilterType,
  { typeOfUsage }: { typeOfUsage: UsageType },
): Promise<RechartsData> =>
  http
    .get(`/api/v2/usage/streams/${typeOfUsage}/bysdkversion?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

export const getReceivedEventUsage = async (filters: FilterType) =>
  http
    .get(`/api/v2/usage/events/received?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

export const getPublishedEventUsage = async (filters: FilterType) =>
  http
    .get(`/api/v2/usage/events/published?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

export const getSdkVersions = async (typeOfUsage: UsageType): Promise<SdkVersionsData> =>
  http.get(`/api/v2/usage/streams/${typeOfUsage}/sdkversions`, { beta: true }).then(mw.json).catch(mw.jsonError);

export const getMauSdks = async (typeOfUsage: UsageType, filters: FilterType): Promise<SdkTypeData> =>
  http
    .get(`/api/v2/usage/mau/sdks?sdktype=${typeOfUsage}&${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

type MauUsageOptions = { renderTotal?: boolean };

type GetMauUsageType = (filters: FilterType, options: MauUsageOptions) => Promise<RechartsData>;

export type RechartsSeriesItem = {
  [key: string]: string | number | undefined;
  time?: number;
};

export type SdkVersionsType = {
  sdk: string;
  version: string;
};

export type RechartsData = {
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
  }>;
  metadata: SDKVersion[];
  series: RechartsSeriesItem[];
};

export type SdkVersionsData = {
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
  }>;
  sdkVersions: SdkVersionsType[];
};

export type SdkTypeData = {
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
  }>;
  sdks: string[];
};

export const getMauUsage: GetMauUsageType = async (filters, { renderTotal }) => {
  const convertedFilters = convertMauFilters(filters, renderTotal);
  const url = `/api/v2/usage/mau?${qs.stringify(convertedFilters)}`;
  logger.log(`Fetching new mau usage url: ${url}`);

  const newData = await http.get(url, { beta: true }).then(mw.json).catch(mw.jsonError);

  return newData;
};

export const getFlagEvaluations = async (
  filters: FilterType,
  { projKey, envKey, flagKey }: { projKey?: string; envKey?: string; flagKey?: string },
) =>
  http
    .get(`/api/v2/usage/evaluations/${projKey}/${envKey}/${flagKey}?${qs.stringify(filters)}`, { beta: true })
    .then(mw.json)
    .catch(mw.jsonError);

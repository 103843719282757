import { type AnalyticEventData } from '@gonfalon/analytics';
import { isEmpty } from '@gonfalon/es6-utils';
import { Member as MemberCore } from '@gonfalon/members';
import { checkAccess } from '@gonfalon/permissions';
import { FlagImportIntegration } from '@gonfalon/rest-api';
// eslint-disable-next-line no-restricted-imports
import { fromJS, is, List, Map, OrderedMap, Record } from 'immutable';

import { AZURE, KINESIS, MPARTICLE, PUBSUB, SEGMENT, SNOWFLAKE, SNOWFLAKE_V2 } from 'components/integrations/constants';
import { AccessChecks, allowDecision, createAccessDecision } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';
import { trackIntegrationEvent } from 'utils/analyticsUtils';
import { createEnvironment, Environment, getProjectForEnv } from 'utils/environmentUtils';
import { manifestMapIsTriggerOnly, ManifestRecord } from 'utils/goaltenderUtils';
import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { isPolicyString, PolicyStatement } from 'utils/policyUtils';
import { createProject, Project } from 'utils/projectUtils';
import { isAbsoluteURL, isValidURLPort } from 'utils/urlUtils';
import {
  ImmutableValidationResults,
  isChecked,
  isJSON,
  isLength,
  isNotEmpty,
  optional,
  validateRecord,
} from 'utils/validationUtils';

export type FlagImportAction =
  | 'createIntegration'
  | 'updateIntegration'
  | 'deleteIntegration'
  | 'updateName'
  | 'updateConfiguration';

export function checkFlagImportIntegrationAccess(
  member: MemberCore,
  action: FlagImportAction,
  flagImportIntegration?: FlagImportIntegration,
) {
  return checkAccess(member, flagImportIntegration?._access, action);
}

export const InfoTag = {
  beta: { key: 'beta', displayText: 'Beta' },
  new: { key: 'new', displayText: 'New' },
  partner: { key: 'partner', displayText: 'Partner-built' },
  enterprise: { key: 'enterprise', displayText: 'Enterprise' },
  dataExport: { key: 'data-export', displayText: 'Add-On' },
};

type SlackIncomingHookProps = {
  _access: AccessChecks | null;
  _links: ImmutableMap<{
    self: Link;
  }>;
  _id: string;
  name: string;
  url: string;
  statements: PolicyStatement[] | null;
  on: boolean;
  tags: List<string>;
};

export class SlackIncomingHook extends Record<SlackIncomingHookProps>({
  _access: null,
  _links: Map(),
  _id: '',
  name: '',
  url: '',
  statements: null,
  on: true,
  tags: List(),
}) {
  checkAccess({ profile }: { profile: Member }) {
    const access = this.get('_access');

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action: string) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }
  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  toForm() {
    return createSlackIncomingHookForm({
      name: this.name,
      url: this.url,
      statements: this.statements ? JSON.stringify(this.statements, null, 2) : '',
      on: this.on,
      tags: this.tags,
      // We only want to get privacy policy approval for new subscriptions. If we already have a subscription
      // then we can assume the privacy policy has been approved
      privacyPolicyApproved: true,
    });
  }

  mergeForm(form: SlackIncomingHookForm) {
    return this.withMutations((hook) =>
      hook.merge({
        name: form.name,
        url: form.url,
        statements: isEmpty(form.statements) ? null : JSON.parse(form.statements),
        on: form.on,
        tags: form.tags,
      }),
    );
  }
}

type SlackIncomingHookFormProps = {
  name: string;
  url: string;
  statements: string;
  on: boolean;
  tags: List<string>;
  privacyPolicyApproved: boolean;
};

export class SlackIncomingHookForm extends Record<SlackIncomingHookFormProps>({
  name: '',
  url: '',
  statements: '',
  on: true,
  tags: List(),
  privacyPolicyApproved: false,
}) {
  validate() {
    return validateRecord(
      this,
      isChecked('privacyPolicyApproved'),
      optional(isNotEmpty)('name'),
      isLength(0, 100)('name'),
      isNotEmpty('url'),
      isAbsoluteURL('url'),
      isValidURLPort('url'),
      optional(isJSON)('statements'),
      optional(isPolicyString)('statements'),
    );
  }

  is(other: SlackIncomingHookForm) {
    try {
      return (
        this.name === other.name &&
        this.url === other.url &&
        ((isEmpty(this.statements) && isEmpty(other.statements)) ||
          is(fromJS(JSON.parse(this.statements)), fromJS(JSON.parse(other.statements)))) &&
        this.on === other.on &&
        is(this.tags, other.tags)
      );
    } catch (e) {
      // could fail to parse as JSON
      return false;
    }
  }

  toSlackIncomingHook() {
    const statements = this.statements !== '' ? JSON.parse(this.statements) : null;
    return createSlackIncomingHook({
      name: this.name,
      url: this.url,
      statements: statements && statements.length ? statements : null,
      on: this.on,
      tags: this.tags,
    });
  }
}

export function createSlackIncomingHook(props: CreateFunctionInput<SlackIncomingHook> = {}) {
  return props instanceof SlackIncomingHook ? props : new SlackIncomingHook(fromJS(props));
}

export function createSlackIncomingHookForm(props: CreateFunctionInput<SlackIncomingHookForm> = {}) {
  return props instanceof SlackIncomingHookForm ? props : new SlackIncomingHookForm(fromJS(props));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DestinationConfig<T extends Record<any> = any> = T & {
  validate?: () => ImmutableValidationResults;
};

type DestinationProps = {
  _access: AccessChecks | null;
  _links: ImmutableMap<{
    self: Link;
  }>;
  _id: string;
  name: string;
  environment: Environment;
  project: Project;
  kind: string;
  config: DestinationConfig;
  on: boolean;
  version: number;
  privacyPolicyApproved: boolean;
};

export class Destination extends Record<DestinationProps>({
  _access: null,
  _links: Map(),
  _id: '',
  name: '',
  environment: createEnvironment(),
  project: createProject(),
  kind: '',
  config: Map(),
  on: true,
  version: 0,
  privacyPolicyApproved: false,
}) {
  checkAccess({ profile }: { profile: Member }) {
    const access = this.get('_access');

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action: string) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  selfLink() {
    return this.getIn(['_links', 'self', 'href']);
  }

  validate() {
    const baseValidation = validateRecord(
      this,
      isChecked('privacyPolicyApproved'),
      optional(isNotEmpty)('name'),
      isLength(0, 100)('name'),
    );

    const envValidation = this.environment.validate();
    const projValidation = this.project.validate();

    if (!this.config?.validate) {
      return baseValidation.merge(
        envValidation.mapKeys((key: string) => `environment.${key}`),
        projValidation.mapKeys((key: string) => `project.${key}`),
      );
    }

    const configValidation = this.config?.validate();
    return baseValidation.merge(
      configValidation.mapKeys((key: string) => `config.${key}`),
      envValidation.mapKeys((key: string) => `environment.${key}`),
      projValidation.mapKeys((key: string) => `project.${key}`),
    );
  }

  toRep() {
    return fromJS(this.toJSON()).withMutations((map: Destination) => {
      map.delete('environment');
      map.delete('project');
    });
  }
}

type KinesisDestinationConfigProps = {
  region: string;
  roleArn: string;
  streamName: string;
};

class KinesisDestinationConfig extends Record<KinesisDestinationConfigProps>({
  region: '',
  roleArn: '',
  streamName: '',
}) {
  validate() {
    return validateRecord(this, isNotEmpty('region'), isNotEmpty('roleArn'), isNotEmpty('streamName'));
  }
}

type PubsubDestinationConfigProps = {
  topic: string;
  project: string;
};

class PubsubDestinationConfig extends Record<PubsubDestinationConfigProps>({
  topic: '',
  project: '',
}) {
  validate() {
    return validateRecord(this, isNotEmpty('topic'), isNotEmpty('project'));
  }
}

export type ContextToMParticleMap = Record<{
  ldContextKind: string;
  mparticleUserIdentity: string;
}>;

type MParticleDestinationConfigProps = {
  environment: string;
  apiKey: string;
  secret: string;
  userIdentity: string | undefined;
  anonymousUserIdentity: string | undefined;
  userIdentities: List<ContextToMParticleMap> | undefined;
};

class MParticleDestinationConfig extends Record<MParticleDestinationConfigProps>({
  environment: '',
  apiKey: '',
  secret: '',
  userIdentity: undefined,
  anonymousUserIdentity: undefined,
  userIdentities: undefined,
}) {
  validate() {
    const baseValidation = validateRecord(this, isNotEmpty('environment'), isNotEmpty('apiKey'), isNotEmpty('secret'));
    const contextValidation = validateRecord(this, isNotEmpty('userIdentities'));

    return baseValidation.merge(contextValidation.mapKeys((key: string) => key));
  }
}

type SegmentDestinationConfigProps = {
  writeKey: string;
  anonymousIDContextKind: string;
  userIDContextKind: string;
};

class SegmentDestinationConfig extends Record<SegmentDestinationConfigProps>({
  writeKey: '',
  anonymousIDContextKind: '',
  userIDContextKind: '',
}) {
  validate() {
    const baseValidation = validateRecord(this, isNotEmpty('writeKey'));

    const contextValidation = validateRecord(
      this,
      isNotEmpty('anonymousIDContextKind'),
      isNotEmpty('userIDContextKind'),
    );

    return baseValidation.merge(contextValidation.mapKeys((key: string) => key));
  }
}

type AzureDestinationConfigProps = {
  namespace: string;
  name: string;
  policyName: string;
  policyKey: string;
};

class AzureDestinationConfig extends Record<AzureDestinationConfigProps>({
  namespace: '',
  name: '',
  policyName: '',
  policyKey: '',
}) {
  validate() {
    return validateRecord(
      this,
      isNotEmpty('namespace'),
      isNotEmpty('name'),
      isNotEmpty('policyName'),
      isNotEmpty('policyKey'),
    );
  }
}

type SnowflakeDestinationConfigProps = {
  accountId: string;
};

class SnowflakeDestinationConfig extends Record<SnowflakeDestinationConfigProps>({
  accountId: '',
}) {
  validate() {
    return validateRecord(this, isNotEmpty('accountId'));
  }
}

type SnowflakeV2DestinationConfigProps = {
  snowflakeHostAddress: string;
  environment: Environment;
};

class SnowflakeV2DestinationConfig extends Record<SnowflakeV2DestinationConfigProps>({
  snowflakeHostAddress: '',
  environment: createEnvironment(),
}) {
  validate() {
    return validateRecord(this, isNotEmpty('snowflakeHostAddress'));
  }
}

export function createDestination(props: CreateFunctionInput<Destination> = {}) {
  const destination = props instanceof Destination ? props : new Destination(fromJS(props));
  return destination.update('config', (c) => {
    switch (destination.kind) {
      case KINESIS:
        return new KinesisDestinationConfig(c);
      case PUBSUB:
        return new PubsubDestinationConfig(c);
      case MPARTICLE:
        return new MParticleDestinationConfig(c);
      case SEGMENT:
        return new SegmentDestinationConfig(c);
      case AZURE:
        return new AzureDestinationConfig(c);
      case SNOWFLAKE:
        return new SnowflakeDestinationConfig(c);
      case SNOWFLAKE_V2:
        return new SnowflakeV2DestinationConfig(c);
      default:
        return c;
    }
  });
}

export const getLabelForDestinationKind = (kind: string) => {
  switch (kind) {
    case KINESIS:
      return 'Amazon Kinesis';
    case PUBSUB:
      return 'Google Cloud PubSub';
    case MPARTICLE:
      return 'mParticle';
    case SEGMENT:
      return 'Segment';
    case AZURE:
      return 'Azure Event Hubs';
    case SNOWFLAKE:
      return 'Snowflake';
    case SNOWFLAKE_V2:
      return 'Snowflake Export';
    default:
      return '';
  }
};

export const getProjAndEnvKeysFromEnv = (env: Environment, projects: OrderedMap<string, Project>) => {
  const project = getProjectForEnv(env, projects);
  const projKey = project?.key;
  return [projKey, env.key];
};

export const getProjAndEnvKeysFromSelfLink = (link: string) => {
  const destination = link.split('destinations')[1];
  const projKey = destination.split('/')[1];
  const envKey = destination.split('/')[2];
  return [projKey, envKey];
};

export const getProjAndEnvFromSelfLink = (
  projects: OrderedMap<string, Project>,
  environments: OrderedMap<string, Environment>,
  link: string,
) => {
  if (!link) {
    return {};
  }
  const [projKey, envKey] = getProjAndEnvKeysFromSelfLink(link);
  if (projKey && envKey) {
    const proj = projects.get(projKey);
    const matchingEnvs = environments.filter((e) => e.key === envKey);
    if (proj) {
      const env = matchingEnvs.find((e) => proj.environments.includes(e._id));
      if (env) {
        return { proj, env };
      }
    }
  }
  return {};
};

export const addIntegrationTracker = (attributes: AnalyticEventData) =>
  trackIntegrationEvent('Add Integration Button Clicked', attributes);
export const editIntegrationTracker = (attributes: AnalyticEventData) =>
  trackIntegrationEvent('Edit Integration Button Clicked', attributes);
export const subscriptionErrorLogTracker = (attributes: AnalyticEventData) =>
  trackIntegrationEvent('View Subscription Error Log Button Clicked', attributes);
export const confirmIntegrationTracker = (attribute: AnalyticEventData) =>
  trackIntegrationEvent('Confirm Integration Button Clicked', attribute);
export const learnMoreTracker = (attributes: AnalyticEventData) =>
  trackIntegrationEvent('Learn More Button Clicked', attributes);
export const deleteIntegrationTracker = (attribute: AnalyticEventData) =>
  trackIntegrationEvent('Delete Integration Button Clicked', attribute);

/**
 * Returns the categories with hypens removed
 *
 * @param {Array<string>} catagories array of categories
 */
export const unhyphenatedCategories = (catagories: string[]) =>
  catagories.map((c) => {
    const unhyphenated = c.replaceAll('-', ' ');
    return unhyphenated[0].toUpperCase() + unhyphenated.substring(1, unhyphenated.length);
  });

/**
 * Returns summarized data to be displayed on integration cards in the UI.
 *
 * @param {ManifestRecord} manifest immutable manifest object
 */
export const parseManifestSummary = (manifest: ManifestRecord) => ({
  name: manifestMapIsTriggerOnly(manifest) ? `${manifest.get('name')} trigger` : manifest.get('name'),
  overview: manifest.get('overview'),
  // the `ManifestRecord` type doesn't seem right; it makes it seem as if it's not immutable, but it's clearly expected to be immutable here
  isBeta: (manifest.get('additionalFields') as unknown as Map<string, string>).get('status') === InfoTag.beta.key,
  isNew: (manifest.get('additionalFields') as unknown as Map<string, string>).get('status') === InfoTag.new.key,
  isPartner: (manifest.get('additionalFields') as unknown as Map<string, string>).get('status') === InfoTag.partner.key,
  categories: unhyphenatedCategories((manifest.get('categories') as unknown as List<string>).toJS()),
  upsellKind: (manifest.get('additionalFields') as unknown as Map<string, string>).get('upsellKind') || 'none',
});

import cx from 'clsx';
import { Counter } from 'launchpad';

import { DataListToolbar } from 'components/ui/datalist';
import { Cell, Grid } from 'components/ui/grid';
import { AlignValue, GridLayoutValue } from 'components/ui/grid/types';

import './styles.css';

type TableHeaderToolbarProps = {
  renderSearch?(): JSX.Element;
  counterValue?: number;
  title?: string | JSX.Element;
  description?: React.ReactNode;
  headerButton?: React.ReactNode;
  customHeader?: boolean;
  showBorder?: boolean;
  renderSecondRow?(): React.ReactNode;
  children?: React.ReactNode;
  layout?: GridLayoutValue;
  className?: string;
};

/* eslint-disable import/no-default-export */
export default function TableHeaderToolbar({
  children,
  counterValue,
  title,
  description,
  customHeader = false,
  headerButton,
  showBorder,
  renderSearch,
  renderSecondRow,
  layout = GridLayoutValue.AUTO,
  className,
}: TableHeaderToolbarProps) {
  return (
    <DataListToolbar showBorder={showBorder} className={className}>
      {title && (
        <Grid className={cx('u-pt-m', { 'u-mb-m': !description })}>
          <Cell>
            <header className={cx({ 'TableHeader-title': !customHeader })}>
              <h2>{title}</h2>
              {!customHeader && (
                <span className="u-xs-ph-m" data-test-id="counter-value">
                  {counterValue ? <Counter className="TableHeader-counter" value={counterValue} subtle /> : ''}
                </span>
              )}
            </header>
          </Cell>
          {headerButton}
        </Grid>
      )}
      {description && (
        <Grid>
          <Cell className="TableHeader-description">{description}</Cell>
        </Grid>
      )}
      <Grid align={AlignValue.MIDDLE} layout={layout} className={cx('u-pb-m', { 'u-pt-m ': !title })}>
        {renderSearch && renderSearch()}
        {children}
      </Grid>
      {renderSecondRow && <Grid className="TableHeaderToolbar-secondRow">{renderSecondRow()}</Grid>}
    </DataListToolbar>
  );
}

import { useStore } from 'react-redux';
import { useNavigate } from 'react-router';
import { kebabCase } from '@gonfalon/es6-utils';
import { toTeamMembers, toTeams } from '@gonfalon/navigator';
import { GetTeamQueryParams } from '@gonfalon/openapi';
import { OrderedMap, Set } from 'immutable';
import { Dispatch } from 'redux';

import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch } from 'reducers';
import {
  deleteTeam,
  getTeam,
  getTeamCustomRoles,
  getTeamMaintainers,
  getTeams,
  patchTeam,
  postTeam,
  postTeamMembers,
  teamExpandOptions,
} from 'sources/TeamsAPI';
import { MemberSummary } from 'utils/accountUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import Logger from 'utils/logUtils';
import { createMemberFilters } from 'utils/memberUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { TeamsBulkAddMembersResponse } from 'utils/teamsBulkAddMembersUtils';
import {
  createTeamsFilters,
  getAddMaintainersSemanticPatchInstructions,
  getRemoveMaintainerSemanticPatchInstructions,
  getTeamsSemanticPatchInstructions,
  Team,
  TeamCustomRolesFilters,
  TeamForm,
  TeamInstructionKindForMultipleValueResources,
  TeamMaintainerSummary,
  TeamsFilters,
  TeamsSemanticPatchInstructions,
} from 'utils/teamsUtils';

import { fetchMembersByTeam } from './members';

const logger = Logger.get('actions/teams');

const requestTeams = () => ({ type: 'teams/REQUEST_TEAMS' }) as const;
const receiveTeams = (response: OrderedMap<string, Team>) => ({ type: 'teams/RECEIVE_TEAMS', response }) as const;
const requestTeamsFailed = (error: ImmutableServerError) => ({ type: 'teams/REQUEST_TEAMS_FAILED', error }) as const;

export function fetchTeams(filters: TeamsFilters, expandOptions?: teamExpandOptions[]) {
  return async (dispatch: Dispatch) => {
    dispatch(requestTeams());
    try {
      const response = await getTeams(filters, expandOptions);
      return dispatch(receiveTeams(response));
    } catch (error) {
      dispatch(requestTeamsFailed(error as ImmutableServerError));
    }
  };
}

const requestMoreTeamMaintainers = () => ({ type: 'teams/REQUEST_MORE_TEAM_MAINTAINERS' }) as const;
const requestMoreTeamMaintainersDone = (summary: TeamMaintainerSummary) =>
  ({ type: 'teams/REQUEST_MORE_TEAM_MAINTAINERS_DONE', summary }) as const;
const requestMoreTeamMaintainersFailed = () => ({ type: 'teams/REQUEST_MORE_TEAM_MAINTAINERS_FAILED' }) as const;

export function fetchMoreTeamMaintainers(team: Team) {
  return async (dispatch: Dispatch) => {
    dispatch(requestMoreTeamMaintainers());

    try {
      const response = await getTeamMaintainers({
        teamKey: team.key,
        nextPageLink: team.maintainers._links.next,
      });
      return dispatch(requestMoreTeamMaintainersDone(response));
    } catch (error) {
      dispatch(requestMoreTeamMaintainersFailed());
    }
  };
}

// If the customer has teams we always need to have at least one team in our store so we can check for create team access
// This prevents the create button from flashing on/off if the customer is filtering
// This is a workaround that should be removed if we ever have a better way to determine custom role create access checking

const requestTeamForAccessCheck = () => ({ type: 'teams/REQUEST_TEAM_ACCESS_CHECK' }) as const;
const receiveTeamForAccessCheck = (response: OrderedMap<string, Team>) =>
  ({ type: 'teams/RECEIVE_TEAM_ACCESS_CHECK', response }) as const;
const requestTeamForAccessCheckFailed = (error: ImmutableServerError) =>
  ({ type: 'teams/REQUEST_TEAM_ACCESS_CHECK_FAILED', error }) as const;

export function fetchTeamForAccessCheck() {
  return async (dispatch: Dispatch) => {
    dispatch(requestTeamForAccessCheck());
    try {
      // fetch only one team since we only require it for access checking
      const response = await getTeams(createTeamsFilters({ limit: 1 }));
      return dispatch(receiveTeamForAccessCheck(response));
    } catch (error) {
      dispatch(requestTeamForAccessCheckFailed(error as ImmutableServerError));
    }
  };
}

const requestTeam = () => ({ type: 'teams/REQUEST_TEAM' }) as const;
const receiveTeam = (team: Team) => ({ type: 'teams/RECEIVE_TEAM', team }) as const;
const requestTeamFailed = (error: ImmutableServerError) => ({ type: 'teams/REQUEST_TEAM_FAILED', error }) as const;

export function useFetchTeam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (teamKey: string, query?: GetTeamQueryParams) => {
    dispatch(requestTeam());
    try {
      const response = await getTeam(teamKey, query);
      return dispatch(receiveTeam(response));
    } catch (error) {
      const err = error as ImmutableServerError;
      if (err.get('status') === 403 || err.get('status') === 404) {
        await navigate(toTeams());
      }
      dispatch(requestTeamFailed(err));
    }
  };
}

const create = (team: TeamForm) => ({ type: 'teams/CREATE_TEAM', team }) as const;
const createDone = (team: Team) => ({ type: 'teams/CREATE_TEAM_DONE', team }) as const;
const createFailed = (team: TeamForm, error: ImmutableServerError) =>
  ({ type: 'teams/CREATE_TEAM_FAILED', team, error }) as const;

export function useCreateTeam() {
  const dispatch = useDispatch();
  const store = useStore();
  const navigate = useNavigate();

  return async (filters: TeamsFilters) => {
    const formState = store.getState().teamsForm;
    const { modified } = formState;
    const teamsUrl = toTeams({ search: filters.toQueryString() });
    const teamUrl = toTeamMembers({ teamKey: modified.key });
    dispatch(create(modified));
    return postTeam(modified)
      .then((created) => {
        dispatch(createDone(created));
      })
      .then(async () => dispatch(fetchTeams(filters)))
      .then(async () => {
        await navigate(teamUrl);
      })
      .catch(async (error) => {
        if (error.get('status') === 403 || error.get('status') === 404) {
          await navigate(teamsUrl);
        }
        dispatch(createFailed(modified, error));
      });
  };
}

const edit = (field: string, team: TeamForm) => ({ type: 'teams/EDIT_TEAM', field, team }) as const;

const truncateKey = (key: string) => key.substring(0, 50); // find better max length, per DB

export function editTeam(path: string[], value: string | string[]) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().teamsForm;
    const { original } = formState;
    const { modified } = formState;
    const field = path[path.length - 1];
    let team = modified;
    if (field === 'key') {
      team = team.setIn(path, truncateKey(value as string));
    } else if (field === 'name') {
      team = team.setIn(path, value);
      if (!original.key) {
        team = team.set('key', truncateKey(kebabCase((value as string).toLowerCase())));
      }
    } else if (field === 'memberIDs') {
      team = team.set('memberIDs', Set(value));
    } else if (field === 'customRoleKeys') {
      team = team.set('customRoleKeys', Set(value));
    } else if (field === 'maintainerIDs') {
      team = team.set('maintainerIDs', Set(value));
    } else {
      team = team.setIn(path, value);
    }
    dispatch(edit(field, team));
  };
}

const update = (original: TeamForm, modified: TeamForm) => ({ type: 'teams/UPDATE_TEAM', original, modified }) as const;
const updateDone = (team: Team) => ({ type: 'teams/UPDATE_TEAM_DONE', team }) as const;
const updateFailed = (team: TeamForm, error: ImmutableServerError) =>
  ({
    type: 'teams/UPDATE_TEAM_FAILED',
    team,
    error,
  }) as const;

export function updateTeamSettings() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().teamsForm;
    const { original, modified } = formState;
    const teamKey = original.key;
    const instructions = getTeamsSemanticPatchInstructions(original, modified);
    dispatch(update(original, modified));
    return patchTeam(teamKey, instructions)
      .then((team) => {
        dispatch(updateDone(team));
      })
      .catch((error) => {
        dispatch(updateFailed(modified, error));
      });
  };
}

const addMaintainer = (maintainerIds: string[], teamKey: string) =>
  ({ type: 'teams/ADD_MAINTAINERS_TO_TEAM', maintainerIds, teamKey }) as const;
const addMaintainerDone = (maintainers: MemberSummary[], maintainerIds: string[], team: Team) =>
  ({ type: 'teams/ADD_MAINTAINERS_TO_TEAM_DONE', maintainers, maintainerIds, team }) as const;
const addMaintainerFailed = (maintainerIds: string[], teamKey: string, error: ImmutableServerError) =>
  ({
    type: 'teams/ADD_MAINTAINERS_TO_TEAM_FAILED',
    maintainerIds,
    teamKey,
    error,
  }) as const;

export function addMaintainersToTeam(maintainers: MemberSummary[], teamKey: string) {
  const memberIDs = maintainers.map((maintainer) => maintainer._id);

  return async (dispatch: GlobalDispatch) => {
    const instructions = getAddMaintainersSemanticPatchInstructions(memberIDs) as TeamsSemanticPatchInstructions[];
    dispatch(addMaintainer(memberIDs, teamKey));
    return patchTeam(teamKey, instructions)
      .then((team) => {
        dispatch(addMaintainerDone(maintainers, memberIDs, team));
      })
      .catch((error) => {
        dispatch(addMaintainerFailed(memberIDs, teamKey, error));
      });
  };
}

const removeMaintainer = (maintainerId: string, teamKey: string) =>
  ({ type: 'teams/REMOVE_MAINTAINER_FROM_TEAM', maintainerId, teamKey }) as const;
const removeMaintainerDone = (maintainerId: string, team: Team) =>
  ({ type: 'teams/REMOVE_MAINTAINER_FROM_TEAM_DONE', maintainerId, team }) as const;
const removeMaintainerFailed = (maintainerId: string, teamKey: string, error: ImmutableServerError) =>
  ({
    type: 'teams/REMOVE_MAINTAINER_FROM_TEAM_FAILED',
    maintainerId,
    teamKey,
    error,
  }) as const;

export function removeMaintainerFromTeam(maintainerId: string, teamKey: string) {
  return async (dispatch: GlobalDispatch) => {
    const instructions = getRemoveMaintainerSemanticPatchInstructions(maintainerId) as TeamsSemanticPatchInstructions[];
    dispatch(removeMaintainer(maintainerId, teamKey));
    return patchTeam(teamKey, instructions)
      .then((team) => {
        dispatch(removeMaintainerDone(maintainerId, team));
      })
      .catch((error) => {
        dispatch(removeMaintainerFailed(maintainerId, teamKey, error));
      });
  };
}
const removeMember = (memberId: string, teamKey: string) =>
  ({ type: 'teams/REMOVE_MEMBER_FROM_TEAM', memberId, teamKey }) as const;
const removeMemberDone = (memberId: string, team: Team) =>
  ({ type: 'teams/REMOVE_MEMBER_FROM_TEAM_DONE', memberId, team }) as const;
const removeMemberFailed = (memberId: string, teamKey: string, error: ImmutableServerError) =>
  ({
    type: 'teams/REMOVE_MEMBER_FROM_TEAM_FAILED',
    memberId,
    teamKey,
    error,
  }) as const;

export function removeMemberFromTeam(memberId: string, teamKey: string) {
  return async (dispatch: GlobalDispatch) => {
    const instructions = [
      { kind: TeamInstructionKindForMultipleValueResources.REMOVE_MEMBERS, values: [memberId] },
    ] as TeamsSemanticPatchInstructions[];
    dispatch(removeMember(memberId, teamKey));
    return patchTeam(teamKey, instructions)
      .then((team) => {
        dispatch(removeMemberDone(memberId, team));
      })
      .then(async () => dispatch(fetchMembersByTeam(createMemberFilters({ team: teamKey, limit: 25 }))))
      .catch((error) => {
        dispatch(removeMemberFailed(memberId, teamKey, error));
      });
  };
}

const addTeamMembers = (members: string[], teamKey: string) =>
  ({ type: 'teams/ADD_TEAM_MEMBERS', members, teamKey }) as const;
const addTeamMembersDone = (members: string[], team: Team) =>
  ({ type: 'teams/ADD_TEAM_MEMBERS_DONE', members, team }) as const;
const addTeamMembersFailed = (members: string[], teamKey: string, error: ImmutableServerError) =>
  ({
    type: 'teams/ADD_TEAM_MEMBERS_FAILED',
    members,
    teamKey,
    error,
  }) as const;

export function addMembersToTeam(members: string[], teamKey: string) {
  return async (dispatch: GlobalDispatch) => {
    const instructions = [{ kind: 'addMembers', values: members }] as TeamsSemanticPatchInstructions[];
    dispatch(addTeamMembers(members, teamKey));
    return patchTeam(teamKey, instructions)
      .then((team) => {
        dispatch(addTeamMembersDone(members, team));
      })
      .then(async () => dispatch(fetchMembersByTeam(createMemberFilters({ team: teamKey, limit: 25 }))))
      .catch((error) => {
        dispatch(addTeamMembersFailed(members, teamKey, error));
      });
  };
}

const bulkAddTeamMembers = () => ({ type: 'teams/BULK_ADD_TEAM_MEMBERS' }) as const;
const bulkAddTeamMembersDone = (response: TeamsBulkAddMembersResponse) =>
  ({ type: 'teams/BULK_ADD_TEAM_MEMBERS_DONE', response }) as const;
const bulkAddTeamMembersFailed = (error: ImmutableServerError) =>
  ({ type: 'teams/BULK_ADD_TEAM_MEMBERS_FAILED', error }) as const;
const bulkAddTeamMembersReset = () => ({ type: 'teams/BULK_ADD_TEAM_MEMBERS_RESET' }) as const;

export function bulkAddMembersToTeamsReset() {
  return (dispatch: GlobalDispatch) => dispatch(bulkAddTeamMembersReset());
}

export function bulkAddMembersToTeam(file: File, teamKey: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(bulkAddTeamMembers());
    return postTeamMembers(teamKey, file)
      .then((response) => {
        dispatch(bulkAddTeamMembersDone(response));
      })
      .catch((error) => {
        dispatch(bulkAddTeamMembersFailed(error));
      });
  };
}

const requestTeamCustomRoles = () => ({ type: 'teams/REQUEST_TEAM_CUSTOM_ROLES' }) as const;
const receiveTeamCustomRoles = (response: OrderedMap<string, Team>) =>
  ({ type: 'teams/RECEIVE_TEAM_CUSTOM_ROLES', response }) as const;
const requestTeamCustomRolesFailed = (error: ImmutableServerError) =>
  ({ type: 'teams/REQUEST_TEAM_CUSTOM_ROLES_FAILED', error }) as const;

export function fetchTeamCustomRoles(teamKey: string, filters: TeamCustomRolesFilters) {
  return async (dispatch: Dispatch) => {
    dispatch(requestTeamCustomRoles());
    try {
      const response = await getTeamCustomRoles(teamKey, filters);
      return dispatch(receiveTeamCustomRoles(response));
    } catch (error) {
      dispatch(requestTeamCustomRolesFailed(error as ImmutableServerError));
    }
  };
}

const addTeamCustomRoles = (customRoles: string[], teamKey: string) =>
  ({ type: 'teams/ADD_TEAM_CUSTOM_ROLES', customRoles, teamKey }) as const;
const addTeamCustomRolesDone = (customRoles: string[], team: Team) =>
  ({ type: 'teams/ADD_TEAM_CUSTOM_ROLES_DONE', customRoles, team }) as const;
const addTeamCustomRolesFailed = (customRoles: string[], teamKey: string, error: ImmutableServerError) =>
  ({
    type: 'teams/ADD_TEAM_CUSTOM_ROLES_FAILED',
    customRoles,
    teamKey,
    error,
  }) as const;

export function addCustomRolesToTeam(customRoles: string[], teamKey: string) {
  return async (dispatch: GlobalDispatch) => {
    const instructions = [
      { kind: TeamInstructionKindForMultipleValueResources.ADD_CUSTOM_ROLES, values: customRoles },
    ] as TeamsSemanticPatchInstructions[];
    dispatch(addTeamCustomRoles(customRoles, teamKey));
    return patchTeam(teamKey, instructions)
      .then((team) => {
        dispatch(addTeamCustomRolesDone(customRoles, team));
      })
      .catch((error) => {
        dispatch(addTeamCustomRolesFailed(customRoles, teamKey, error));
      });
  };
}

const removeTeamCustomRole = (customRole: { name: string; key: string }, teamKey: string) =>
  ({ type: 'teams/REMOVE_TEAM_CUSTOM_ROLE', customRole, teamKey }) as const;
const removeTeamCustomRoleDone = (customRole: { name: string; key: string }, team: Team) =>
  ({ type: 'teams/REMOVE_TEAM_CUSTOM_ROLE_DONE', customRole, team }) as const;
const removeTeamCustomRoleFailed = (
  customRole: { name: string; key: string },
  teamKey: string,
  error: ImmutableServerError,
) =>
  ({
    type: 'teams/REMOVE_TEAM_CUSTOM_ROLE_FAILED',
    customRole,
    teamKey,
    error,
  }) as const;

export function removeCustomRoleFromTeam(customRole: { name: string; key: string }, teamKey: string) {
  return async (dispatch: GlobalDispatch) => {
    const instructions = [
      { kind: TeamInstructionKindForMultipleValueResources.REMOVE_CUSTOM_ROLES, values: [customRole.key] },
    ] as TeamsSemanticPatchInstructions[];
    dispatch(removeTeamCustomRole(customRole, teamKey));
    return patchTeam(teamKey, instructions)
      .then((team) => {
        dispatch(removeTeamCustomRoleDone(customRole, team));
      })
      .catch((error) => {
        dispatch(removeTeamCustomRoleFailed(customRole, teamKey, error));
      });
  };
}

export const deleteTeamAction = (team: Team) => ({ type: 'teams/DELETE_TEAM', team }) as const;
export const deleteTeamDone = (team: Team) => ({ type: 'teams/DELETE_TEAM_DONE', team }) as const;
export const deleteTeamFailed = (team: Team, error: ImmutableServerError) =>
  ({ type: 'teams/DELETE_TEAM_FAILED', team, error }) as const;

export function useRemoveTeam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (team: Team, teamsFilters: TeamsFilters) => {
    dispatch(deleteTeamAction(team));
    const teamsUrl = toTeams({ search: teamsFilters.toQueryString({ omitPaginationParams: true }) });
    return deleteTeam(team)
      .then(async () => {
        await navigate(teamsUrl);
        dispatch(deleteTeamDone(team));
      })
      .then(async () => dispatch(fetchTeams(teamsFilters)))
      .catch((error) => {
        logger.error('removeTeam error', error?.toJS() ?? error);
        dispatch(deleteTeamFailed(team, error));
      });
  };
}

const TeamsActionCreators = {
  // Add team custom roles
  addTeamCustomRoles,
  addTeamCustomRolesDone,
  addTeamCustomRolesFailed,

  // Add team maintainer
  addMaintainer,
  addMaintainerDone,
  addMaintainerFailed,

  // Bulk add team members
  bulkAddTeamMembers,
  bulkAddTeamMembersDone,
  bulkAddTeamMembersFailed,
  bulkAddTeamMembersReset,

  // Remove team maintainer
  removeMaintainer,
  removeMaintainerDone,
  removeMaintainerFailed,

  // Add team members
  addTeamMembers,
  addTeamMembersDone,
  addTeamMembersFailed,

  // Remove team member
  removeMember,
  removeMemberDone,
  removeMemberFailed,

  // Create team
  create,
  createDone,
  createFailed,

  // Edit team
  edit,

  // Request team
  requestTeam,
  receiveTeam,
  requestTeamFailed,

  // Request team custom roles
  requestTeamCustomRoles,
  receiveTeamCustomRoles,
  requestTeamCustomRolesFailed,

  // Request team for access check
  requestTeamForAccessCheck,
  requestTeamForAccessCheckFailed,
  receiveTeamForAccessCheck,

  // Remove team Custom Role
  removeTeamCustomRole,
  removeTeamCustomRoleDone,
  removeTeamCustomRoleFailed,

  // Request teams
  requestTeams,
  receiveTeams,
  requestTeamsFailed,

  // Update team
  update,
  updateDone,
  updateFailed,

  // Delete team
  deleteTeamAction,
  deleteTeamDone,
  deleteTeamFailed,
  requestMoreTeamMaintainers,
  requestMoreTeamMaintainersDone,
  requestMoreTeamMaintainersFailed,
};

export type TeamsAction = GenerateActionType<typeof TeamsActionCreators>;

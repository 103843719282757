import type { DiamondProps } from '@gonfalon/icons';
import { Diamond } from '@gonfalon/icons';

type OwnProps = {
  fill: string;
};

type VariationColorProps = DiamondProps & OwnProps;

export const VariationColor = ({
  // FIXME(REL-4604): should not need to pass in color, only variation index (since color is derived from it)
  // if we only have a color, then we should be using a Diamond directly?
  fill,
  className,
  size = 'small',
}: VariationColorProps) => <Diamond className={className} size={size} fill={fill} />;

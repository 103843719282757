import type { ReactElement } from 'react';
import { cloneElement } from 'react';
import { ToastQueue, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

type CopyToClipboardProps = {
  children: ReactElement;
  text: string;
  tooltip?: string;
  onPressEvent?(): void;
  showTooltip?: boolean;
};

export const CopyToClipboard = ({
  children,
  text,
  tooltip = 'Copy to clipboard',
  onPressEvent,
  showTooltip = true,
}: CopyToClipboardProps) => {
  const pressable = cloneElement(children, {
    onPress: async () => {
      await navigator.clipboard.writeText(text);
      ToastQueue.success('Copied!');
      onPressEvent?.();
    },
  });
  return (
    <TooltipTrigger>
      {pressable}
      {showTooltip && <Tooltip placement="bottom">{tooltip}</Tooltip>}
    </TooltipTrigger>
  );
};

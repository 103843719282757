import { beastModeProfilePageRevamp } from '@gonfalon/dogfood-flags';
import { GetMemberQueryParams } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';
import { fetchMember, memberProfileQuery } from '@gonfalon/rest-api';
import { fromJS, List, Map } from 'immutable';
import { normalize, schema } from 'normalizr';

import {
  Account,
  createAccount as createAccountRecord,
  createAPIVersions,
  createFacilitatedTrial,
  createFollowPreferences,
  createMember,
  createMFARecoveryCodes,
  createSamlConfig as createSamlConfigRecord,
  createScimConfig,
  FacilitatedTrial,
  FacilitatedTrialSignUpForm,
  FollowPreferences,
  getBulkAddMembersToTeamsSemanticPatchInstructions,
  getBulkEditMemberRolesSemanticPatchInstructions,
  ImmutableNotificationsSettingsType,
  InvitationDetails,
  Member,
  MFARecoveryCodes,
  NewJoinTeamRequest,
  NormalizedMembersCollectionResponse,
  NotificationChannel,
  NotificationsSettingsType,
  NotificationTopics,
  OwnerInfo,
  PasswordUpdate,
  RequestSeatsFormRecord,
  SamlAppDetails,
  SamlConfig,
  ScimConfig,
  SignupForm,
} from 'utils/accountUtils';
import { convertMapToOrderedMap } from 'utils/collectionUtils';
import http, {
  beastModeError,
  beastModeRefresh,
  ImmutableServerError,
  jsonToImmutable,
  jsonToImmutableError,
  middleware,
  restApitoImmutableError,
} from 'utils/httpUtils';
import { MemberFilters } from 'utils/memberUtils';
import { createJsonPatch } from 'utils/patchUtils';
import { RoleName } from 'utils/roleUtils';

const members = new schema.Entity('members', {}, { idAttribute: '_id' });

async function reportAccesses(resourceSpec: string) {
  return http
    .report('/internal/accesses', {
      body: {
        resourceSpec,
      },
    })
    .then(jsonToImmutable)
    .catch(jsonToImmutableError);
}

async function getMembers(
  filter?: MemberFilters,
  { signal }: { signal?: AbortSignal } = {},
): Promise<NormalizedMembersCollectionResponse> {
  const queryString = filter ? filter.toBackendMembersQueryString() : '';
  const url = `/api/v2/members${queryString}`;
  return http
    .get(url, {
      headers: { 'Ld-Api-Version': '20160426' },
      signal,
    })
    .then(async (res) =>
      res.json().then((rawJSON) => {
        const data = normalize(rawJSON, { items: [members] });

        return Map({
          result: Map({
            _links: Map(data.result._links),
            totalCount: data.result.totalCount,
            items: List(data.result.items),
            totalCountWithoutSelfOrOwner: data.result.totalCountWithoutSelfOrOwner,
          }),
          entities: Map({
            members: convertMapToOrderedMap(
              Map(data.entities.members).map((m) => createMember(m as Member)),
              data.result.items,
            ),
          }),
        });
      }),
    )
    .catch(jsonToImmutableError);
}

async function getMember(memberId: string, { params }: { params?: GetMemberQueryParams } = {}): Promise<Member> {
  return fetchMember({ memberId, params })
    .then((data) => fromJS(data))
    .then(createMember)
    .catch(jsonToImmutableError);
}

async function createAccount(account: SignupForm): Promise<Account> {
  return http
    .post('/internal/account', {
      body: JSON.stringify(account.toJS()),
    })
    .catch(jsonToImmutableError);
}

async function createAccountFromFacilitatedTrial(
  password: FacilitatedTrialSignUpForm,
  token: string,
): Promise<void | Response> {
  return http
    .post(`/internal/account/signup/facilitated-trial/${token}`, {
      body: JSON.stringify(password.toJS()),
    })
    .catch(jsonToImmutableError);
}

async function getFacilitatedTrial(token: string): Promise<FacilitatedTrial> {
  return http
    .get(`/internal/account/signup/facilitated-trial/${token}/validate`)
    .then(jsonToImmutable)
    .then(createFacilitatedTrial)
    .catch(jsonToImmutableError);
}

async function createUnverifiedMember(account: SignupForm): Promise<void | Response> {
  return http.post('/internal/account/signupv2', { body: JSON.stringify(account.toJS()) }).catch(jsonToImmutableError);
}

async function deleteAccount(): Promise<void | Response> {
  return http.delete('/internal/account').catch(jsonToImmutableError).then(beastModeRefresh, beastModeError);
}

async function deleteDuplicateOrganization(): Promise<void | Response> {
  return http.delete('/internal/account/duplicate-organization').catch(jsonToImmutableError);
}

async function updateDuplicateOrganization(): Promise<void | Response> {
  return http.patch('/internal/account/duplicate-organization').catch(jsonToImmutableError);
}

async function getAccount() {
  return http.get('/internal/account').then(jsonToImmutable).then(createAccountRecord).catch(jsonToImmutableError);
}

async function getApiVersions() {
  return http.get('/api/v2/versions').then(jsonToImmutable).then(createAPIVersions).catch(jsonToImmutableError);
}

async function updateAccount(oldAccount: Account, newAccount: Account): Promise<Account> {
  const patch = createJsonPatch(oldAccount, newAccount);
  return http
    .patch(oldAccount.getIn(['_links', 'self', 'href']), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then(createAccountRecord)
    .catch(jsonToImmutableError);
}

async function updateMember(oldMember: Member, newMember: Member): Promise<Member> {
  const patch = createJsonPatch(oldMember, newMember, {
    propFilter: false,
    shouldTestVersion: newMember.version !== null,
  });
  return http
    .patch(oldMember.getIn(['_links', 'self', 'href']), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then(createMember)
    .catch(jsonToImmutableError);
}

async function bulkReplaceMembersRoles(
  memberIDs: string[],
  customRoleKeys: string[],
  role: RoleName,
  filter: MemberFilters,
  comment?: string,
) {
  const commentPayload = comment ? { comment } : {};
  const instructions = getBulkEditMemberRolesSemanticPatchInstructions(memberIDs, customRoleKeys, role, filter);
  return http
    .patch('/api/v2/members', {
      headers: {
        'Ld-Api-Version': 'beta',
        'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
      },
      body: { instructions, ...commentPayload },
    })
    .then(async (response) => middleware.json(response))
    .catch(jsonToImmutableError);
}

async function bulkReplaceAllMembersRoles(
  ignoredMemberIDs: string[],
  customRoleKeys: string[],
  role: RoleName,
  filters: MemberFilters,
) {
  const instructions = getBulkEditMemberRolesSemanticPatchInstructions(
    ['*'],
    customRoleKeys,
    role,
    filters,
    ignoredMemberIDs,
  );
  return http
    .patch('/api/v2/members', {
      headers: {
        'Ld-Api-Version': 'beta',
        'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
      },
      body: { instructions },
    })
    .then(async (response) => middleware.json(response))
    .catch(jsonToImmutableError);
}

async function addMemberToTeams(memberId: string, teamKeys: string[]): Promise<Member> {
  return http
    .post(`/api/v2/members/${memberId}/teams`, {
      body: { teamKeys },
    })
    .then(jsonToImmutable)
    .then(createMember)
    .catch(jsonToImmutableError);
}

async function bulkAddMembersToTeams(memberIDs: string[], teamKeys: string[], filter: MemberFilters) {
  const instructions = getBulkAddMembersToTeamsSemanticPatchInstructions(memberIDs, teamKeys, filter);
  return http
    .patch('/api/v2/teams', {
      headers: {
        'Ld-Api-Version': 'beta',
        'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
      },
      body: { instructions },
    })
    .then(async (response) => middleware.json(response))
    .catch(jsonToImmutableError);
}

async function bulkAddAllMembersToTeams(ignoredMemberIDs: string[], teamKeysToAdd: string[], filter: MemberFilters) {
  const instructions = getBulkAddMembersToTeamsSemanticPatchInstructions(
    ['*'],
    teamKeysToAdd,
    filter,
    ignoredMemberIDs,
  );
  return http
    .patch('/api/v2/teams', {
      headers: {
        'Ld-Api-Version': 'beta',
        'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
      },
      body: { instructions },
    })
    .then(async (response) => middleware.json(response))
    .catch(jsonToImmutableError);
}

async function updateAccountOwner(ownerInfo: OwnerInfo) {
  return http
    .post('/internal/account/owner', {
      body: JSON.stringify({
        promotedMemberId: `${ownerInfo.promotedMember._id}`,
      }),
    })
    .catch(jsonToImmutableError)
    .then(beastModeRefresh, beastModeError);
}

async function requestEnterpriseSeats(seatsRequest: RequestSeatsFormRecord) {
  return http
    .put('/internal/billingv2/account/subscription/opportunity/enterprise-seats', {
      body: JSON.stringify(seatsRequest.toJS()),
    })
    .catch(jsonToImmutableError);
}
async function inviteMembers(
  invites: List<{
    email: string;
    role: string;
    customRoles: List<string>;
    teamKeys: List<string>;
  }>,
): Promise<List<Member>> {
  return http
    .post('/api/v2/members', {
      body: invites,
    })
    .then(jsonToImmutable)
    .then((data) => data.get('items').map(createMember))
    .catch(jsonToImmutableError);
}

async function resendInvite(member: Member): Promise<Member> {
  return http.post(member.getIn(['_links', 'resendInvitation', 'href'])).catch(jsonToImmutableError);
}

async function deleteMember(member: Member): Promise<void> {
  return http.delete(member.getIn(['_links', 'self', 'href'])).catch(jsonToImmutableError);
}

async function acceptInvitation(details: InvitationDetails, invitationToken: string) {
  return http
    .post(`/internal/invite/${invitationToken}`, {
      body: JSON.stringify(details.toJS()),
    })
    .catch(jsonToImmutableError);
}

async function acceptInvitationMFA(
  token: string,
  passcode: string,
  invitationToken: string,
): Promise<MFARecoveryCodes> {
  return http
    .post(`/internal/invite/${invitationToken}/mfa`, {
      body: { token, passcode },
    })
    .then(jsonToImmutable)
    .then(createMFARecoveryCodes)
    .catch(jsonToImmutableError);
}

async function getProfile(): Promise<Member> {
  const queryClient = getQueryClient();
  // NOTE: not all pages have this resource in context so hardcode it for now.
  return queryClient
    .fetchQuery(memberProfileQuery({}))
    .then((data) => fromJS(data))
    .then(createMember)
    .catch(restApitoImmutableError);
}

// updateProfileBeautyMode bypasses beastMode escalation
async function updateProfileBeautyMode(oldProfile: Member, newProfile: Member): Promise<Member> {
  const patch = createJsonPatch(oldProfile, newProfile, { propFilter: false });

  return http
    .patch('/internal/profile/deescalate', {
      body: patch,
    })
    .then(jsonToImmutable, jsonToImmutableError)
    .then(createMember);
}

async function updateProfile(oldProfile: Member, newProfile: Member): Promise<Member> {
  const patch = createJsonPatch(oldProfile, newProfile, { propFilter: false });

  return http
    .patch(oldProfile.getIn(['_links', 'self', 'href']), {
      body: JSON.stringify(patch),
    })
    .then(jsonToImmutable)
    .then(createMember)
    .catch(jsonToImmutableError)
    .catch((error: ImmutableServerError) => {
      if (beastModeProfilePageRevamp()) {
        //  Return the error so that the caller can handle it for the new beast mode UI.
        throw error;
      } else {
        return beastModeError(error);
      }
    });
}

async function updatePassword(password: PasswordUpdate): Promise<PasswordUpdate> {
  return http
    .post('/internal/profile/password', {
      body: JSON.stringify(password.toJS()),
    })
    .catch(jsonToImmutableError);
}

async function resendVerification() {
  return http
    .post('/internal/profile/resend-verification')
    .catch(jsonToImmutableError)
    .then(beastModeRefresh, beastModeError);
}

async function cancelVerification() {
  return http
    .post('/internal/profile/cancel-verification')
    .catch(jsonToImmutableError)
    .then(beastModeRefresh, beastModeError);
}

async function enableMFA() {
  return http
    .post('/internal/profile/mfa/enable')
    .then(jsonToImmutable, jsonToImmutableError)
    .then(beastModeRefresh, beastModeError);
}

async function confirmMFA(token: string, passcode: string): Promise<MFARecoveryCodes> {
  return http
    .post('/internal/profile/mfa/confirm', {
      body: { token, passcode },
    })
    .then(jsonToImmutable)
    .then(createMFARecoveryCodes)
    .catch(jsonToImmutableError);
}

async function disableMFA() {
  return http.post('/internal/profile/mfa/disable').catch(jsonToImmutableError).then(beastModeRefresh, beastModeError);
}

async function sendMFAEnableRequest(member: Member) {
  return http.post(member.getIn(['_links', 'sendMfaEnableRequest', 'href'])).catch(jsonToImmutableError);
}

async function sendMFARecoveryCode(member: Member) {
  return http.post(member.getIn(['_links', 'sendMfaRecoveryCode', 'href'])).catch(jsonToImmutableError);
}

async function deleteAccountToken(account: Account): Promise<void> {
  return http.delete(`${account.selfLink()}/tokens/`).catch(jsonToImmutableError);
}

async function turnOnTeamSync(): Promise<void> {
  return http.post('/internal/account/scim/managed-teams').catch(jsonToImmutableError);
}

async function turnOffTeamSync(): Promise<void> {
  return http.delete('/internal/account/scim/managed-teams').catch(jsonToImmutableError);
}

async function getSamlConfig(): Promise<SamlConfig> {
  return http
    .get('/internal/account/saml')
    .then(jsonToImmutable)
    .then(createSamlConfigRecord)
    .catch(jsonToImmutableError);
}

async function getSamlAppDetails(): Promise<SamlAppDetails> {
  return http.get('/internal/account/saml-app-details').then(jsonToImmutable).catch(jsonToImmutableError);
}

async function createSamlConfig(config: SamlConfig): Promise<SamlConfig> {
  return http
    .put('/internal/account/saml', {
      body: config,
    })
    .then(jsonToImmutable)
    .then(createSamlConfigRecord)
    .catch(jsonToImmutableError);
}

async function updateSamlConfig(original: SamlConfig, modified: SamlConfig) {
  const patch = createJsonPatch(original.toRep(), modified.toRep());
  return http
    .patch('/internal/account/saml', {
      body: patch,
    })
    .catch(jsonToImmutableError);
}

async function deleteSamlConfig(): Promise<void> {
  return http.delete('/internal/account/saml').catch(jsonToImmutableError);
}

async function getScimConfig(): Promise<ScimConfig> {
  return http.get('/internal/account/scim').then(jsonToImmutable).then(createScimConfig).catch(jsonToImmutableError);
}

async function deleteScimConfig(): Promise<void> {
  return http.delete('/internal/account/scim').catch(jsonToImmutableError);
}

async function revokeAllSessions() {
  return http.post('/internal/account/revoke-sessions').catch(jsonToImmutableError);
}

async function requestTeamInvite(joinTeamRequest: NewJoinTeamRequest) {
  return http
    .post('/internal/account/join', {
      body: JSON.stringify(joinTeamRequest.toJS()),
    })
    .catch(jsonToImmutableError);
}

const getFollowPreferences = async () =>
  http
    .get('/internal/profile/following')
    .then(jsonToImmutable)
    .then(createFollowPreferences)
    .catch(jsonToImmutableError);

const updateFollowPreferences = async (oldPreferences: FollowPreferences, nextPreferences: FollowPreferences) => {
  const patch = createJsonPatch(oldPreferences, nextPreferences, { shouldTestVersion: true });
  return http
    .patch(oldPreferences.selfLink(), {
      body: patch,
    })
    .then(jsonToImmutable)
    .then(createFollowPreferences)
    .catch(jsonToImmutableError);
};

const getNotificationSettings = async (): Promise<ImmutableNotificationsSettingsType> =>
  http.get('/internal/profile/notification-settings').then(jsonToImmutable).catch(jsonToImmutableError);

const putNotificationSettings = async (
  topic: NotificationTopics,
  channel: NotificationChannel,
  channelEnabled: boolean,
  oldSettings: NotificationsSettingsType,
) => {
  const body = {
    ...oldSettings,
    [topic]: {
      ...oldSettings[topic],
      [channel]: channelEnabled,
    },
    topic,
  };
  return http.put('/internal/profile/notification-settings', { body }).then(jsonToImmutable);
};

export const approveUnverifiedMemberRequest = async (token: string): Promise<Member> =>
  http
    .put(`/internal/account/unverified-member/${token}`, { body: { role: 'reader' } })
    .then(jsonToImmutable)
    .then(createMember)
    .catch(jsonToImmutableError);

export const suggestInviteMembers = async (emails: string[]): Promise<void> =>
  http.post('/internal/account/suggest-invites', { body: { emails } }).catch(jsonToImmutableError);

export {
  acceptInvitation,
  acceptInvitationMFA,
  addMemberToTeams,
  bulkAddAllMembersToTeams,
  bulkAddMembersToTeams,
  bulkReplaceAllMembersRoles,
  bulkReplaceMembersRoles,
  cancelVerification,
  confirmMFA,
  createAccount,
  createAccountFromFacilitatedTrial,
  createUnverifiedMember,
  createSamlConfig,
  deleteAccount,
  deleteAccountToken,
  deleteMember,
  deleteSamlConfig,
  deleteScimConfig,
  disableMFA,
  enableMFA,
  getAccount,
  getApiVersions,
  getFacilitatedTrial,
  getFollowPreferences,
  getMember,
  getMembers,
  getNotificationSettings,
  getProfile,
  getSamlConfig,
  getSamlAppDetails,
  getScimConfig,
  inviteMembers,
  putNotificationSettings,
  reportAccesses,
  requestEnterpriseSeats,
  requestTeamInvite,
  resendInvite,
  resendVerification,
  revokeAllSessions,
  sendMFAEnableRequest,
  sendMFARecoveryCode,
  turnOffTeamSync,
  turnOnTeamSync,
  updateAccount,
  updateAccountOwner,
  updateFollowPreferences,
  updateMember,
  updatePassword,
  updateProfile,
  updateProfileBeautyMode,
  updateSamlConfig,
  deleteDuplicateOrganization,
  updateDuplicateOrganization,
};

import { CodeSpan } from '../CodeSpan';
import { getAnalysisTypeDisplayName } from '../getAnalysisTypeDisplayName';
import { getSuccessCriteriaDisplayName } from '../getSuccessCriteriaDisplayName';
import { RandomizationUnitsSection } from '../RandomizationUnitsSection';
import { MeasureType, Metric } from '../types';

import styles from './MetricDefinition.module.css';

interface MetricDefinitionProps {
  metric: Metric;
}

export const MetricDefinition: React.FC<MetricDefinitionProps> = ({ metric }) => {
  const measureType: MeasureType = metric.isNumeric
    ? 'value'
    : metric.unitAggregationType === 'sum'
      ? 'count'
      : 'occurrence';
  const analysisTypeString = getAnalysisTypeDisplayName(metric.analysisType, metric.percentileValue);
  const successCriteriaSpan = metric.successCriteria ? (
    <CodeSpan textValue={getSuccessCriteriaDisplayName(metric.successCriteria)} />
  ) : metric.kind === 'click' || metric.kind === 'pageview' ? (
    <span>{getSuccessCriteriaDisplayName('HigherThanBaseline')}</span>
  ) : (
    ''
  );
  return (
    <span className={styles.metricDefinition} id="metricDefinition">
      {measureType !== 'occurrence' && (
        <>
          <CodeSpan textValue={analysisTypeString} />{' '}
        </>
      )}
      <RandomizationUnitsSection
        measureType={measureType}
        unitAggregationType={metric.unitAggregationType}
        metricKind={metric.kind}
        readonly
        readonlyValues={metric.randomizationUnits ?? []}
      />
      {successCriteriaSpan}
    </span>
  );
};

import { endOfDay, startOfDay, subMonths } from 'date-fns';

export function getFilterDateRange(before?: Date, after?: Date) {
  const today = new Date();
  const threeMonthsAgo = subMonths(today, 3);
  const defaultAfter = startOfDay(threeMonthsAgo);
  const defaultBefore = endOfDay(today);

  return {
    start: after || defaultAfter,
    end: before || defaultBefore,
  };
}

import { createTrackerForCategory } from '@gonfalon/analytics';
import { baseUri } from '@gonfalon/constants';
import nullthrows from 'nullthrows';
import qs from 'qs';

const v2ProjectContextRegex =
  /^\/projects\/[0-9a-zA-Z\-\_\.]+\/(?<section>flags|experiments|integrations|contexts|segments|audit|metrics\/analytics)(?<rest>\/.*)?$/;

function updateFilterEnvFilter(search: string, envKey: string) {
  const updateSearch = qs.parse(search, { ignoreQueryPrefix: true });
  const filterEnv = updateSearch.filterEnv;

  if (filterEnv) {
    if (filterEnv !== envKey) {
      updateSearch.filterEnv = envKey;
      return qs.stringify(updateSearch, { indices: false, addQueryPrefix: true });
    }
  }

  return search;
}

type LocationContext = { projectKey: string; environmentKey: string };

function transformUrlContext(location: Location, { projectKey, environmentKey }: LocationContext) {
  const path = location.pathname;
  const search = updateFilterEnvFilter(location.search, environmentKey);

  const match = v2ProjectContextRegex.exec(path);
  if (match && match.length > 0) {
    // if we have a match, we must have a section
    const section = nullthrows(match.groups?.section);
    // we may not have a nested route though
    const rest = match.groups?.rest ?? '';
    const searchParams = new URLSearchParams(search);
    searchParams.set('env', environmentKey);

    const nextUrl = new URL(`/projects/${projectKey}/${section}${rest}`, window.location.origin);
    nextUrl.search = searchParams.toString();
    return nextUrl.toString().replace(baseUri(), '');
  }

  return location.pathname + location.search + location.hash;
}

export const getNewUrlContext = ({ projectKey, environmentKey }: LocationContext) =>
  transformUrlContext(window.location, { projectKey, environmentKey });

export const trackPageLeaveEvent = createTrackerForCategory('PageLeave');

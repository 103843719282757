import { useNavigate } from 'react-router';
import { kebabCase } from '@gonfalon/es6-utils';
import { toCustomRoles } from '@gonfalon/navigator';
import { getQueryClient } from '@gonfalon/react-query-client';
import { OrderedMap } from 'immutable';

import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import {
  deleteRole as roleAPIDeleteRole,
  getAllRoles as roleAPIGetAllRoles,
  postRole as roleAPIPostRole,
  updateRole as roleAPIUpdateRole,
} from 'sources/RoleAPI';
import { ImmutableServerError } from 'utils/httpUtils';
import { GenerateActionType } from 'utils/reduxUtils';
import { Role, RoleForm } from 'utils/roleUtils';
const requestRoles = () => ({ type: 'roles/REQUEST_ROLES' }) as const;
const requestRolesDone = (response: OrderedMap<string, Role>) =>
  ({ type: 'roles/REQUEST_ROLES_DONE', response }) as const;
const requestRolesFailed = (error: ImmutableServerError) => ({ type: 'roles/REQUEST_ROLES_FAILED', error }) as const;

function fetchRoles() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestRoles());
    return roleAPIGetAllRoles()
      .then((response) => dispatch(requestRolesDone(response)))
      .catch((error) => dispatch(requestRolesFailed(error)));
  };
}

function shouldFetchRoles(state: GlobalState) {
  return !state.roles.get('lastFetched') && !state.roles.get('isFetching');
}

function fetchRolesIfNeeded(options?: { shouldFetch?: boolean }) {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (options?.shouldFetch || shouldFetchRoles(getState())) {
      return dispatch(fetchRoles());
    }
  };
}

const filterRoles = (text: string) => ({ type: 'roles/FILTER_BY_TEXT', text }) as const;
const editRoleAction = (field: string, role: RoleForm, options?: { shouldClearServerError: boolean }) =>
  ({
    type: 'roles/EDIT_ROLE',
    field,
    role,
    options,
  }) as const;

function editRole(
  field: 'policy' | '_links' | '_id' | 'name' | 'key' | 'description' | 'basePermissions',
  value: string,
  options?: { shouldClearServerError: boolean },
) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().roleForm;
    let modified = formState.modified.set(field, value);

    if (modified.isNew() && field === 'name' && !formState.wasChanged('key')) {
      modified = modified.set('key', kebabCase(value));
    }
    dispatch(editRoleAction(field, modified, options));
  };
}

const updateRoleAction = () => ({ type: 'roles/UPDATE_ROLE' }) as const;
const updateRoleDone = (role: Role) => ({ type: 'roles/UPDATE_ROLE_DONE', role }) as const;
const updateRoleFailed = (error: ImmutableServerError, newRole: RoleForm) =>
  ({ type: 'roles/UPDATE_ROLE_FAILED', newRole, error }) as const;

export function useUpdateRole() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (oldRole: RoleForm, newRole: RoleForm, options = { comment: '' }) => {
    dispatch(updateRoleAction());
    return roleAPIUpdateRole(oldRole, newRole, options)
      .then(async (updated) => {
        const queryClient = getQueryClient();
        await queryClient.invalidateQueries({
          queryKey: ['customRoles'],
        });
        await navigate(toCustomRoles());
        return dispatch(updateRoleDone(updated));
      })
      .catch(async (error) => {
        if (error.get('status') === 403) {
          await navigate(toCustomRoles());
        }
        return dispatch(updateRoleFailed(error, newRole));
      });
  };
}

const createRoleAction = () => ({ type: 'roles/CREATE_ROLE' }) as const;
const createRoleDone = (role: Role) => ({ type: 'roles/CREATE_ROLE_DONE', role }) as const;
const createRoleFailed = (role: RoleForm, error: ImmutableServerError) =>
  ({ type: 'roles/CREATE_ROLE_FAILED', role, error }) as const;

export function useCreateRole() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (role: RoleForm) => {
    dispatch(createRoleAction());
    return roleAPIPostRole(role)
      .then(async (created) => {
        const queryClient = getQueryClient();
        await queryClient.invalidateQueries({
          queryKey: ['customRoles'],
        });
        await navigate(toCustomRoles());
        return dispatch(createRoleDone(created));
      })
      .catch(async (error) => {
        if (error.get('status') === 403) {
          await navigate(toCustomRoles());
        }
        return dispatch(createRoleFailed(role, error));
      });
  };
}

const deleteRoleAction = (role: Role) => ({ type: 'roles/DELETE_ROLE', role }) as const;
const deleteRoleDone = (role: Role) => ({ type: 'roles/DELETE_ROLE_DONE', role }) as const;
const deleteRoleFailed = (role: Role, error: ImmutableServerError) =>
  ({ type: 'roles/DELETE_ROLE_FAILED', role, error }) as const;

export function useDeleteRole() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (role: Role) => {
    dispatch(deleteRoleAction(role));
    return roleAPIDeleteRole(role)
      .then(async () => {
        const queryClient = getQueryClient();
        await queryClient.invalidateQueries({
          queryKey: ['customRoles'],
        });
        await navigate(toCustomRoles());
        dispatch(deleteRoleDone(role));
      })
      .catch(async (error) => {
        await navigate(toCustomRoles());
        dispatch(deleteRoleFailed(role, error));
      });
  };
}

const RoleActionCreators = {
  requestRoles,
  requestRolesDone,
  requestRolesFailed,
  editRoleAction,
  filterRoles,
  updateRoleAction,
  updateRoleDone,
  updateRoleFailed,
  createRoleAction,
  createRoleDone,
  createRoleFailed,
  deleteRoleAction,
  deleteRoleDone,
  deleteRoleFailed,
};

export type RoleAction = GenerateActionType<typeof RoleActionCreators>;

export { fetchRolesIfNeeded as fetchRoles, filterRoles, editRole };

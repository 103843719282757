import { Suspense, useState } from 'react';
import { useModifierKeys } from '@gonfalon/dom';
import { ErrorBoundary } from '@gonfalon/error-boundaries';
import { useKeyCombo } from '@gonfalon/react';
import { Alert, DialogTrigger, IconButton, Popover, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

import { type SelectedEnvironment } from '../types';

import { EnvironmentFallback } from './EnvironmentFallback';
import { SelectEnvironmentDialog } from './SelectEnvironmentDialog';

import styles from './EnvironmentSelector.module.css';

export function AddEnvironmentDialogTrigger({
  projectKey,
  excludedIds,
  onSelect,
}: {
  projectKey: string;
  excludedIds: Set<string>;
  onSelect: (environment: SelectedEnvironment) => void;
}) {
  const keys = useModifierKeys();
  const [isOpen, setOpen] = useState(false);

  useKeyCombo('.', () => {
    setOpen(true);
  });

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={setOpen}>
      <TooltipTrigger>
        <IconButton aria-label="Add environment" variant="minimal" size="medium" icon="add" className={styles.add} />
        <Tooltip placement="bottom">View another environment</Tooltip>
      </TooltipTrigger>
      <Popover placement="bottom end">
        <Suspense fallback={<EnvironmentFallback />}>
          <ErrorBoundary
            severity="high"
            fallback={
              <Alert variant="inline" status="error">
                There was a problem fetching the environments. Try again later.
              </Alert>
            }
          >
            <SelectEnvironmentDialog
              projectKey={projectKey}
              excludedIds={excludedIds}
              onSelect={(v) => {
                !keys.current.shiftKey && setOpen(false);
                onSelect(v);
              }}
            />
          </ErrorBoundary>
        </Suspense>
      </Popover>
    </DialogTrigger>
  );
}

import { createTrackerForCategory } from '@gonfalon/analytics';
import { toContexts } from '@gonfalon/navigator';
import { isJSONValue } from '@gonfalon/types';
import { LDContext, LDContextCommon, LDMultiKindContext, LDSingleKindContext } from 'launchdarkly-js-client-sdk';

import { handleContextPatchFlagResponse, handleDeleteContextResponse } from 'actions/contextKinds';
import { ContextDetailsTypes } from 'components/Contexts/ContextsDetails/ContextDetailsStateMachine';
import { ContextListTypes } from 'components/Contexts/ContextsList/ContextListStateMachine';
import { allowDecision, createAccessDecision } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';

import { Access, AccessChecks, ContextAttributesNames, ContextKind } from '../types';

export const getContextKindOptions = (contextKinds: ContextKind[]) => {
  const contextKindOptions: Array<{ label: string; value: string }> = [];
  contextKinds.forEach((ctx) => {
    contextKindOptions.push({ label: `${ctx.name}`, value: ctx.key });
  });
  return contextKindOptions;
};

export const getAttributesNameByContextKinds = (
  contextKind: string,
  contextAttributesNames: ContextAttributesNames[],
) => {
  const contextAttributeOptions: Array<{ label: string; value: string }> = [];
  const contextAttributeObject: { [key: string]: string } = {};
  if (contextKind?.length > 0 && contextKind !== '') {
    const contextAttribute = contextAttributesNames.find((ctx) => ctx.kind === contextKind);
    contextAttribute?.names.forEach((item) => {
      if (!contextAttributeObject[item.name]) {
        contextAttributeObject[item.name] = item.name;
        contextAttributeOptions.push({ label: `${item.name}`, value: item.name });
      }
    });
  } else {
    contextAttributesNames.forEach((ctx) => {
      ctx?.names.forEach((item) => {
        // remove duplicates
        if (!contextAttributeObject[item.name]) {
          contextAttributeObject[item.name] = item.name;
          contextAttributeOptions.push({ label: `${item.name}`, value: item.name });
        }
      });
    });
  }
  return contextAttributeOptions;
};

export const getAttributesValueOptions = (contextAttributeValues: Array<{ name: string; weight: number }>) => {
  const contextKindOptions: Array<{ label: string; value: string }> = [];
  contextAttributeValues.forEach((ctx) => {
    if (ctx.name !== '') {
      contextKindOptions.push({ label: `${ctx.name}`, value: ctx.name });
    }
  });
  return contextKindOptions;
};

type CheckAccessPropTypes = {
  profile: Member | undefined;
  action: string;
  access: AccessChecks | undefined;
};

export const checkAccess = ({ profile, action = '', access }: CheckAccessPropTypes) => {
  if (profile && profile.isReader()) {
    return createAccessDecision({
      isAllowed: false,
      appliedRoleName: 'Reader',
    });
  }

  if (profile && (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !access || !access.denied)) {
    return allowDecision();
  }

  const deniedAction = access?.denied?.find((v: Access) => v.action === action);

  if (deniedAction) {
    const reason = deniedAction.reason;
    const roleName = reason && reason.role_name;
    return createAccessDecision({
      isAllowed: false,
      appliedRoleName: roleName,
    });
  }
  return createAccessDecision({ isAllowed: true });
};

export const handleDeleteContextSuccess = async (context: ContextDetailsTypes | ContextListTypes) => {
  const location = context.id && context.id.length > 0 ? 'Context Details' : 'Context Instance Details';
  trackContextDetailsEvent('Context Deleted', { location });
  context.dispatch(handleDeleteContextResponse(true));
  await context.navigate(toContexts(context));
};

export const handleDeleteContextFailure = (context: ContextDetailsTypes | ContextListTypes) => {
  context.dispatch(handleDeleteContextResponse(false));
};

export const handlePatchFlagSuccess = (context: ContextDetailsTypes) => {
  context.dispatch(handleContextPatchFlagResponse(true));
};

export const handlePatchFlagFailure = (context: ContextDetailsTypes) => {
  context.dispatch(handleContextPatchFlagResponse(false));
};

export const trackDeleteEvent = (context: ContextDetailsTypes) => {
  const location = context.id && context.id.length > 0 ? 'Context Details' : 'Context Instance Details';
  trackContextDetailsEvent('Delete Context', { location });
};

/**
 * Check if a context is a single kind context.
 * @param context
 * @returns true if the context is a single kind context.
 */
export function isSingleKind(context: LDContext): context is LDSingleKindContext {
  if ('kind' in context) {
    return context.kind !== 'multi';
  }
  return true;
}

/**
 * Check if a context is a multi-kind context.
 * @param context
 * @returns true if it is a multi-kind context.
 */
export function isMultiKind(context: LDContext): context is LDMultiKindContext {
  if ('kind' in context) {
    return context.kind === 'multi';
  }
  return false;
}

/**
 * Get the kind of a context.
 * @param context
 *  The context to get the kind of.
 * @returns The kind of the context.
 * If the context is a multi-kind context, the kind will be "multi".
 * If the context is a single-kind context, the kind will be the kind of the context.
 */
export function getContextKind(context: LDContext): string {
  if ('kind' in context) {
    return String(context.kind);
  }
  return '';
}

export function getContextKey(context: LDContext): string {
  if ('key' in context) {
    return String(context.key);
  }
  return '';
}

export function getContextNameOrKey(context: LDContext): string {
  if ('name' in context && context.name) {
    return String(context.name);
  }
  if ('firstName' in context && 'lastName' in context && context.firstName && context.lastName) {
    return `${context.firstName} ${context.lastName}`;
  }
  return String(context.key);
}

export function getContextAttributeValue(context: LDContext, attribute: string): string {
  if (attribute in context) {
    return String(context[attribute as keyof LDContext]);
  }
  return '';
}

export function getContextNameOrFirstLastOrKey(context: LDContext, kind?: string): string {
  let activeContext: $TSFixMe = context;
  if (isMultiKind(activeContext) && kind) {
    activeContext = activeContext[kind];
  }

  if ('name' in activeContext && activeContext.name) {
    return String(activeContext.name);
  }

  if (
    'firstName' in activeContext &&
    'lastName' in activeContext &&
    activeContext.firstName &&
    activeContext.lastName
  ) {
    return `${activeContext.firstName} ${activeContext.lastName}`;
  }
  return String(activeContext.key);
}

function isContextCommon(kindOrContext: 'multi' | LDContextCommon): kindOrContext is LDContextCommon {
  return kindOrContext && isJSONValue(kindOrContext);
}

export function kindsAndKeys(context: LDContext): Record<string, string> {
  if (isMultiKind(context)) {
    const kinds = Object.keys(context).filter((key) => key !== 'kind');
    const contexts = kinds.reduce((acc: Record<string, LDContextCommon>, kind) => {
      const singleContext = context[kind];
      if (isContextCommon(singleContext)) {
        // eslint-disable-next-line no-param-reassign
        acc[kind] = singleContext;
      }
      return acc;
    }, {});

    return Object.entries(contexts).reduce((acc: Record<string, string>, [k, c]) => {
      // eslint-disable-next-line no-param-reassign
      acc[k] = c.key || '';
      return acc;
    }, {});
  }
  return { [getContextKind(context)]: getContextKey(context) };
}

export const trackContextDetailsEvent = createTrackerForCategory('ContextDetails');

export const trackContextListEvent = createTrackerForCategory('ContextList');

export const trackContextKindEvent = createTrackerForCategory('ContextKindManagment');

export const filterOperators = [
  'equals',
  'anyOf',
  'contains',
  'startsWith',
  'lte',
  'gte',
  'after',
  'before',
  'notEquals',
];

import { GetEventActivityByProjectQueryParams } from '@gonfalon/openapi';
import { getMetricEventsByEventKeyQuery } from '@gonfalon/rest-api';
import { useSuspenseQuery } from '@tanstack/react-query';

export const useSuspenseEventActivityByEventKey = (
  projectKey: string,
  eventKey: string,
  query: GetEventActivityByProjectQueryParams,
) =>
  useSuspenseQuery(
    getMetricEventsByEventKeyQuery({
      projectKey,
      eventKey,
      query,
    }),
  );

import { useStore } from 'react-redux';
import { useNavigate } from 'react-router';
import { toEditPayloadFilter } from '@gonfalon/navigator';
import { ImmutableServerError } from 'ia-poc/services/http';

import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch } from 'reducers';
import { getPayloadFilter, getPayloadFilters, patchPayloadFilter, postPayloadFilter } from 'sources/PayloadFiltersAPI';
import {
  PayloadFilter,
  PayloadFilterFilters,
  PayloadFilterProps,
  PayloadFilterValueType,
} from 'utils/payloadFilterUtils';
import { GenerateActionType } from 'utils/reduxUtils';

const request = (projKey: string) => ({ type: 'payloadFilters/REQUEST_PAYLOAD_FILTERS', projKey }) as const;

const requestDone = (response: PayloadFilter) =>
  ({ type: 'payloadFilters/REQUEST_PAYLOAD_FILTERS_DONE', response }) as const;

const requestFailed = (projKey: string, error: ImmutableServerError) =>
  ({ type: 'payloadFilters/REQUEST_PAYLOAD_FILTERS_FAILED', projKey, error }) as const;

export function fetchPayloadFilters(projKey: string, filters: PayloadFilterFilters) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(request(projKey));
    return new Promise((resolve, reject) => {
      getPayloadFilters(projKey, filters)
        .then((response) => {
          dispatch(requestDone(response));
          resolve(response);
        })
        .catch((error) => {
          dispatch(requestFailed(projKey, error));
          reject(error);
        });
    });
  };
}

const requestFilter = (projKey: string) => ({ type: 'payloadFilters/REQUEST_PAYLOAD_FILTER', projKey }) as const;

const requestFilterDone = (response: PayloadFilter) =>
  ({ type: 'payloadFilters/REQUEST_PAYLOAD_FILTER_DONE', response }) as const;

const requestFilterFailed = (projKey: string, error: ImmutableServerError) =>
  ({ type: 'payloadFilters/REQUEST_PAYLOAD_FILTER_FAILED', projKey, error }) as const;

export function fetchPayloadFilterAction(projKey: string, filterKey: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestFilter(projKey));
    return new Promise((resolve, reject) => {
      getPayloadFilter(projKey, filterKey)
        .then((response) => {
          dispatch(requestFilterDone(response));
          resolve(response);
        })
        .catch((error) => {
          dispatch(requestFilterFailed(projKey, error));
          reject(error);
        });
    });
  };
}

const edit = (field: string, value: PayloadFilterValueType, payloadFilter: PayloadFilter) =>
  ({ type: 'payloadFilters/EDIT_PAYLOAD_FILTER', field, value, payloadFilter }) as const;

export function editPayloadFilter(field: keyof PayloadFilterProps, value: PayloadFilterValueType) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = getState().payloadFilterForm;
    const updatedFilter = form.modified.set(field, value);
    dispatch(edit(field, value, updatedFilter));
  };
}

const create = () => ({ type: 'payloadFilters/CREATE_PAYLOAD_FILTER' }) as const;
const createDone = (response: PayloadFilter) =>
  ({ type: 'payloadFilters/CREATE_PAYLOAD_FILTER_DONE', response }) as const;
const createFailed = (payloadFilter: PayloadFilter, error: ImmutableServerError) =>
  ({ type: 'payloadFilters/CREATE_PAYLOAD_FILTER_FAILED', payloadFilter, error }) as const;

export function useCreatePayloadFilter() {
  const navigate = useNavigate();
  const store = useStore();
  const dispatch = useDispatch();

  return async (projKey: string, filters: PayloadFilterFilters) => {
    const formState = store.getState().payloadFilterForm;
    const { modified } = formState;
    const filterUrl = toEditPayloadFilter({
      projectKey: projKey,
      payloadFilterKey: modified.key,
    });
    dispatch(create());
    return postPayloadFilter(modified, projKey)
      .then((response) => {
        dispatch(createDone(response));
      })
      .then(async () => navigate(filterUrl))
      .then(async () => dispatch(fetchPayloadFilters(projKey, filters)))
      .catch((error) => {
        dispatch(createFailed(modified, error));
      });
  };
}

const update = (original: PayloadFilter, modified: PayloadFilter) =>
  ({ type: 'payloadFilters/UPDATE_PAYLOAD_FILTER', original, modified }) as const;

const updateDone = (response: PayloadFilter) =>
  ({ type: 'payloadFilters/UPDATE_PAYLOAD_FILTER_DONE', response }) as const;

const updateFailed = (modified: PayloadFilter, error: ImmutableServerError) =>
  ({
    type: 'payloadFilters/UPDATE_PAYLOAD_FILTER_FAILED',
    payloadFilter: modified,
    error,
  }) as const;

export function updatePayloadFilterAction(original: PayloadFilter, modified: PayloadFilter, projKey: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(update(original, modified));
    return patchPayloadFilter(projKey, original, modified)
      .then((response) => {
        dispatch(updateDone(response));
      })
      .catch((error) => {
        dispatch(updateFailed(modified, error));
      });
  };
}

const archive = (original: PayloadFilter, modified: PayloadFilter) =>
  ({ type: 'payloadFilters/ARCHIVE_PAYLOAD_FILTER', original, modified }) as const;

const archiveDone = (response: PayloadFilter) =>
  ({ type: 'payloadFilters/ARCHIVE_PAYLOAD_FILTER_DONE', response }) as const;

const archiveFailed = (modified: PayloadFilter, error: ImmutableServerError) =>
  ({
    type: 'payloadFilters/ARCHIVE_PAYLOAD_FILTER_FAILED',
    payloadFilter: modified,
    error,
  }) as const;

export function archivePayloadFilterAction(
  original: PayloadFilter,
  modified: PayloadFilter,
  projKey: string,
  queryParamFilters: PayloadFilterFilters,
) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(archive(original, modified));
    return patchPayloadFilter(projKey, original, modified)
      .then((response) => {
        dispatch(archiveDone(response));
      })
      .then(async () => dispatch(fetchPayloadFilters(projKey, queryParamFilters)))
      .catch((error) => {
        dispatch(archiveFailed(modified, error));
      });
  };
}

const PayloadFiltersActionCreators = {
  archive,
  archiveDone,
  archiveFailed,
  create,
  createDone,
  createFailed,
  edit,
  request,
  requestDone,
  requestFailed,
  requestFilter,
  requestFilterDone,
  requestFilterFailed,
  update,
  updateDone,
  updateFailed,
};

export type PayloadFiltersAction = GenerateActionType<typeof PayloadFiltersActionCreators>;

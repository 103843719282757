import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { enablePaginationOnGetSegments } from '@gonfalon/dogfood-flags';
import { toSegments, toSegmentTargeting } from '@gonfalon/navigator';
import { type GetSegmentsQueryParams } from '@gonfalon/openapi';
import { isRESTAPIError, useCreateSegment, useDeleteSegment, useSegments, useUpdateSegment } from '@gonfalon/rest-api';
import { SnackbarQueue } from '@launchpad-ui/components';
import { fromJS, Map } from 'immutable';

import {
  createSegmentDone,
  createSegmentFailed,
  createSegmentStart,
  deleteSegmentDone,
  deleteSegmentFailed,
  deleteSegmentStart,
  fetchSegmentsDone,
  fetchSegmentsFailed,
  fetchSegmentsStart,
} from 'actions/segments';
import { useDispatch } from 'hooks/useDispatch';
import { useSelector } from 'hooks/useSelector';
import { segmentTargetingManagerSelector } from 'reducers/segments';
import { normalizeGetSegmentsResponse } from 'sources/SegmentAPI';
import { ImmutableServerError } from 'utils/httpUtils';
import { createSegment, Segment, SegmentSourceType } from 'utils/segmentUtils';

/**
 *
 * There are a handful of redux interactions in this file, because segment form state still lives there and we depend
 * on segments in redux elsewhere in the app.
 * All notifications are dispatched from redux because they also update the store. Eventually they should all
 * be updated to dispatch a snackbar instead.
 */

const useGetSegmentsQuery = ({
  projectKey,
  environmentKey,
  params,
  enabled,
}: {
  projectKey: string;
  environmentKey: string;
  params?: GetSegmentsQueryParams;
  enabled?: boolean;
}) => {
  const dispatch = useDispatch();

  const isPaginationEnabledOnGetSegments = enablePaginationOnGetSegments();
  const canUseUpdatedSegmentsApi = isPaginationEnabledOnGetSegments;

  const semanticTargetingManagerState = useSelector(segmentTargetingManagerSelector);

  const query = useSegments(
    { projectKey, environmentKey, params: canUseUpdatedSegmentsApi ? params : undefined },
    { enabled },
  );

  const { isSuccess, data: jsonResponse, isError, error, isFetching } = query;

  const normalizedResponse = jsonResponse ? normalizeGetSegmentsResponse(jsonResponse) : Map();

  useEffect(() => {
    if (isFetching) {
      dispatch(fetchSegmentsStart());
      return;
    }

    if (isSuccess) {
      dispatch(fetchSegmentsDone(normalizedResponse, semanticTargetingManagerState.getIn(['original', 'key'])));
      return;
    }

    if (isError) {
      dispatch(fetchSegmentsFailed(error as ImmutableServerError));
      return;
    }
  }, [isFetching, isSuccess, isError]);

  return { ...query, normalizedResponse };
};

const useCreateSegmentMutation = (source?: SegmentSourceType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mutation = useCreateSegment();

  const { isPending, variables } = mutation;
  const creatingSegment = variables?.body;

  useEffect(() => {
    if (isPending) {
      dispatch(createSegmentStart(createSegment(creatingSegment)));
    }
  }, [isPending]);

  const mutate: typeof mutation.mutate = (variables_) =>
    mutation.mutate(variables_, {
      onSuccess: async (_, { body: createdSegment, projectKey, environmentKey }) => {
        dispatch(createSegmentDone(createSegment(createdSegment)));
        await navigate(
          toSegmentTargeting(
            {
              projectKey,
              environmentKey,
              segmentKey: createdSegment.key,
            },
            {
              search: source ? new URLSearchParams({ 'new-source': source.toString() }) : undefined,
            },
          ),
        );
      },
      onError: (error, { body: segment }) => {
        dispatch(createSegmentFailed(createSegment(segment), fromJS(error)));
        SnackbarQueue.error({ description: isRESTAPIError(error) || error instanceof Error ? error.message : '' });
      },
    });

  return { ...mutation, mutate };
};

const useEditSegmentSettingsMutation = () => {
  const dispatch = useDispatch();
  const segmentRef = useRef<Segment | null>(null);

  const mutation = useUpdateSegment();

  // a workaround to allow us to pass the immutable Segment record to the mutation without adding
  // it as props to the rest-api
  const mutateFn: typeof mutation.mutate = (variables_) =>
    mutation.mutate(variables_, {
      onSuccess: () => {
        dispatch({ type: 'segments/UPDATE_SEGMENT_SETTINGS_DONE', segment: segmentRef.current });
      },
      onError: (error) => {
        dispatch({ type: 'segments/UPDATE_SEGMENT_SETTINGS_FAILED', segment: segmentRef.current, error });
      },
    });

  const mutate = (segment: Segment) => {
    segmentRef.current = segment;
    return mutateFn;
  };

  const { isPending } = mutation;

  useEffect(() => {
    if (isPending) {
      dispatch({ type: 'segments/UPDATE_SEGMENT_SETTINGS', segment: segmentRef.current });
      return;
    }
  }, [isPending]);

  return { ...mutation, mutate };
};

const useDeleteSegmentMutation = (segment: Segment) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mutation = useDeleteSegment();

  const { isPending } = mutation;

  useEffect(() => {
    if (isPending) {
      dispatch(deleteSegmentStart(segment));
      return;
    }
  }, [isPending]);

  const mutate: typeof mutation.mutate = (variables) =>
    mutation.mutate(variables, {
      onSuccess: async (data, { projectKey, environmentKey }) => {
        dispatch(deleteSegmentDone(segment));
        await navigate(toSegments({ projectKey, environmentKey }));
      },
      onError: (error) => {
        dispatch(deleteSegmentFailed(segment, fromJS(error)));
      },
    });

  return { ...mutation, mutate };
};

export { useGetSegmentsQuery, useCreateSegmentMutation, useEditSegmentSettingsMutation, useDeleteSegmentMutation };

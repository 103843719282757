export enum DestinationKinds {
  Kinesis = 'kinesis',
  Pubsub = 'google-pubsub',
  Segment = 'segment',
  MParticle = 'mparticle',
  Azure = 'azure-event-hubs',
  Snowflake = 'snowflake',
  SnowflakeV2 = 'snowflake-v2',
}
export const KINESIS = DestinationKinds.Kinesis;
export const PUBSUB = DestinationKinds.Pubsub;
export const SEGMENT = DestinationKinds.Segment;
export const MPARTICLE = DestinationKinds.MParticle;
export const AZURE = DestinationKinds.Azure;
export const SNOWFLAKE = DestinationKinds.Snowflake;
export const SNOWFLAKE_V2 = DestinationKinds.SnowflakeV2;
export const DESTINATION_KINDS = [KINESIS, PUBSUB, MPARTICLE, SEGMENT, AZURE];
export const AMAZON_REGIONS = [
  {
    code: 'us-east-1',
    name: 'N Virginia',
  },
  {
    code: 'us-east-2',
    name: 'Ohio',
  },
  {
    code: 'us-west-1',
    name: 'N California',
  },
  {
    code: 'us-west-2',
    name: 'Oregon',
  },
  {
    code: 'ca-central-1',
    name: 'Canada',
  },
  {
    code: 'eu-west-1',
    name: 'Ireland',
  },
  {
    code: 'eu-west-2',
    name: 'London',
  },
  {
    code: 'eu-west-3',
    name: 'Paris',
  },
  {
    code: 'eu-central-1',
    name: 'Frankfurt',
  },
  {
    code: 'ap-northeast-1',
    name: 'Tokyo',
  },
  {
    code: 'ap-northeast-2',
    name: 'Seoul',
  },
  {
    code: 'ap-northeast-3',
    name: 'Osaka',
  },
  {
    code: 'ap-southeast-1',
    name: 'Singapore',
  },
  {
    code: 'ap-southeast-2',
    name: 'Sydney',
  },
  {
    code: 'ap-south-1',
    name: 'Mumbai',
  },
  {
    code: 'sa-east-1',
    name: 'Sao Paulo',
  },
];

export const MPARTICLE_ENVIRONMENTS = ['production', 'development'];

export const MPARTICLE_USERID_TYPES = [
  'customer_id',
  'email',
  'facebook',
  'twitter',
  'google',
  'microsoft',
  'mpid',
  'other',
  'other2',
  'other3',
  'other4',
];

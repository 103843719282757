import { forwardRef } from 'react';
import { Link, useNavigate } from 'react-router';
import { isString } from '@gonfalon/es6-utils';
import { Icon } from '@launchpad-ui/icons';
import type { IconButtonProps } from 'launchpad';
import { Dropdown, IconButton, Menu, MenuDivider, MenuItem, PopoverPlacement } from 'launchpad';

import { EllipsisButtonType } from './types';

const defaultButtonAriaLabel = 'More options';

type HamburgerButtonProps = {
  /**
   * Use an aria-label if you don't pass in children and don't have a visible label to associate with the HamburgerButton.
   */
  'aria-label'?: string;
  'aria-expanded'?: boolean;
  onClick?(): void;
  size: IconButtonProps['size'];
};

export const HamburgerButton = forwardRef<HTMLButtonElement, HamburgerButtonProps>(
  ({ 'aria-label': ariaLabel, 'aria-expanded': ariaExpanded, onClick, size }, ref) => (
    <IconButton
      aria-label={ariaLabel || defaultButtonAriaLabel}
      aria-expanded={ariaExpanded}
      icon={<Icon name="more-vert" />}
      onClick={onClick}
      size={size}
      ref={ref}
    />
  ),
);

type EllipsisButtonProps = {
  /**
   * Use an aria-label if you don't pass in children and don't have a visible label to associate with the EllipsesButton.
   */
  'aria-label'?: string;
  'aria-expanded'?: boolean;
  onClick?(): void;
  testId?: string;
  className?: string;
  disabled?: boolean;
  size?: IconButtonProps['size'];
};

export const VerticalEllipsisButton = forwardRef<HTMLButtonElement, Omit<EllipsisButtonProps, 'disabled'>>(
  ({ 'aria-label': ariaLabel, 'aria-expanded': ariaExpanded, onClick, testId, className, size }, ref) => (
    <IconButton
      icon={<Icon name="more-vert" />}
      aria-label={ariaLabel || defaultButtonAriaLabel}
      aria-expanded={ariaExpanded}
      className={className}
      onClick={onClick}
      data-test-id={testId}
      ref={ref}
      size={size}
    />
  ),
);

export const EllipsisButton = forwardRef<HTMLButtonElement, EllipsisButtonProps>(
  (
    { 'aria-label': ariaLabel, 'aria-expanded': ariaExpanded, onClick, testId, className, size, disabled = false },
    ref,
  ) => (
    <IconButton
      aria-label={ariaLabel || defaultButtonAriaLabel}
      aria-expanded={ariaExpanded}
      className={className}
      icon={<Icon name="more-horiz" />}
      onClick={onClick}
      disabled={disabled}
      data-test-id={testId}
      size={size}
      ref={ref}
    />
  ),
);

type ChartAreaButtonProps = {
  /**
   * Use an aria-label if you don't pass in children and don't have a visible label to associate with the ChartAreaButton.
   */
  'aria-label'?: string;
  'aria-expanded'?: boolean;
  onClick?(): void;
  testId?: string;
  className?: string;
  disabled?: boolean;
  size: IconButtonProps['size'];
};

export const ChartAreaButton = forwardRef<HTMLButtonElement, ChartAreaButtonProps>(
  (
    { 'aria-label': ariaLabel, 'aria-expanded': ariaExpanded, onClick, testId, className, disabled = false, size },
    ref,
  ) => (
    <IconButton
      aria-label={ariaLabel || defaultButtonAriaLabel}
      aria-expanded={ariaExpanded}
      className={className}
      onClick={onClick}
      data-test-id={testId}
      disabled={disabled}
      size={size}
      ref={ref}
      icon={<Icon name="chart-area" />}
    />
  ),
);

export type EllipsisButtonWithDropdownMenuItem = {
  disabled?: boolean;
  label?: string | JSX.Element;
  key?: string;
  onClick?(event?: React.MouseEvent): void;
  tooltip?: string | JSX.Element;
  tooltipPlacement?: PopoverPlacement;
  reloadDocument?: boolean;
  icon?: JSX.Element;
} & EllipsisButtonWithDropdownMenuItemWithLink &
  Divider;
type Url = string | { pathname: string; search: string };
type EllipsisButtonWithDropdownMenuItemWithLink =
  | {
      useMenuItemLink: true;
      newTab?: false;
      url: Url;
    }
  | {
      useMenuItemLink: true;
      newTab: true;
      url: Url;
    }
  | {
      useMenuItemLink?: false;
      newTab: true;
      url: Url;
    }
  | {
      useMenuItemLink?: false;
      newTab?: false;
      url?: Url;
    };
type Divider =
  | { isDivider: true; label?: string | JSX.Element; key?: string }
  | { isDivider?: false; label: string | JSX.Element; key: string };

type EllipsisButtonWithDropdownProps = {
  /**
   * Use an aria-label if you don't pass in children and don't have a visible label to associate with the Dropdown Button.
   */
  'aria-label'?: string;
  type?: EllipsisButtonType;
  className?: string;
  menuItemClassName?: string;
  items: EllipsisButtonWithDropdownMenuItem[];
  onClickEllipsisButton?(): void;
  testId?: string;
  isOpen?: boolean;
  onInteraction?(): void;
  size?: IconButtonProps['size'];
  menuDisabled?: boolean;
  popoverClassName?: string;
  enforceFocus?: boolean;
};

export const EllipsisButtonWithDropdown = forwardRef<HTMLButtonElement, EllipsisButtonWithDropdownProps>(
  (
    {
      'aria-label': ariaLabel,
      items,
      type,
      onClickEllipsisButton,
      testId,
      className,
      menuItemClassName,
      isOpen,
      onInteraction,
      size,
      menuDisabled = false,
      popoverClassName,
      enforceFocus,
    },
    ref,
  ) => {
    const navigate = useNavigate();

    const renderIconButton = () => {
      switch (type) {
        case EllipsisButtonType.VERTICAL:
          return (
            <VerticalEllipsisButton
              aria-expanded={isOpen}
              aria-label={ariaLabel}
              className={className}
              onClick={onClickEllipsisButton}
              size={size}
              testId={testId}
              ref={ref}
            />
          );
        case EllipsisButtonType.HAMBURGER:
          return (
            <HamburgerButton
              aria-expanded={isOpen}
              size={size}
              aria-label={ariaLabel}
              onClick={onClickEllipsisButton}
              ref={ref}
            />
          );
        case EllipsisButtonType.CHART:
          return (
            <ChartAreaButton
              aria-expanded={isOpen}
              aria-label={ariaLabel}
              className={className}
              size={size}
              onClick={onClickEllipsisButton}
              disabled={menuDisabled}
              testId={testId}
              ref={ref}
            />
          );
        default:
          return (
            <EllipsisButton
              aria-expanded={isOpen}
              className={className}
              size={size}
              aria-label={ariaLabel}
              onClick={onClickEllipsisButton}
              testId={testId}
              ref={ref}
            />
          );
      }
    };

    const handleSelect = async (index: number) => {
      const selectedItem = items[index];
      const url = isString(selectedItem.url) ? { pathname: selectedItem.url } : selectedItem.url;
      if (url && !selectedItem.useMenuItemLink) {
        if (selectedItem.reloadDocument) {
          const pageUrl = new URL(url.pathname, location.origin);
          if ('search' in url) {
            pageUrl.search = url.search;
          }
          window.location.href = pageUrl.toString();
          return;
        }

        await navigate(url);
      }
    };

    return (
      <Dropdown<number>
        placement="bottom-end"
        onSelect={handleSelect}
        isOpen={isOpen}
        onInteraction={onInteraction}
        disabled={menuDisabled}
        popoverClassName={popoverClassName}
        enforceFocus={enforceFocus}
      >
        {renderIconButton()}
        <Menu menuItemClassName={menuItemClassName}>
          {items.map((item, num) => {
            if (item.isDivider) {
              return <MenuDivider key={num} />;
            }

            if (item.useMenuItemLink || item.newTab) {
              return (
                <MenuItem key={item.key} disabled={false} onClick={item.onClick} asChild icon={item.icon}>
                  <Link to={item.url} target={item.newTab ? '_blank' : undefined} reloadDocument={item.reloadDocument}>
                    {item.label}
                  </Link>
                </MenuItem>
              );
            }

            return (
              <MenuItem
                key={item.key}
                disabled={item.disabled}
                tooltip={item.tooltip}
                onClick={item.onClick}
                onKeyDown={(event: React.KeyboardEvent<HTMLAnchorElement>) => {
                  if (event.key === 'Enter') {
                    item.onClick?.();
                  }
                }}
                component={(!!item.url && Link) as typeof Link | undefined}
                to={item.url || ''}
                tooltipPlacement={item.tooltipPlacement}
                icon={item.icon}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Dropdown>
    );
  },
);

ChartAreaButton.displayName = 'ChartAreaButton';
EllipsisButton.displayName = 'EllipsisButton';
HamburgerButton.displayName = 'HamburgerButton';
VerticalEllipsisButton.displayName = 'VerticalEllipsisButton';

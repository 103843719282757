import { Theme } from '@gonfalon/theme';

const grayBase = (themeValue: Theme) => (isDarkTheme(themeValue) ? 'var(--lp-color-border-field-base)' : '#8a929c'); // --lp-color-gray-500
const textColorPrimary = (themeValue: Theme) =>
  isDarkTheme(themeValue) ? 'var(--lp-color-text-ui-primary-base)' : '#212529'; // --lp-color-gray-950
const textColorTertiary = (themeValue: Theme) =>
  isDarkTheme(themeValue) ? 'var(--lp-color-text-ui-secondary)' : 'var(--lp-color-gray-600)'; // --lp-color-gray-600

export const selectCustomControlStyles = (themeValue: Theme, isFocused: boolean, isDisabled: boolean) => ({
  backgroundColor: isDisabled ? 'var(--lp-color-bg-interactive-disabled)' : 'var(--lp-color-bg-field-base)',
  minHeight: '2rem',
  display: 'flex',
  overflow: 'visible',
  width: '100%',
  boxShadow: isFocused ? 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 0 3px rgba(0,126,255,.1)' : 0,
  borderColor: isFocused ? 'var(--lp-color-border-field-focus)' : 'var(--lp-color-border-field-base)',
  '&:hover': {
    borderColor: isFocused ? 'var(--lp-color-border-field-focus)' : 'var(--lp-color-border-field-base)',
  },
  outline: 0,
});

export const selectCustomSingleValueStyles = {
  fontSize: 'var(--lp-font-size-200)',
  color: 'var(--lp-color-text-ui-primary-base)',
};

export const selectCustomMenuStyles = (themeValue: Theme) => ({
  zIndex: 101, // One less than all modals and manager controls, and sticky menu
  borderColor: grayBase(themeValue),
  backgroundColor: isDarkTheme(themeValue) ? 'var(--lp-color-gray-950)' : 'var(--lp-color-white-950)',
  fontSize: 'var(--lp-font-size-200)',
});

export const selectCustomMenuListStyles = {};

export const selectCustomMenuPortalStyles = {
  zIndex: 101, // One less than all modals and manager controls, and sticky menu
};
export const selectCustomMultiValueStyles = {
  backgroundColor: 'var(--lp-color-bg-interactive-secondary-hover)',
  borderRadius: '3px',
  maxWidth: '32.5rem',
};

export const selectCustomInputStyles = {
  fontSize: 'var(--lp-font-size-200)',
  color: 'var(--lp-color-text-ui-primary-base)',
};

export const selectCustomMultiValueLabelStyles = {
  color: 'var(--lp-color-text-ui-primary-base)',
};

export const selectCustomMultiValueRemoveStyles = {
  '&:hover': {
    backgroundColor: 'var(--lp-color-gray-400)',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export const selectCustomPlaceholderStyles = (themeValue: Theme) => ({
  fontSize: 'var(--lp-font-size-200)',
  color: textColorTertiary(themeValue),
});

export const selectCustomIndicatorStyles = (themeValue: Theme) => ({
  color: textColorTertiary(themeValue),
  '&:hover': {
    color: textColorPrimary(themeValue),
    cursor: 'pointer',
  },
  padding: '0.3125rem',
});

export const selectCustomOptionStyles = (
  themeValue: Theme,
  isSelected: boolean,
  isFocused: boolean,
  isDisabled: boolean,
) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  // state: default
  backgroundColor: isDarkTheme(themeValue) ? 'var(--lp-color-gray-950)' : 'var(--lp-color-white-950)',
  color: isDarkTheme(themeValue) ? 'var(--lp-color-white-950)' : 'var(--lp-color-gray-950)',
  borderColor: isDarkTheme(themeValue) ? 'var(--lp-color-gray-950)' : 'var(--lp-color-white-950)',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '2px',
  padding: 'var(--lp-size-4) var(--lp-size-6)',

  // state: focused
  ...(isFocused && {
    backgroundColor: isDarkTheme(themeValue) ? 'var(--lp-color-gray-950)' : 'var(--lp-color-gray-50)',
    borderColor: isDarkTheme(themeValue) ? 'var(--lp-color-blue-400)' : 'var(--lp-color-blue-500)',
  }),

  // state: selected
  ...(isSelected && {
    backgroundColor: isDarkTheme(themeValue) ? 'var(--lp-color-gray-800)' : 'var(--lp-color-blue-200)',
    borderColor: isDarkTheme(themeValue) ? 'var(--lp-color-blue-400)' : 'var(--lp-color-blue-500)',
  }),

  // state: disabled
  ...(isDisabled && {
    backgroundColor: 'none',
    color: 'var(--lp-color-text-interactive-disabled)',
  }),

  // state: hover
  '&:hover': {
    cursor: !isDisabled ? 'pointer' : 'not-allowed',
    backgroundColor: (() => {
      if (isDisabled) {
        return;
      }

      return isDarkTheme(themeValue) ? 'var(--lp-color-gray-900)' : 'var(--lp-color-blue-200)';
    })(),
    borderColor: isDarkTheme(themeValue) ? 'var(--lp-color-blue-400)' : 'var(--lp-color-blue-500)',
  },
});

const isDarkTheme = (themeValue: Theme) => themeValue === 'dark';

import { Label } from '@launchpad-ui/components';
import clsx from 'clsx';

import styles from './RequiredLabel.module.css';

export const RequiredLabel: React.FC<{ label: string; htmlFor?: string; className?: string }> = ({
  label,
  htmlFor,
  className,
}) => (
  <span className={styles.outerSpan}>
    <Label htmlFor={htmlFor}>{label}</Label>
    <span className={clsx(styles.requiredAsterisk, className)}>*</span>
  </span>
);

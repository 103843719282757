import { VALID_ANALYSIS_PERCENTILES } from './constants';
import { Metric } from './types';

export function getAnalysisTypeDisplayName(analysisType: Metric['analysisType'], percentileValue?: number): string {
  if (analysisType === 'mean') {
    return 'Average';
  } else if (analysisType === 'percentile' && percentileValue) {
    if (VALID_ANALYSIS_PERCENTILES.includes(percentileValue)) {
      return `P${percentileValue}`;
    }
  }
  return 'undefined';
}

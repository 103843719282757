import { isNumber } from '@gonfalon/es6-utils';
import { conditionallyFormatAsSciENotation } from '@gonfalon/format';
import { List } from 'immutable';
import qs from 'qs';

import {
  ExperimentsResultQuery,
  ExperimentSummaryTotal,
  MetricOperatorToggleOption,
  SuccessCriteria,
  VariationResultChangeType,
} from 'components/FlagExperiments/types';
import { lastUsedTzid } from 'utils/dateUtils';
import { Fallthrough, Flag, Rollout, Rule, VersionResults } from 'utils/flagUtils';

import Logger from './logUtils';

export const logger = Logger.get('FlagExperiment');

export const P_VALUE_THRESHOLD = 0.05;

const RESULTS_MANTISSA = 1;
export const formatCount = (num: number | string | null | undefined, options = {}) =>
  conditionallyFormatAsSciENotation(num, {
    minimumFractionDigits: RESULTS_MANTISSA,
    ...options,
  });

export const formatPercent = (num: number | string | null | undefined, options = {}) =>
  conditionallyFormatAsSciENotation(num, {
    minimumFractionDigits: RESULTS_MANTISSA,
    style: 'percent',
    ...options,
  });

export const calculatePValue = (statSig: number) => 1 - statSig;

export const getExperimentSeriesResultsQuery = (
  from: Date | number | undefined,
  to: Date | number | undefined,
  pageSearchQuery: string,
  metricOperator?: MetricOperatorToggleOption,
) => {
  let query: ExperimentsResultQuery = {};
  if (from && to) {
    query = { ...query, from: from.valueOf(), to: to.valueOf(), tz: lastUsedTzid() };
  }
  // These url parameters let us pass through some overrides to the API
  // 'approx' requests that the API returns HLL-backed results
  const approx = qs.parse(pageSearchQuery, { ignoreQueryPrefix: true }).approx;
  if (approx) {
    query.approx = approx;
  }
  // 'useObservedEffectForRelativeDelta' makes our stats minimum sample size calculate the min sample size for the improvement
  // currently seen in the experiment rather than for a fixed "relative delta" value.
  // https://launchdarkly.atlassian.net/wiki/spaces/ENG/pages/739314130/DACI+Experiment+Minimum+Sample+Size
  const useObservedEffect = qs.parse(pageSearchQuery, { ignoreQueryPrefix: true }).useObservedEffectForRelativeDelta;
  if (useObservedEffect) {
    query.useObservedEffectForRelativeDelta = useObservedEffect;
  }

  if (metricOperator) {
    query.sumMetrics = metricOperator === MetricOperatorToggleOption.SUM;
  }

  return query;
};

export const getExperimentSummaryResultsQuery = (
  from: Date | number | undefined,
  to: Date | number | undefined,
  pageSearchQuery: string,
  metricOperator?: MetricOperatorToggleOption,
) => getExperimentSeriesResultsQuery(from, to, pageSearchQuery, metricOperator);

export const createInitialNullDataPoint = (flag: Flag, queryFromDate: Date | number) =>
  flag.variations.reduce((accum, _, i) => ({ ...accum, [i]: null }), { time: queryFromDate.valueOf() });

export const getConfidenceIntervalExtremes = (flag: Flag, totals: ExperimentSummaryTotal[]) => {
  let hasExperimentConfidenceIntervalData = false;
  let lowerExtreme: number | undefined;
  let upperExtreme: number | undefined;

  flag.variations.forEach((_, index) => {
    const conversionConfidenceInterval = totals[index] ? totals[index].cumulativeConfidenceInterval : null;

    if (conversionConfidenceInterval) {
      hasExperimentConfidenceIntervalData = true;
      lowerExtreme =
        lowerExtreme === undefined || conversionConfidenceInterval.lower < lowerExtreme
          ? conversionConfidenceInterval.lower
          : lowerExtreme;

      upperExtreme =
        upperExtreme === undefined || conversionConfidenceInterval.upper > upperExtreme
          ? conversionConfidenceInterval.upper
          : upperExtreme;
    }
  });

  return hasExperimentConfidenceIntervalData
    ? {
        lowerExtreme,
        upperExtreme,
      }
    : null;
};

export const getV1ConfidenceIntervalExtremes = (control: VersionResults, experiment: VersionResults) => {
  const controlConversionRate = control.conversionRate;
  const controlConfidenceInterval = control.confidenceInterval;
  const controlLower = controlConversionRate - controlConfidenceInterval;
  const controlUpper = controlConversionRate + controlConfidenceInterval;

  const experimentConversionRate = experiment.conversionRate;
  const experimentConfidenceInterval = experiment.confidenceInterval;
  const experimentLower = experimentConversionRate - experimentConfidenceInterval;
  const experimentUpper = experimentConversionRate + experimentConfidenceInterval;

  return {
    lowerExtreme: controlLower < experimentLower ? controlLower : experimentLower,
    upperExtreme: controlUpper > experimentUpper ? controlUpper : experimentUpper,
  };
};

export const isVariationResultStatisticallySignificant = (pValue: number | null) =>
  isNumber(pValue) && pValue < P_VALUE_THRESHOLD;

export const getVariationResultChangeType = (
  change: number | null,
  isNumeric: boolean,
  successCriteria: SuccessCriteria | null,
) => {
  const higherIsBetter = successCriteria === null || successCriteria === SuccessCriteria.HIGHER_THAN_BASELINE;
  if (change === null) {
    return;
  }

  if (change > 0) {
    return higherIsBetter ? VariationResultChangeType.IMPROVEMENT : VariationResultChangeType.SETBACK;
  } else if (change < 0) {
    return higherIsBetter ? VariationResultChangeType.SETBACK : VariationResultChangeType.IMPROVEMENT;
  } else if (change === 0) {
    return VariationResultChangeType.NO_CHANGE;
  }
};

export const getChangeValue = (
  totals: ExperimentSummaryTotal[] = [],
  baselineIdx: number,
  varIdx: number,
  isNumeric: boolean,
) => {
  const valueKey = isNumeric ? 'cumulativeValue' : 'cumulativeConversionRate';
  const baselineVal = totals[baselineIdx] ? totals[baselineIdx][valueKey] : null;
  const variationVal = totals[varIdx] ? totals[varIdx][valueKey] : null;

  if (baselineVal === null || variationVal === null || baselineIdx === varIdx) {
    return null;
  }

  return variationVal - baselineVal;
};

export const hasExistingExperimentRollouts = (rules: List<Rule>, fallthrough?: Fallthrough) => {
  if (rules.some((r) => r.getIn(['rollout', 'experimentAllocation']))) {
    return true;
  }
  return !!fallthrough?.getIn(['rollout', 'experimentAllocation']);
};

export const defaultExperimentRolloutVariation = (baselineIdx?: number, offVariation?: number, rollout?: Rollout) => {
  if (isNumber(rollout?.getIn(['experimentAllocation', 'defaultVariation']))) {
    return rollout?.getIn(['experimentAllocation', 'defaultVariation']);
  }
  const experimentRolloutDefaultVariation = typeof baselineIdx === 'number' ? baselineIdx : offVariation;
  if (isNumber(experimentRolloutDefaultVariation)) {
    return experimentRolloutDefaultVariation;
  }
  return 0;
};

import Abbr from 'components/ui/abbr/Abbr';

/* eslint-disable import/no-default-export */
export default function MAUAbbr() {
  return (
    <Abbr role="tooltip" tabIndex={0} title="Monthly active users">
      MAUs
    </Abbr>
  );
}

import { SetURLSearchParams } from 'react-router';
import { createTrackerForCategory } from '@gonfalon/analytics';

import { ApprovalRequestResourceKind, ReviewersCount } from 'utils/approvalsUtils';

type ApprovalRequestTrackEventInfo = {
  isRequired?: boolean;
  fromSaveChanges?: boolean; // true if user selects approval request in modal
  bypassApprovals?: boolean;
  approvalSystem?: string;
  resourceKind?: ApprovalRequestResourceKind;
  changeKind?: string;
  reviewerType?: string;
  reviewersCount?: ReviewersCount;
  numFlagsChanged?: number;
};

type ReviewActionsInfo = {
  timeSinceCreation: number;
  timeSinceApproval?: number;
};

export const trackApprovalRequestEvent = (event: string, attributes?: ApprovalRequestTrackEventInfo) => {
  createTrackerForCategory('Approval Request')(event, attributes);
};

export const trackApprovalSettingsButtonClick = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Approval Settings Viewed', attributes);

export const trackApprovalPanelLoadedEvent = () => {
  trackApprovalRequestEvent('Approval Settings Panel Loaded');
};

export const trackApprovalRequestView = (
  searchParams: URLSearchParams,
  updateSearchParams: SetURLSearchParams,
  approvalSystem?: string,
  resourceKind?: ApprovalRequestResourceKind,
) => {
  trackApprovalRequestEvent('Approval Request Viewed', { approvalSystem, resourceKind });

  const cleanParams = new URLSearchParams(searchParams);
  cleanParams.delete('source');

  if (cleanParams.size > 0) {
    updateSearchParams(cleanParams, { replace: true });
  }
};

export const trackApprovalRequestCreated = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Approval Request Created', attributes);

export const trackCreateApprovalRequestButtonClick = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Request Approval Button Clicked', attributes);

export const trackApprovalRequestDropdownButtonClick = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Approval Request Dropdown Button Clicked', attributes);

export const trackApprovalRequestApproveButtonClick = (attributes: ApprovalRequestTrackEventInfo & ReviewActionsInfo) =>
  trackApprovalRequestEvent('Approval Request Approve Button Clicked', attributes);

export const trackApprovalRequestApplyButtonClick = (attributes: ApprovalRequestTrackEventInfo & ReviewActionsInfo) =>
  trackApprovalRequestEvent('Approval Request Apply Button Clicked', attributes);

export const trackApprovalRequestDeclineButtonClick = (attributes: ApprovalRequestTrackEventInfo & ReviewActionsInfo) =>
  trackApprovalRequestEvent('Approval Request Decline Button Clicked', attributes);

export const trackApprovalRequestCommentButtonClick = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Approval Request Comment Button Clicked', attributes);

export const trackApprovalRequestDeleteSubmitted = (attributes: ApprovalRequestTrackEventInfo) =>
  trackApprovalRequestEvent('Approval Request Delete Submitted', attributes);

export type ApprovalRequestViewButtonSource =
  | 'Flag variations'
  | 'Flag targeting'
  | 'Approvals dashboard / Status'
  | 'Approvals dashboard / Reason'
  | 'Approvals dashboard / View details';

export const trackApprovalRequestViewButtonClicked = (attributes: {
  approvalSystem: string;
  source: ApprovalRequestViewButtonSource;
  resourceKind?: ApprovalRequestResourceKind;
}) => trackApprovalRequestEvent('Approval Request View Button Clicked', attributes);

export const trackApprovalRequestApplyChangesViewButtonClicked = (attributes: {
  approvalSystem: string;
  source: ApprovalRequestViewButtonSource;
  resourceKind?: ApprovalRequestResourceKind;
}) => trackApprovalRequestEvent('Approval Request Apply Changes View Button Clicked', attributes);

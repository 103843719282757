import { MetricEventUrl, MetricEventUrlKind } from './types';

export function toMetricEventUrl({ kind, value }: { kind: MetricEventUrlKind; value: string }): MetricEventUrl {
  switch (kind) {
    case 'regex':
      return { kind, pattern: value };
    case 'substring':
      return { kind, substring: value };
    case 'canonical':
    // fallthrough
    case 'exact':
      return { kind, url: value };
    default:
      throw new Error(`Unknown metric event url kind: ${kind}`);
  }
}

import { enforceResourceNameLength } from '@gonfalon/dogfood-flags';
import { SegmentType, trackAddTargetsToSegment } from '@gonfalon/segments';
import { isValidKey } from '@gonfalon/strings';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record, Set } from 'immutable';
import { v4 } from 'uuid';

import { AccessChecks, allowDecision, CheckAccessFunction, createAccessDecision } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';
import { Clause, createClause } from 'utils/clauseUtils';
import { CreateFunctionInput, findIndexByKey, ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { isLength, isNotEmpty, isReservedKey, isValidTagList, validateRecord } from 'utils/validationUtils';

import { USER_CONTEXT_KIND } from './constants';
import { SegmentVariationTypes } from './expiringContextTargetsUtils';

const MAX_SEGMENT_NAME_LENGTH = 256;
const MAX_SEGMENT_KEY_LENGTH = 256;

const validateSegment = (segment: Segment) =>
  validateRecord(
    segment,
    isNotEmpty('name'),
    enforceResourceNameLength() ? isLength(1, MAX_SEGMENT_NAME_LENGTH)('name') : () => undefined,
    isNotEmpty('key'),
    isValidKey('key'),
    isReservedKey('key'),
    isLength(1, MAX_SEGMENT_KEY_LENGTH)('key'),
    isValidTagList('tags'),
  );

export function createSegmentForm(props = {}) {
  return props instanceof Segment ? props : new Segment(props);
}

type SegmentRuleProps = {
  _key: string;
  _id: string;
  clauses: List<Clause>;
  weight?: number;
  rolloutContextKind?: string;
  bucketBy?: string;
  description?: string;
};

export class SegmentRule extends Record<SegmentRuleProps>({
  _key: '',
  _id: '',
  clauses: List.of(createClause()),
  weight: undefined,
  rolloutContextKind: USER_CONTEXT_KIND,
  bucketBy: undefined,
  description: '',
}) {
  hasNonUserContextClause() {
    return this.clauses.some((c) => !!c.contextKind && c.contextKind !== USER_CONTEXT_KIND);
  }
  hasNonUserContextRollout() {
    return !!this.rolloutContextKind && this.rolloutContextKind !== USER_CONTEXT_KIND;
  }
  hasNestedSegmentClause() {
    return this.clauses.some((c) => c.hasSegmentOp());
  }
}

type RelatedFlagProps = {
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
  }>;
  _site: Link;
  key: string;
  name: string;
};

export class RelatedFlag extends Record<RelatedFlagProps>({
  _links: Map(),
  _site: Map(),
  key: '',
  name: '',
}) {
  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  siteLink(): string {
    return this._site.get('href');
  }
}

type BigSegmentTargetProps = {
  included: boolean;
  excluded: boolean;
};

export class BigSegmentTarget extends Record<BigSegmentTargetProps>({
  included: false,
  excluded: false,
}) {}

export function createBigSegmentTarget(props = {}) {
  return props instanceof BigSegmentTarget ? props : new BigSegmentTarget(props);
}

type SegmentTargetProps = {
  values: List<string>;
  contextKind: string;
};
export class SegmentTarget extends Record<SegmentTargetProps>({
  values: List(),
  contextKind: '',
}) {
  getContextKind() {
    return this.contextKind || USER_CONTEXT_KIND;
  }
}

export type SegmentProps = {
  _links: ImmutableMap<{
    parent: Link;
    self: Link;
    site: Link;
  }>;
  _access?: AccessChecks;
  _flags: List<RelatedFlag>;
  _flagCount: number;
  key: string;
  name: string;
  version: number;
  description: string;
  tags: Set<string>;
  creationDate: number;
  lastModifiedDate: number;
  rules: List<SegmentRule>;
  included: List<string>;
  excluded: List<string>;
  includedContexts: List<SegmentTarget>;
  excludedContexts: List<SegmentTarget>;
  unbounded: boolean;
  unboundedContextKind?: string;
  _external: string;
  _externalLink: string;
  _unboundedMetadata: {
    version: number;
    includedCount: number;
    excludedCount: number;
    lastModified: number;
  };
  _importInProgress: boolean;
  type?: SegmentProvisionType;
};

export class Segment extends Record<SegmentProps>({
  _links: Map(),
  _access: undefined,
  _flags: List(),
  _flagCount: 0,
  name: '',
  key: '',
  version: 0,
  description: '',
  tags: Set(),
  creationDate: 0,
  lastModifiedDate: 0,
  rules: List(),
  included: List(),
  excluded: List(),
  includedContexts: List(),
  excludedContexts: List(),
  unbounded: false,
  unboundedContextKind: '',
  _external: '',
  _externalLink: '',
  _unboundedMetadata: {
    version: 0,
    includedCount: 0,
    excludedCount: 0,
    lastModified: 0,
  },
  _importInProgress: false,
}) {
  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  siteLink() {
    return this._links.getIn(['site', 'href']);
  }

  checkAccess(profile: Member): CheckAccessFunction {
    const access = this._access;

    if (profile.isReader()) {
      return () =>
        createAccessDecision({
          isAllowed: false,
          appliedRoleName: 'Reader',
        });
    }

    if (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  validate() {
    return validateSegment(this);
  }

  addRule(rule?: SegmentRule, atIndex?: number) {
    if (atIndex !== undefined) {
      return this.update('rules', (rs) => rs.insert(atIndex, rule ? rule : createSegmentRule()));
    }
    return this.update('rules', (rs) => rs.push(rule ? rule : createSegmentRule()));
  }

  deleteRule(rule: SegmentRule) {
    const index = findIndexByKey(this.rules, rule);

    if (index === -1) {
      return this;
    }

    return this.update('rules', (rs) => rs.delete(index));
  }

  addRuleClause(rule: SegmentRule, clause?: Clause) {
    const index = findIndexByKey(this.rules, rule);

    if (index === -1) {
      return this;
    }

    const ruleClauses = this.rules.get(index)?.clauses;

    let contextKind: string = USER_CONTEXT_KIND;

    if (!!ruleClauses && !ruleClauses.isEmpty()) {
      contextKind = ruleClauses.last<Clause>().contextKind;
    }

    return this.updateIn(['rules', index], (r) =>
      r.update('clauses', (cs: List<Clause>) => cs.push(clause ? clause : createClause({ contextKind }))),
    );
  }

  deleteRuleClause(rule: SegmentRule, clause: Clause) {
    const ruleIndex = findIndexByKey(this.rules, rule);

    if (ruleIndex === -1) {
      return this;
    }

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const clauseIndex = findIndexByKey(
      this.rules.get(ruleIndex)!.clauses,
      clause,
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */

    if (clauseIndex === -1) {
      return this;
    }

    return this.updateIn(['rules', ruleIndex], (r) =>
      r.update('clauses', (cs: List<Clause>) => cs.delete(clauseIndex)),
    );
  }

  editRuleClause(rule: SegmentRule, clause: Clause) {
    const ruleIndex = findIndexByKey(this.rules, rule);

    if (ruleIndex === -1) {
      return this;
    }

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const clauseIndex = findIndexByKey(
      this.rules.get(ruleIndex)!.clauses,
      clause,
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */

    if (clauseIndex === -1) {
      return this;
    }

    return this.setIn(['rules', ruleIndex, 'clauses', clauseIndex], clause);
  }

  setRuleRollout(rule: SegmentRule, weight: number) {
    const ruleIndex = findIndexByKey(this.rules, rule);

    if (ruleIndex === -1) {
      return this;
    }

    return this.setIn(['rules', ruleIndex, 'weight'], weight);
  }

  setRuleRolloutContextKind(rule: SegmentRule, contextKind: string) {
    const ruleIndex = findIndexByKey(this.rules, rule);

    if (ruleIndex === -1) {
      return this;
    }

    return this.setIn(['rules', ruleIndex, 'rolloutContextKind'], contextKind);
  }

  setRuleBucket(rule: SegmentRule, attr: string) {
    const ruleIndex = findIndexByKey(this.rules, rule);

    if (ruleIndex === -1) {
      return this;
    }

    return this.setIn(['rules', ruleIndex, 'bucketBy'], attr);
  }

  setRuleIndex(ruleKey: string, index: number) {
    return this.update('rules', (rules) => {
      const previousIndex = rules.findIndex((r) => r._key === ruleKey);
      if (previousIndex === -1 || index === -1) {
        return rules;
      }
      const rule = rules.get(previousIndex);
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      return rules
        .delete(previousIndex)
        .insert(index, rule!); /* eslint-enable @typescript-eslint/no-non-null-assertion */
    });
  }

  setRuleDescription(rule: SegmentRule, description: string) {
    const ruleIndex = findIndexByKey(this.rules, rule);

    if (ruleIndex === -1) {
      return this;
    }

    return this.setIn(['rules', ruleIndex, 'description'], description);
  }

  getTargetCount(segmentVariation: SegmentVariationTypes) {
    let targetValues = 0;
    if (segmentVariation === SegmentVariationTypes.INCLUDED) {
      this.includedContexts.forEach((target) => {
        targetValues += target.values.size;
      });
      targetValues += this.included.size;
    } else {
      this.excludedContexts.forEach((target) => {
        targetValues += target.values.size;
      });
      targetValues += this.excluded.size;
    }
    return targetValues;
  }

  hasTargetsOrRules() {
    return this.countAllTargets() > 0 || this.rules.size > 0;
  }

  setContextTarget(
    targets: List<string>,
    contextKind: string,
    index: number,
    field: 'includedContexts' | 'excludedContexts',
  ) {
    trackAddTargetsToSegment(contextKind);
    // delete the SegmentTarget stored in the included or excluded context field if the list of targets has been cleared for a given context
    if (targets.isEmpty()) {
      if (index >= 0) {
        return this.deleteIn([field, index]);
      } else {
        return this;
      }
    }
    if (index >= 0) {
      // update the list of targets for the given context if it already exists
      return this.updateIn([field, index], (f) => f.set('values', targets));
    } else {
      // create a new SegmentTarget in includedContexts if none already exists for a given context
      const target = new SegmentTarget({ values: targets, contextKind });
      return this.update(field, (f) => f.push(target));
    }
  }

  getContextIndex(variation: 'includedContexts' | 'excludedContexts', contextKind: string) {
    return this[variation].findIndex((ic) => ic.contextKind === contextKind);
  }

  isTargetingNonUserContexts() {
    const hasNonUserTargets = !this.includedContexts.isEmpty() || !this.excludedContexts.isEmpty();
    const hasNonUserTargetingRules = this.rules.some(
      (r) => r.hasNonUserContextClause() || r.hasNonUserContextRollout(),
    );
    return hasNonUserTargets || hasNonUserTargetingRules;
  }

  hasNestedSegmentRule() {
    return this.rules.some((r) => r.hasNestedSegmentClause());
  }

  setIncludedTargets(targets: List<string>, contextKind: string) {
    // user context targets are stored in the included field
    if (contextKind === 'user') {
      trackAddTargetsToSegment(contextKind);
      return this.set('included', targets);
    }
    const index = this.getContextIndex('includedContexts', contextKind);
    return this.setContextTarget(targets, contextKind, index, 'includedContexts');
  }

  setExcludedTargets(targets: List<string>, contextKind: string) {
    // user context targets are stored in the excluded field
    if (contextKind === 'user') {
      trackAddTargetsToSegment(contextKind);
      return this.set('excluded', targets);
    }
    const index = this.getContextIndex('excludedContexts', contextKind);
    return this.setContextTarget(targets, contextKind, index, 'excludedContexts');
  }

  // Combines user and context targets
  getAllTargets(type: SegmentVariationTypes) {
    let contexts = type === SegmentVariationTypes.INCLUDED ? this.includedContexts : this.excludedContexts;
    const userIdx = contexts.findIndex((t) => t.contextKind === USER_CONTEXT_KIND);
    const values = type === SegmentVariationTypes.INCLUDED ? this.included : this.excluded;
    const target = new SegmentTarget({ values, contextKind: USER_CONTEXT_KIND });
    if (userIdx > -1) {
      contexts = contexts.set(userIdx, target);
    } else {
      contexts = contexts.push(target);
    }
    return contexts;
  }

  countAllTargets() {
    if (this.unbounded) {
      const includedCount = this.getIn(['_unboundedMetadata', 'includedCount']);
      const excludedCount = this.getIn(['_unboundedMetadata', 'excludedCount']);
      return includedCount + excludedCount;
    }

    const includedCount = this.getTargetCount(SegmentVariationTypes.INCLUDED);
    const excludedCount = this.getTargetCount(SegmentVariationTypes.EXCLUDED);

    return includedCount + excludedCount;
  }

  countAllRules() {
    return this.rules.size;
  }

  countAllTargetsAndRules() {
    return this.countAllTargets() + this.countAllRules();
  }

  hasDependentFlags() {
    return this._flags.size > 0;
  }

  isBasic() {
    return !this.unbounded;
  }

  isBig() {
    return this.unbounded;
  }

  isNativeBig() {
    return this.isBig() && !this._external;
  }

  isSynced() {
    return this.isBig() && !!this._external;
  }

  updateBigSegmentTargetCount({ addedCount, removedCount }: { addedCount: number; removedCount: number }) {
    if (!this.isNativeBig()) {
      return this;
    }
    const startingCount: number = this.getIn(['_unboundedMetadata', 'includedCount']);
    const updatedCount = startingCount + addedCount - removedCount;
    return this.setIn(['_unboundedMetadata', 'includedCount'], updatedCount);
  }

  isEmptyBigSegment() {
    if (!this.isNativeBig()) {
      return false;
    }
    const hasEmptyTargets = (this.getIn(['_unboundedMetadata', 'includedCount']) as number) === 0;
    return hasEmptyTargets && !this._importInProgress;
  }

  getType() {
    if (this.isSynced() && this._external === SegmentType.AMPLITUDE) {
      return SegmentType.AMPLITUDE;
    }

    if (this.isNativeBig()) {
      return SegmentType.NATIVE_BIG;
    }

    return SegmentType.REGULAR;
  }
}

export const getSegmentTargetByContext = (targets: List<SegmentTarget>, contextKind: string) => {
  const target = targets.find((t) => t.contextKind === contextKind);
  return target ? target.values : List();
};
export function createSegmentRule(props = {}) {
  const rule = props instanceof SegmentRule ? props : new SegmentRule(props);
  return rule.update('_key', (key) => (key ? key : v4())).update('clauses', (cs) => cs.map(createClause));
}

export function createSegment(props = {}) {
  const segment = props instanceof Segment ? props : new Segment(fromJS(props));
  return segment
    .update('tags', (ts) => ts.toSet())
    .update('rules', (rs) => rs.map(createSegmentRule))
    .update('includedContexts', (ic) => ic?.map((i) => new SegmentTarget(i)))
    .update('excludedContexts', (ec) => ec?.map((e) => new SegmentTarget(e)))
    .update('_flags', (fs: List<RelatedFlag>) => (fs ? fs.map((f) => new RelatedFlag(f)) : fs));
}

export enum SegmentProvisionType {
  TEMPORARY = 'temporary',
  PERMANENT = 'permanent',
}

const bigSegmentIndividualTargetUpdatesFields = {
  added: List(),
  removed: List(),
};

type BigSegmentIndividualTargetUpdatesProps = {
  added: List<string>;
  removed: List<string>;
};
export type BigSegmentIndividualTargetUpdatesField = keyof typeof bigSegmentIndividualTargetUpdatesFields;
export class BigSegmentIndividualTargetUpdates extends Record<BigSegmentIndividualTargetUpdatesProps>(
  bigSegmentIndividualTargetUpdatesFields,
) {
  updateTargetsList(field: BigSegmentIndividualTargetUpdatesField, targetKey: string) {
    return this.update(field, (f) => f.push(targetKey));
  }
  getTargetUpdateField(targetKey: string): BigSegmentIndividualTargetUpdatesField | undefined {
    if (this.added.includes(targetKey)) {
      return 'added';
    }
    if (this.removed.includes(targetKey)) {
      return 'removed';
    }
    return;
  }
  toRep() {
    return {
      included: {
        add: this.added.toArray(),
        remove: this.removed.toArray(),
      },
    };
  }
}

export function createBigSegmentIndividualTargetUpdates(
  props: CreateFunctionInput<BigSegmentIndividualTargetUpdatesProps> = {},
) {
  return props instanceof BigSegmentIndividualTargetUpdates
    ? props
    : new BigSegmentIndividualTargetUpdates(fromJS(props));
}

export enum BigSegmentUploadMode {
  REPLACE = 'replace',
  MERGE = 'merge',
}

export function idOrKey<T extends { _id?: string; _key?: string }>(obj: T): string {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return (obj._id || obj._key)!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

export enum SegmentSourceType {
  RULES = 'rules',
  LIST = 'list',
  SYNC = 'sync',
}

export const getSegmentSourceType = (source: string) => {
  switch (source) {
    case 'rules':
      return SegmentSourceType.RULES;
    case 'list':
      return SegmentSourceType.LIST;
    case 'sync':
      return SegmentSourceType.SYNC;
    default:
      return null;
  }
};

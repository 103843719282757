import { MouseEventHandler, ReactNode } from 'react';
import { Icon } from '@launchpad-ui/icons';
import clsx from 'clsx';
import { IconButton } from 'launchpad';

import styles from './Pagination.module.css';

type PaginationRenderProps = {
  from: number;
  to: number;
  total: number | string;
};

type PaginationProps = {
  from: number;
  to: number;
  total: number | string;
  onFirstClick?: MouseEventHandler<HTMLButtonElement>;
  onPreviousClick?: MouseEventHandler<HTMLButtonElement>;
  onNextClick?: MouseEventHandler<HTMLButtonElement>;
  onLastClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  children?: ((values: PaginationRenderProps) => ReactNode) | undefined;
};

export function Pagination({
  from,
  to,
  total,
  className,
  children,
  onFirstClick,
  onPreviousClick,
  onNextClick,
  onLastClick,
}: PaginationProps) {
  return (
    <div className={clsx(styles.pagination, className)}>
      <IconButton
        size="small"
        icon={<Icon name="chevrons-left" size="small" />}
        aria-label="first page"
        disabled={onFirstClick === undefined}
        onClick={onFirstClick}
      />
      <IconButton
        size="small"
        icon={<Icon name="chevron-left" size="small" />}
        aria-label="previous page"
        disabled={onPreviousClick === undefined}
        onClick={onPreviousClick}
      />
      <div className={styles.hud}>
        {typeof children === 'function' ? (
          children({ from, to, total })
        ) : (
          <>
            <strong>
              {from}–{to}
            </strong>{' '}
            of <strong>{total}</strong>
          </>
        )}
      </div>
      <IconButton
        size="small"
        icon={<Icon name="chevron-right" size="small" />}
        aria-label="next page"
        disabled={onNextClick === undefined}
        onClick={onNextClick}
      />
      <IconButton
        size="small"
        icon={<Icon name="chevrons-right" size="small" />}
        aria-label="last page"
        disabled={onLastClick === undefined}
        onClick={onLastClick}
      />
    </div>
  );
}

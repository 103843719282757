import { Link } from 'react-router';
import cx from 'clsx';

import { trackScheduledConflictsEvent } from 'utils/scheduledChangesUtils';

import { InstructionListItem } from './InstructionListItem';
import InstructionListItemIcon from './InstructionListItemIcon';
import { InstructionListChangeKind, RULE_NOT_FOUND } from './instructionListUtils';

export type NestedInstructionListItemProps = {
  subCategoryHeader: string;
  subCategoryDetails?: string;
  isOrderedList?: boolean;
  showConflictLink?: boolean;
  conflictLink?: string;
  changeKind?: InstructionListChangeKind;
  children: JSX.Element[];
  onExit?: () => void;
};

export const NestedInstructionListItem = ({
  subCategoryHeader,
  subCategoryDetails,
  isOrderedList,
  showConflictLink,
  conflictLink,
  changeKind = InstructionListChangeKind.DEFAULT,
  children,
  onExit,
}: NestedInstructionListItemProps) => {
  const SubList = isOrderedList ? 'ol' : 'ul';
  const detailsNotFound = subCategoryDetails === RULE_NOT_FOUND;
  return (
    <InstructionListItem
      className="InstructionList-subCategory"
      changeKind={InstructionListChangeKind.NONE}
      onExit={onExit}
    >
      <span className="NestedInstructionListItem-content">
        {changeKind === InstructionListChangeKind.REORDER && (
          <InstructionListItemIcon testId="nestedInstructionList-icon" changeKind={changeKind} />
        )}
        <h3 className="u-fw-regular">
          {subCategoryHeader}
          {!subCategoryDetails && ':'}
          {subCategoryDetails && (
            <span
              className={cx({
                'u-italic u-fw-regular': detailsNotFound,
                'u-fw-semibold': !detailsNotFound,
              })}
            >
              {' '}
              {subCategoryDetails}:
            </span>
          )}
          {showConflictLink && conflictLink && (
            <Link
              className="u-ml-s"
              to={conflictLink}
              onClick={() => {
                trackScheduledConflictsEvent('View Conflict Clicked');
                onExit?.();
              }}
            >
              View conflict
            </Link>
          )}
        </h3>
      </span>

      <SubList
        className={cx('InstructionList-subList', 'NestedInstructionListItem-subList', {
          'InstructionList-orderedSubList': isOrderedList,
        })}
      >
        {children}
      </SubList>
    </InstructionListItem>
  );
};

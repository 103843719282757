import { schemas } from '@gonfalon/openapi';

import MetricKind, { MetricGroupKind } from './MetricKind';

type Metric = schemas['MetricRep'];
type CredibleInterval = schemas['CredibleIntervalRep'];
type RelativeDifference = schemas['RelativeDifferenceRep'];
type MetricGroupRep = schemas['MetricGroupRep'];
type MetricInGroupRep = schemas['MetricInGroupRep'];
type MetricListingRep = schemas['MetricListingRep'];
type MetricListingRepExpandableProperties = schemas['MetricListingRepExpandableProperties'];
type DiscoveredMetricEvent = schemas['DiscoveredMetricEventWithMetrics'];
type DependentMetric = schemas['MetricV2Rep'];
type EventActivityResponseItem = schemas['EventActivityResponseItemRep'];

type MetricEventUrlKind = 'canonical' | 'exact' | 'regex' | 'substring';

type MetricEventUrl =
  | {
      kind: 'canonical' | 'exact';
      url: string;
      pattern?: never;
      substring?: never;
    }
  | {
      kind: 'regex';
      pattern: string;
      url?: never;
      substring?: never;
    }
  | {
      kind: 'substring';
      substring: string;
      url?: never;
      pattern?: never;
    };

type FilterableMetricDashboardItem = {
  type: 'metric' | 'metric-group';
  key: string;
  name: string;
  description?: string;
  _creationDate: number;
  experimentCount?: number;
  kind: MetricKind | MetricGroupKind;
  isNumeric?: boolean;
  unitAggregationType?: 'sum' | 'average';
  tags: string[];
  _attachedFlagCount?: number;
  metricGroupCount?: number;
  metricCount?: number;
};

export const METRIC_ANALYTICS_CHART_TYPES = {
  LINE: 'LINE' as const,
  BAR: 'BAR' as const,
};

type MetricAnalyticsChartType = keyof typeof METRIC_ANALYTICS_CHART_TYPES;

type MetricAnalyticsTableRowValue = {
  analysisType: string;
  analysisValue?: number;
  average?: number;
  color: string;
  groupByKey?: string;
  groupByValue?: string;
  isHigherBetter: boolean;
  metricKey: string;
  metricName: string;
  numberOfEvents: number;
  originalIndex: number;
  total?: number;
  trend: { currentPeriod?: number; percentageChange: string | number; previousPeriod?: number };
  unit?: string;
};

type MeasureType = 'count' | 'occurrence' | 'value';

export {
  type Metric,
  type MetricEventUrlKind,
  type MetricEventUrl,
  type FilterableMetricDashboardItem,
  type MeasureType,
  type CredibleInterval,
  type RelativeDifference,
  type MetricGroupRep,
  type MetricInGroupRep,
  type MetricListingRep,
  type MetricListingRepExpandableProperties,
  type DiscoveredMetricEvent,
  type DependentMetric,
  type EventActivityResponseItem,
  type MetricAnalyticsChartType,
  type MetricAnalyticsTableRowValue,
};

import { useLayoutEffect, useRef } from 'react';
import { noop } from '@gonfalon/es6-utils';
import { IconButton } from '@launchpad-ui/components';
import classNames from 'clsx';
import Prism from 'prismjs';

import 'prismjs/components/prism-rust';

import { CopyToClipboard } from '../CopyToClipboard';

import './styles.css';

export const languages = [
  '',
  'bash',
  'shell',
  'json',
  'html',
  'xml',
  'js',
  'javascript',
  'lua',
  'ts',
  'typescript',
  'php',
  'java',
  'ruby',
  'python',
  'go',
  'csharp',
  'c',
  'cpp',
  'objectivec',
  'swift',
  'makefile',
  'haskell',
  'erlang',
  'brightscript',
  'dart',
  'rust',
  'tsx',
];

export type SnippetLang =
  | ''
  | 'bash'
  | 'shell'
  | 'json'
  | 'html'
  | 'xml'
  | 'js'
  | 'javascript'
  | 'lua'
  | 'ts'
  | 'typescript'
  | 'php'
  | 'java'
  | 'ruby'
  | 'python'
  | 'go'
  | 'csharp'
  | 'c'
  | 'cpp'
  | 'objectivec'
  | 'swift'
  | 'makefile'
  | 'haskell'
  | 'erlang'
  | 'brightscript'
  | 'text'
  | 'dart'
  | 'rust'
  | 'tsx';

type SnippetProps = {
  children: string | JSX.Element;
  className?: string;
  highlightRange?: string;
  highlightOffset?: number;
  lang?: SnippetLang;
  label?: string;
  withHeader?: boolean;
  withLineNumbers?: boolean;
  useDefaultHighlighting?: boolean;
  withCopyButton?: boolean;
  trackAnalyticsOnClick?: () => void;
};

// Example usage:
//
// const json = JSON.stringify({
//    'key': 'test@test.com',
//    'ip': '192.168.0.1',
//    'custom': {
//      'customer_ranking': 10004
//    }
// }, null, 2);
//
// <Snippet withCopyButton={true} lang="json">{json}</Snippet>
export function Snippet({
  children,
  className,
  highlightRange,
  highlightOffset,
  lang,
  label,
  withHeader,
  withLineNumbers,
  useDefaultHighlighting = false,
  withCopyButton,
  trackAnalyticsOnClick,
}: SnippetProps) {
  const codeEl = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    const element = codeEl.current;
    if (!element) {
      return;
    }

    // Use requestAnimationFrame to ensure that the element is mounted
    // before highlighting it.
    const frame = requestAnimationFrame(() => {
      Prism.highlightElement(element);
    });

    // Cancel the animation frame when the component unmounts.
    return () => cancelAnimationFrame(frame);
  }, [children, lang]);

  const classes = classNames('Snippet', className, {
    'Snippet--copyable': withCopyButton,
    'Snippet--useDefaultHighlighting': useDefaultHighlighting,
  });

  return (
    <>
      {withHeader && (
        <div className="header">
          {label && <span>{label}</span>}
          {lang && <span>{lang}</span>}
        </div>
      )}
      <div className={classes}>
        <pre
          className={withLineNumbers ? 'line-numbers' : ''}
          data-start={1}
          data-line-offset={highlightOffset ? highlightOffset.toString() : ''}
          data-line={highlightRange}
        >
          <code className={`language-${lang}`} ref={codeEl}>
            {children}
          </code>
          {withCopyButton && (
            <CopyToClipboard text={children as string} showTooltip={false} onPressEvent={trackAnalyticsOnClick || noop}>
              <IconButton className="copyButton" aria-label="Copy code snippet" variant="minimal" icon="copy-code" />
            </CopyToClipboard>
          )}
        </pre>
      </div>
    </>
  );
}

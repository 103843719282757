import { MetricEventUrl } from './types';

export function isMetricEventUrl(url: unknown): url is MetricEventUrl {
  if (!(typeof url === 'object' && url !== null && 'kind' in url)) {
    return false;
  }

  if (typeof url.kind !== 'string') {
    return false;
  }

  if (['canonical', 'exact'].includes(url.kind) && 'url' in url && typeof url.url === 'string') {
    return true;
  }

  if (url.kind === 'regex' && 'pattern' in url && typeof url.pattern === 'string') {
    return true;
  }

  if (url.kind === 'substring' && 'substring' in url && typeof url.substring === 'string') {
    return true;
  }

  return false;
}

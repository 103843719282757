import { MetricEventUrl, MetricEventUrlKind } from './types';

export function fromMetricEventUrl({ kind, pattern, substring, url }: MetricEventUrl): {
  kind: MetricEventUrlKind;
  value: string;
} {
  switch (kind) {
    case 'regex':
      return { kind, value: pattern };
    case 'substring':
      return { kind, value: substring };
    case 'canonical':
    // fallthrough
    case 'exact':
      return { kind, value: url };
    default:
      throw new Error(`Unknown metric event url kind: ${kind}`);
  }
}

import { SnowflakeIcon } from '@gonfalon/icons';
import { TagButton } from '@launchpad-ui/components';

import styles from './SnowflakeSourceTag.module.css';

export const SnowflakeSourceTag = () => (
  <TagButton className={styles.snowflakeTag}>
    <SnowflakeIcon size="small" /> Snowflake
  </TagButton>
);

import { matchPath } from 'react-router';
import { enableSignedSAMLAuthnRequests } from '@gonfalon/dogfood-flags';
import invariant from 'tiny-invariant';

import { getDestinationUrl } from 'actions/navigation';
import { SignupForm } from 'utils/accountUtils';
import http, { jsonToImmutableError } from 'utils/httpUtils';
import { LoginInfo } from 'utils/loginUtils';
import { ForgotPassword, ResetPassword } from 'utils/resetPasswordUtils';

export async function login(info: LoginInfo | SignupForm) {
  return http
    .put('/internal/account/login', {
      body: info,
    })
    .catch(jsonToImmutableError);
}

export async function loginV2(info: LoginInfo, redirectPath?: string) {
  const { username, password, accountId, oauth } = info;
  const convertUsernameToEmail = {
    email: username,
    password,
    accountId,
    oauth,
  };
  const body = enableSignedSAMLAuthnRequests()
    ? { ...convertUsernameToEmail, redirect: redirectPath || getDestinationUrl() }
    : convertUsernameToEmail;
  return http
    .post('/internal/account/login', {
      body,
    })
    .catch(jsonToImmutableError);
}

export async function getLoginPage(email: string, redirectPath?: string) {
  const body: { email: string; redirect?: string } = { email };

  body.redirect = redirectPath ? redirectPath : getDestinationUrl();

  return http
    .put('/internal/account/login2', {
      body,
    })
    .catch(async (err) => jsonToImmutableError(err));
}

export async function escalateSession(info: LoginInfo) {
  return http
    .post('/internal/account/session/escalate', {
      body: info,
    })
    .catch(jsonToImmutableError);
}

export async function loginWithMFA(token: string, passcode: string, accountID?: string) {
  return http
    .post('/internal/account/session/mfa', {
      body: { accountID, token, passcode }, // *Account ID is only required for multi-account members.
    })
    .catch(jsonToImmutableError);
}

export async function loginWithMFARecovery(token: string, recoveryCode: string, accountID?: string) {
  return http
    .post('/internal/account/session/mfa-recovery', {
      body: { accountID, token, recoveryCode },
    })
    .catch(jsonToImmutableError);
}

export async function forgotPassword(reset: ForgotPassword) {
  return http
    .post('/internal/forgot', {
      body: JSON.stringify(reset.toJS()),
    })
    .catch(jsonToImmutableError);
}

export async function resetPassword(reset: ResetPassword) {
  const match = matchPath('/reset/:passwordResetToken', window.location.pathname);
  invariant(match?.params.passwordResetToken, 'Expected passwordResetToken route param');

  return http
    .post(`/internal/reset/${match.params.passwordResetToken}`, {
      body: JSON.stringify(reset.toJS()),
    })
    .catch(jsonToImmutableError);
}

export async function logout() {
  return http.delete('/internal/account/session').catch(jsonToImmutableError);
}

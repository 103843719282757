import produce, { Draft } from 'immer';

/**
 * This function returns a reducer function where, for the received action, an "action handler" function is called according to the action's "type" property.
 * Each state update is applied using `produce` from `immer` to make immutable state updates easier.
 * Each "action handler" function is given both the "draft" state, which can be mutated directly, as well as the action payload, if any.
 *
 * This enforces that that a reducer's action type statisies this type:
 * ```ts
 * {
 *  type: string;
 *  payload: { [key: string]: any };
 * }
 * ```
 */
export function createReducer<
  State,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Action extends { type: string; payload?: { [key: string]: any } },
>(actionHandlers: {
  [Type in Action['type']]: Extract<Action, { type: Type }>['payload'] extends undefined
    ? (state: Draft<State>) => void
    : (state: Draft<State>, payload: Extract<Action, { type: Type }>['payload']) => void;
}) {
  return (state: State, action: Action) =>
    produce(state, (draft) => {
      actionHandlers[action.type as Action['type']](draft, action.payload);
    });
}

export const MAX_METRIC_KEY_LENGTH = 256;
export const MAX_METRIC_GROUP_KEY_LENGTH = 256;

export const SuccessCriteriaDisplayOptions = [
  { value: 'HigherThanBaseline', displayName: 'higher is better' },
  { value: 'LowerThanBaseline', displayName: 'lower is better' },
] as const;

export const UNIT_AGGREGATION_TYPE_DISPLAY_NAMES = {
  average: 'average',
  sum: 'sum',
} as const;

export const AnalysisTypeDisplayOptions = [
  { value: 'mean', displayName: 'Average' },
  { value: 99, displayName: 'P99' },
  { value: 95, displayName: 'P95' },
  { value: 90, displayName: 'P90' },
  { value: 75, displayName: 'P75' },
  { value: 50, displayName: 'P50' },
];

export const VALID_ANALYSIS_PERCENTILES = [99, 95, 90, 75, 50];

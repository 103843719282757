import { VALID_ANALYSIS_PERCENTILES } from './constants';
import { Metric } from './types';

export function getAnalysisTypeOption(
  analysisType: Metric['analysisType'] | undefined,
  percentileValue?: number,
): string | number {
  if (analysisType === 'mean' || !analysisType) {
    return 'mean';
  } else if (analysisType === 'percentile' && percentileValue) {
    if (!VALID_ANALYSIS_PERCENTILES.includes(percentileValue)) {
      return 99;
    }
    return percentileValue;
  } else {
    return 'mean';
  }
}

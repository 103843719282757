import { useCallback, useMemo } from 'react';
import { createSearchParams } from 'react-router';
import { useStorageSlot } from '@gonfalon/browser-storage';
import { cacheFlagFiltersAndSortOrdering } from '@gonfalon/dogfood-flags';

import { hasActiveFilters } from './hasActiveFilters';
import { parseEnvironmentAwareUIFlagListViewFromBrowser } from './parseEnvironmentAwareUIFlagListViewFromBrowser';
import { serializeUIFlagFilterForBrowser } from './serializeUIFlagFilterForBrowser';
import { UIFlagFilter } from './types';

export const cacheKey = 'flag-list-filters';

export function useCachedFlagListFilters() {
  const [rawParams, slot] = useStorageSlot(cacheKey, {
    storageType: 'sessionStorage',
    isEnabled: cacheFlagFiltersAndSortOrdering(),
  });

  const cached = useMemo(() => {
    if (!rawParams) {
      return {};
    }

    const params = createSearchParams(rawParams);
    return parseEnvironmentAwareUIFlagListViewFromBrowser(params);
  }, [rawParams]);

  const put = useCallback(
    (value?: UIFlagFilter | undefined) => {
      if (!value) {
        slot.remove();
        return;
      }

      if (!hasActiveFilters(value)) {
        slot.remove();
        return;
      }

      slot.set(serializeUIFlagFilterForBrowser(value).toString());
    },
    [rawParams],
  );

  const value = useMemo(
    () => ({
      value: cached,
      put,
    }),
    [cached, put],
  );

  return value;
}

import { Link } from 'react-router';
import cx from 'clsx';

import { InstructionListChangeKind } from 'components/InstructionList/instructionListUtils';
import { trackScheduledConflictsEvent } from 'utils/scheduledChangesUtils';

import InstructionListItemIcon from './InstructionListItemIcon';

import './styles.css';

type InstructionListItemProps = {
  className?: string;
  description?: string | React.ReactNode;
  showConflictLink?: boolean;
  conflictLink?: string;
  changeKind?: InstructionListChangeKind;
  children: React.ReactNode;
  onExit?: () => void;
};

export function InstructionListItem({
  className,
  description,
  showConflictLink = true,
  conflictLink,
  changeKind = InstructionListChangeKind.DEFAULT,
  children,
  onExit,
}: InstructionListItemProps) {
  const listItemChangeKindClassName = `InstructionListItem-${changeKind}`;
  const testId = changeKind ? `instruction-list-item-${changeKind}` : 'instruction-list-item';

  return (
    <li
      className={cx('InstructionItem', 'InstructionListItem', className, listItemChangeKindClassName)}
      data-test-id={testId}
    >
      <span className="u-flex">
        <InstructionListItemIcon className="InstructionListItemIcon-notNested" changeKind={changeKind} />
        <span className="InstructionItem-content">
          {!!description && <span>{description} </span>}
          {children}
          {showConflictLink && conflictLink && (
            <Link
              to={conflictLink}
              onClick={() => {
                trackScheduledConflictsEvent('View Conflict Clicked');
                onExit?.();
              }}
            >
              View conflict
            </Link>
          )}
        </span>
      </span>
    </li>
  );
}

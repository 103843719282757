import { useMemo } from 'react';
import { useMatches } from 'react-router';
import { z } from 'zod';

const actionSchema = z.object({
  Action: z
    .function()
    .args(z.object({ data: z.any() }))
    .returns(z.any()),
});

/**
 * Render an action for the current route.
 *
 * A route defines its action via its `handle`.
 */
export function Action() {
  const matches = useMatches();

  const RouteAction = useMemo(() => {
    const reversedMatches = [...matches].reverse();

    for (const match of reversedMatches) {
      const parse = actionSchema.safeParse(match.handle);
      if (parse.success) {
        return { Action: parse.data.Action, data: match.data };
      }
    }
    return undefined;
  }, [matches]);

  if (RouteAction === undefined) {
    return null;
  }

  return (
    <div>
      <RouteAction.Action data={RouteAction.data} />
    </div>
  );
}

import { useState } from 'react';
import { createDefaultShortcuts, DateRangeFilterWithTrigger } from '@gonfalon/datetime';
import { endOfDay, startOfDay } from 'date-fns';

import { getFilterDateRange } from '../../getFilterDateRange';

export type ActivityFiltersProps = {
  before?: Date;
  after?: Date;
  onFilterChange: ({ before, after }: { before: Date; after: Date }) => void;
};

export const ActivityDateFilters = ({ before, after, onFilterChange }: ActivityFiltersProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const { start, end } = getFilterDateRange(before, after);

  const [selectedDateRange, setSelectedDateRange] = useState({
    selectedStartDate: start,
    selectedEndDate: end,
  });

  const handleDatesChange = (dates: Date[]) => {
    const [startDate, endDate] = dates;
    setSelectedDateRange({
      selectedStartDate: startDate,
      selectedEndDate: endDate,
    });
  };

  const handleDatesConfirm = () => {
    onFilterChange({
      after: startOfDay(selectedDateRange.selectedStartDate),
      before: endOfDay(selectedDateRange.selectedEndDate),
    });
    setIsDatePickerOpen(false);
  };

  return (
    <DateRangeFilterWithTrigger
      createShortcuts={createDefaultShortcuts}
      isOpen={isDatePickerOpen}
      dates={[selectedDateRange.selectedStartDate, selectedDateRange.selectedEndDate]}
      onChange={handleDatesChange}
      onConfirm={handleDatesConfirm}
    />
  );
};

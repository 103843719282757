import { createTrackerForCategory } from '@gonfalon/analytics';
import {
  enableReleaseGuardianRefreshedUI,
  enforceResourceNameLength,
  isBypassApprovalsEnabled,
  userTargetAlertThreshold,
  userTargetWarningThreshold,
  variationValueNumberField,
} from '@gonfalon/dogfood-flags';
import { isBoolean, isEmpty, isEqual, isString, noop } from '@gonfalon/es6-utils';
import {
  canDeprecateFlag,
  createMissingEnvironmentError,
  FeatureFlagConfig,
  getAllTargetsForVariation,
  getCountOfTargetsForVariation,
  getFlagActiveVariationsFromConfig,
  getFlagActiveVariationsFromSummary,
  getFlagVariationDisplayValue,
  getFlagVariationIndexFromId,
  getFlagVariationValueString,
  GetFlagVariationValueStringOptions,
  getRuleKind,
  getVariationFillColor,
  isFlagDeprecated,
  isFlagOnForEnvironment,
  isMobileRule,
  MeasuredRolloutStatus,
} from '@gonfalon/flags';
import { isValidKey } from '@gonfalon/strings';
import { coerceToType, couldBeBoolean, couldBeNumber, isJSONValue, stringifyValue } from '@gonfalon/types';
// eslint-disable-next-line no-restricted-imports
import { fromJS, is, List, Map, Record, Set } from 'immutable';
import nullthrows from 'nullthrows';
import invariant from 'tiny-invariant';
import { v4 } from 'uuid';

import { ContextKind } from 'components/Contexts/types';
import { isTeamOrMember } from 'components/SelectTeamOrMember/SelectTeamOrMemberUtils';
import { FilterKind } from 'components/ui/multipleFilters/types';
import { FlagAndEnvKeys } from 'reducers/flags';
import {
  AccessChecks,
  allowDecision,
  CheckAccessFunction,
  combineAccessDecisions,
  createAccessDecision,
  DeniedAccess,
} from 'utils/accessUtils';
import { createMemberSummary, Member, MemberSummary } from 'utils/accountUtils';
import { Clause, createClause } from 'utils/clauseUtils';
import { createAllCodeRefStatsRecord } from 'utils/codeRefs/codeRefsUtils';
import type { AllCodeRefStats } from 'utils/codeRefs/types';
import { nilFilter } from 'utils/collectionUtils';
import { USER_CONTEXT_KIND } from 'utils/constants';
import { ApprovalSettings, Environment } from 'utils/environmentUtils';
import { FlagStatus } from 'utils/flagStatusUtils';
import { createGoal, Goal } from 'utils/goalUtils';
import { CreateFunctionInput, ImmutableMap, toJS } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { createTeam, Team, TeamSummary } from 'utils/teamsUtils';
import { areAllBooleans, areAllJSON, areAllNumbers, couldAllBeJSON, couldBeJSONValue } from 'utils/typeUtils';
import {
  areAllUnique,
  checkRadioIsInRange,
  isLength,
  isNotEmpty,
  isReservedKey,
  isValidEach,
  isValidEachKeyed,
  isValidTagList,
  predicateFor,
  validateRecord,
  ValidationResults,
} from 'utils/validationUtils';

import {
  makeUpdateRuleVariationInstruction,
  makeUpdateRuleWithMeasuredRolloutInstruction,
  makeUpdateRuleWithMeasuredRolloutV2Instruction,
} from './instructions/rules/helpers';
import { SemanticInstruction } from './instructions/shared/types';
import { validateFlagConfiguration } from './validation/flags';
import { SegmentTarget } from './segmentUtils';

export enum FlagKind {
  BOOLEAN = 'boolean',
  MULTIVARIATE = 'multivariate',
}

export const flagKinds = FlagKind;

export enum VariationType {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
  JSON = 'json',
}
export const variationTypes = VariationType;

export enum FlagTargetingType {
  SEGMENT_TARGETS = 'Target segments',
  INDIVIDUAL_TARGETS = 'Target individuals',
  MOBILE_TARGETS = 'Target mobile apps and devices',
  MOBILE_TARGETS_V3 = 'Target mobile',
  PREREQUISITE_FLAGS = 'Set prerequisite flags',
  PREREQUISITE_FLAGS_V3 = 'Set prerequisites',
  CUSTOM_RULES = 'Target with a custom rule',
  CUSTOM_RULES_V3 = 'Build my own rule',
}

export const flagTargetingTypes = FlagTargetingType;

const MAX_FLAG_NAME_LENGTH = 256;
const vt = variationTypes;

// `endsWith` check is required to make sure we don't swallow periods while typing, eg '3.' => 3
// eslint-disable-next-line
const couldBeNumerical = (v: any) => couldBeNumber(v) && !(v.endsWith && v.endsWith('.'));

const typechecker = {
  [VariationType.NUMBER]: couldBeNumerical,
  [VariationType.BOOLEAN]: couldBeBoolean,
  [VariationType.STRING]: isString,
  [VariationType.JSON]: couldBeJSONValue,
};

const formatter = {
  [VariationType.NUMBER]: () => 'This must be a number',
  [VariationType.BOOLEAN]: () => 'This must be true or false',
  [VariationType.JSON]: () => 'This must be valid JSON',
  [VariationType.STRING]: () => '',
};

const matchesType = (t: VariationType) =>
  predicateFor(
    (v) => !typechecker[t](v),
    () => formatter[t](),
  );

const valueExists = predicateFor(
  (v) => v === null || v === undefined,
  () => 'A value is required',
);

const flagConfigContainsTargetsOrRules = (flagConfig: FeatureFlagConfig) => {
  const { targets, rules, contextTargets } = flagConfig;
  return (
    (targets && targets.length > 0) || (rules && rules.length > 0) || (contextTargets && contextTargets.length > 0)
  );
};

export const sortFallThroughVariations = (variations: List<WeightedVariation>) =>
  variations.sort((a, b) => (a.variation > b.variation ? 1 : -1));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type VariationValue = any;

export type VariationProps = {
  _id: string;
  _key: string;
  _isDraft?: boolean;
  value: VariationValue;
  name: string;
  description: string;
  originalAIValue?: string;
};

export class Variation extends Record<VariationProps>({
  _id: '',
  _key: '',
  value: '',
  name: '',
  description: '',
  originalAIValue: undefined,
}) {
  validate({ requiredType }: { requiredType?: VariationType } = {}) {
    let checks = [valueExists('value')];

    if (requiredType !== null && requiredType !== undefined) {
      checks = checks.concat(matchesType(requiredType)('value'));
    }

    return validateRecord(this, ...checks);
  }

  toRep() {
    return fromJS(this.toJSON()).withMutations((map: Variation) => {
      map.remove('_key');
      map.remove('_id');
    });
  }

  isEmpty() {
    return !isBoolean(this.value) && !couldBeNumber(this.value) && isEmpty(this.value);
  }

  // JSON variations get turned into Immutable.Collection upon response handling.
  // Without this step, a JSON object variation would render as Map { ... }.
  getValueString(options?: GetFlagVariationValueStringOptions) {
    return getFlagVariationValueString(this.toJS().value, options);
  }

  getDisplay(options?: GetFlagVariationValueStringOptions) {
    return getFlagVariationDisplayValue(this.toJS(), options);
  }
}

// TODO: Delete colorVariation once all callsites are importing getVariationFillColor from @gonfalon/flags
export const colorVariation = (index: number) => getVariationFillColor(index);

export function variationKey(variation: Variation) {
  return `variations.${variation._key}`;
}

export type CustomProperty = ImmutableMap<{
  key: string;
  name: string;
  value: Set<string>;
}>;

export function customPropertiesToForm(customProperties?: Map<string, CustomProperty>) {
  return customProperties
    ? customProperties.reduce(
        // (lookup, val, key) => lookup.push(val ? val.set('key', key) : key),
        // toJS because we're going from the custom property API rep to our FE form rep thing
        (lookup, val, key) => lookup.push(createCustomPropertyForm(val.set('key', key).toJS())),
        List<CustomPropertyForm>(),
      )
    : List<CustomPropertyForm>();
}

export type CustomPropertyFormProps = {
  name: string;
  key: string;
  value: Set<string>;
  new: boolean;
  type?: string;
};

export class CustomPropertyForm extends Record<CustomPropertyFormProps>({
  name: '',
  key: '',
  value: Set(),
  new: false,
  type: undefined,
}) {
  validate() {
    const isValidSize = isLength(0, 64);
    const isValidLength = isLength(0, 65536);
    const predicates = [
      isNotEmpty('name'),
      isNotEmpty('key'),
      isValidSize('name'),
      isValidSize('key'),
      isValidSize('value'),
      isValidKey('key'),
      isReservedKey('key'),
      // isValidEach((v) => validateRecord(fromJS({ v }), isValidLength('v')).toJS(), 'value'),
      isValidEach(
        (v: string) => validateRecord(fromJS({ v }), isValidLength('v')).toJS() as ValidationResults,
        'value',
      ),
    ];

    return validateRecord(this, ...predicates);
  }
}

export function addNewCustomProperty(customProperty: List<CustomPropertyForm>) {
  return customProperty.push(createCustomPropertyForm({ new: true }));
}

function customPropertiesFormToRep(customPropertiesList: List<CustomPropertyForm>) {
  return customPropertiesList.reduce(
    // CHEAT: fromJS + toJS here are to brute-force convert a CustomPropertyForm to a CustomProperty rep for the API
    (lookup, val) => lookup.set(val.get('key'), fromJS(val.remove('key').toJS())),
    Map<string, CustomProperty>(),
  );
}

const handleRolloutLogic = (r: Rollout, variationIndex: number, weight: number, variations: List<Variation>) => {
  //we're only updating variations that are not untracked
  const index = r.variations.findIndex((v) => v.variation === variationIndex && !v._untracked);
  const existingVariations = r.variations
    .toJS()
    .map((d) => (d?._untracked ? undefined : d.variation))
    .filter((v) => v !== undefined);

  if (index === -1) {
    const sortedVariations = sortFallThroughVariations(r.variations);
    const hasCurrentVariationsThatAreSorted = sortedVariations.equals(r.variations);
    /* Add all flag variations to flagConfig.fallthrough.rollout (even if rollout for that variation is 0%) and then
      sort the multivariate percentage rollouts when:
        (1) User creates a new percentage rollout
        (2) Current rollouts are sorted and there more flag variations than percentage rollouts (this will not happen in the future)
    */
    if (variations.size > r.variations.size && hasCurrentVariationsThatAreSorted) {
      //First add all flag variations to flagConfig.fallthrough.rollout even if rollout for that variation is 0%
      return r.update('variations', (vs) => {
        variations.forEach((v, i) => {
          if (!existingVariations.includes(i)) {
            // eslint-disable-next-line no-param-reassign
            vs = vs.push(
              createWeightedVariation({
                variation: i,
                weight: variationIndex === i ? weight : 0,
              }),
            );
          }
        });
        //Then sort the list of fallthrough variations
        return sortFallThroughVariations(vs);
      });
    } else {
      //if current variations are not sorted, return unsorted fallthrough variation list:
      return r.update('variations', (vs) =>
        vs.push(
          createWeightedVariation({
            variation: variationIndex,
            weight,
          }),
        ),
      );
    }
  } else {
    //if we are only changing percentages, return updated fallthrough variation list with updated percentages
    return r.updateIn(['variations', index], (wv) => wv.set('weight', weight));
  }
};

export class Defaults extends Record<{ onVariation?: number; offVariation?: number }>({
  onVariation: 0,
  offVariation: 1,
}) {}

export function getDefaultVariations(flag: Flag) {
  return {
    onVariation:
      typeof flag.defaults?.onVariation === 'number' ? flag.variations.get(flag.defaults.onVariation) : undefined,
    offVariation:
      typeof flag.defaults?.offVariation === 'number' ? flag.variations.get(flag.defaults.offVariation) : undefined,
  } as const;
}

export class EnvironmentSettings extends Record<{
  startDate: number;
  stopDate?: number;
  enabledPeriods: List<Map<'startDate' | 'stopDate', number | undefined>>;
}>({ startDate: 0, stopDate: undefined, enabledPeriods: List() }) {}

type ExperimentProps = {
  metricKey: string;
  _metric: Goal;
  environments: List<string>;
  _environmentSettings: Map<string, EnvironmentSettings>;
};

export class Experiment extends Record<ExperimentProps>({
  metricKey: '',
  _metric: new Goal(),
  environments: List(),
  _environmentSettings: Map(),
}) {
  toRep() {
    return fromJS(this.toJSON()).withMutations((map: Experiment) => {
      map.delete('_metric');
      map.delete('_environmentSettings');
    });
  }
}

type ExperimentInfoProps = { baselineIdx: number; items: List<Experiment> };

class ExperimentInfo extends Record<ExperimentInfoProps>({
  baselineIdx: 0,
  items: List(),
}) {
  toRep() {
    return fromJS(this.toJSON()).withMutations((map: ExperimentInfo) => {
      map.update('items', (its) => its.map((it) => it.toRep()));
    });
  }
}

export type ClientSideAvailability = ImmutableMap<{ usingMobileKey: boolean; usingEnvironmentId: boolean }>;

export type FlagPurpose = 'migration' | 'holdout' | 'ai';

export type FlagMigrationSettings = {
  stageCount: number;
  contextKind?: string;
};

export type FlagType = {
  _links: ImmutableMap<{
    self: Link;
    parent: Link;
  }>;
  _maintainer?: Member | MemberSummary;
  _maintainerTeam?: Team;
  _site: Link;
  _version: number;
  archived: boolean;
  archivedDate?: number;
  clientSideAvailability?: ClientSideAvailability;
  codeReferences?: AllCodeRefStats;
  creationDate: number;
  customProperties: List<CustomPropertyForm>;
  // customProperties: OrderedMap<string, CustomPropertyForm>; // The API returns CustomProperty but we use CustomPropertyForm in the UI (for now…)
  defaults?: Defaults;
  description?: string;
  environments: Map<string, FlagConfiguration>;
  experiments: ExperimentInfo;
  goalIds: Set<string>;
  key: string;
  kind: FlagKind;
  maintainerId?: string;
  maintainerTeamKey?: string;
  name: string;
  tags: Set<string>;
  temporary: boolean;
  variations: List<Variation>;
  _purpose?: FlagPurpose;
  migrationSettings?: Map<string, FlagMigrationSettings>;
  releasePipelineKey?: string;
  deprecated: boolean;
  deprecatedDate?: number;
};

export interface FlagMaintainerType {
  idOrKey: string;
  kind: 'member' | 'team';
  member?: Member | MemberSummary;
  name: string;
  team?: Team;
}

export function createFlagMaintainer(newMaintainer: Member | MemberSummary | Team | TeamSummary): FlagMaintainer {
  const { isMember, isTeam } = isTeamOrMember(newMaintainer);
  const newFlagMaintainer: FlagMaintainer = new FlagMaintainer({
    member: isMember ? (newMaintainer as Member) : undefined,
    team: isTeam ? (newMaintainer as Team) : undefined,
    idOrKey: isMember ? (newMaintainer as Member)._id : (newMaintainer as Team).key,
    kind: isMember ? 'member' : 'team',
  });
  return newFlagMaintainer;
}
// FlagMaintainer is a UI-only data model that allows you to have a common interface between team or member maintainers.
export class FlagMaintainer extends Record<FlagMaintainerType>({
  idOrKey: '',
  kind: 'member',
  member: undefined,
  name: '',
  team: undefined,
}) {
  getAvatarURL() {
    switch (this.kind) {
      case 'member':
        return this.get('member')?._avatarUrl;
      case 'team':
        return undefined;
      default:
        return undefined;
    }
  }

  getDisplayName() {
    switch (this.kind) {
      case 'member':
        return this.get('member')?.getDisplayName();
      case 'team':
        return this.get('team')?.getDisplayName();
      default:
        return undefined;
    }
  }
}

export const isMigrationFlag = (purpose?: string) => purpose === 'migration';

export enum RuleLabel {
  RULE = 'rule',
  COHORT = 'cohort',
}

export const replaceRuleLabelText = (label: string, ruleLabel: RuleLabel) => {
  let updatedLabel = label;
  if (ruleLabel === RuleLabel.COHORT) {
    updatedLabel = updatedLabel.replace('rule', 'cohort');
    updatedLabel = updatedLabel.replace('Rule', 'Cohort');
  }
  return updatedLabel;
};

export class Flag extends Record<FlagType>({
  _links: Map(),
  _maintainer: undefined,
  _maintainerTeam: undefined,
  _site: Map(),
  _version: 0,
  archived: false,
  archivedDate: undefined,
  clientSideAvailability: undefined,
  codeReferences: undefined,
  creationDate: 0,
  customProperties: customPropertiesToForm(Map()),
  // customProperties: OrderedMap(),
  defaults: undefined,
  description: undefined,
  deprecated: false,
  deprecatedDate: undefined,
  environments: Map(),
  experiments: new ExperimentInfo(),
  goalIds: Set(),
  key: '',
  kind: flagKinds.BOOLEAN,
  maintainerId: undefined,
  maintainerTeamKey: undefined,
  name: '',
  tags: Set(),
  temporary: true,
  variations: List(),
  _purpose: undefined,
  migrationSettings: undefined,
  releasePipelineKey: undefined,
}) {
  static ALLOWED_ARCHIVABLE_AGE_IN_MONTHS = 3;

  clearGuardedRolloutFromRule(environmentKey: string, rule: Rule | Fallthrough): Flag {
    if (rule instanceof Fallthrough) {
      return this.setFallthroughMeasuredRolloutConfig(environmentKey, undefined);
    }

    return this.setRuleMeasuredRolloutConfig(environmentKey, rule, undefined);
  }

  getDefaultRolloutVariation(controlVariationId?: string, testVariationId?: string): Variation | undefined {
    if (testVariationId && testVariationId !== controlVariationId) {
      // If there is a test variation and it is valid, return it
      return this.variations.find((variation: Variation) => variation._id === testVariationId);
    }
    // Find first variation that isn't the control variation
    return this.variations.find((variation: Variation) => variation._id !== controlVariationId);
  }

  getStageCount() {
    return this.getIn(['migrationSettings', 'stageCount']);
  }

  getMigrationContextKind() {
    return this.getIn(['migrationSettings', 'contextKind']);
  }

  isMigrationFlag() {
    return isMigrationFlag(this.get('_purpose'));
  }

  hasConsistencyChecks() {
    return this.isMigrationFlag() && this.getStageCount() !== 2;
  }

  supportsPrerequisites() {
    return this.isMigrationFlag() && this.getStageCount() === 6 ? false : true;
  }

  getRuleLabel() {
    return this.isMigrationFlag() ? RuleLabel.COHORT : RuleLabel.RULE;
  }

  // Returns the maintainer, regardless of whether they are a member or team.
  getMaintainer(): FlagMaintainer | undefined {
    if (this.maintainerTeamKey) {
      // Team maintainer
      return new FlagMaintainer({
        idOrKey: this._maintainerTeam?.key,
        kind: 'team',
        member: undefined,
        name: this._maintainerTeam?.name,
        team: this._maintainerTeam,
      });
    }
    if (this.maintainerId) {
      // Member maintainer
      return new FlagMaintainer({
        idOrKey: this._maintainer?._id,
        kind: 'member',
        member: this._maintainer,
        name: this._maintainer?.getDisplayName(),
        team: undefined,
      });
    }
  }

  checkAccess({ envKey, profile }: { envKey: string; profile: Member }): CheckAccessFunction {
    const access = this.getIn(['environments', envKey, '_access']);
    if (profile?.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }
    if (profile?.hasStrictWriterRights() || !(access && access.get('denied'))) {
      return allowDecision;
    }
    return (action) => {
      const deniedAction = access.get('denied').find((v: DeniedAccess) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  checkAccessForAllEnvironments({ profile }: { profile: Member }): CheckAccessFunction {
    return (action) => {
      const accessDecisions = this.environments
        .keySeq()
        .toArray()
        .map((envKey) => this.checkAccess({ envKey, profile })(action));

      return accessDecisions.length > 0
        ? combineAccessDecisions(...accessDecisions)
        : createAccessDecision({ isAllowed: false });
    };
  }

  addCustomPropertyValue(customPropertyKey: string, customPropertyValue: string) {
    return this.set(
      'customProperties',
      customPropertiesToForm(
        customPropertiesFormToRep(this.customProperties).update(
          customPropertyKey,
          fromJS({
            key: customPropertyKey,
            name: customPropertyKey.replace('.', ' ').replace(/^\w/, (c) => c.toUpperCase()),
            value: [customPropertyValue],
          }),
          (cmp) => cmp.update('value', (v) => v.toSet().add(customPropertyValue)),
        ),
      ),
    );
  }

  hasCodeReferencesExpandedAndNotZero() {
    const hasCodeReferences = (this.codeReferences?.items?.size ?? 0) > 0;
    return hasCodeReferences;
  }

  hasExperiments() {
    const hasNewExperiments = !!(this.experiments && this.experiments.items.size);
    return !this.goalIds.isEmpty() || hasNewExperiments;
  }

  hasActiveExperiments(environmentKey: string) {
    const hasAnActiveExperiment = this.experiments?.items.some((metric) => {
      const envSetting = metric._environmentSettings.get(environmentKey);
      return !!(envSetting && envSetting.startDate && !envSetting.stopDate);
    });

    return hasAnActiveExperiment;
  }

  hasNonPristineExperiments(environmentKey: string) {
    return (
      !!this.experiments &&
      this.experiments.items.reduce((hasActives, item) => {
        if (hasActives) {
          return hasActives;
        }
        return !!item._environmentSettings.get(environmentKey)?.startDate;
      }, false)
    );
  }

  getNonPristineExperimentsCount(environmentKey: string) {
    if (!this.experiments) {
      return 0;
    }
    return this.experiments.items.reduce(
      (accum, item) => (item._environmentSettings.get(environmentKey)?.startDate ? accum + 1 : accum),
      0,
    );
  }

  hasNonPristineExperimentsInAllEnvs() {
    if (!this.experiments) {
      return false;
    }
    return this.experiments.items.some((item) => {
      const startedEnvKeys = item._environmentSettings.filter(
        (settings, environmentKey) => item._environmentSettings.get(environmentKey)?.startDate,
      );
      return startedEnvKeys.size > 0;
    });
  }

  getEnabledPeriods(metricKey: string, environmentKey: string) {
    return (
      this.get('experiments')
        .get('items')
        .find((experiment) => experiment.get('metricKey') === metricKey)
        ?.get('_environmentSettings')
        .get(environmentKey)
        ?.get('enabledPeriods') ?? List()
    );
  }

  getExperimentStartDate(metricKey: string, environmentKey: string) {
    const experiment = this.experiments.items.find((item) => item.metricKey === metricKey);
    return experiment?.get('_environmentSettings').get(environmentKey)?.get('startDate');
  }

  getExperimentStopDate(metricKey: string, environmentKey: string) {
    const experiment = this.experiments.items.find((item) => item.metricKey === metricKey);
    return experiment?.get('_environmentSettings').get(environmentKey)?.get('stopDate');
  }

  // An experiment is pristine if it has never recorded any data.
  isExperimentPristine(metricKey: string, environmentKey: string) {
    return (
      !this.isExperimentActive(metricKey, environmentKey) && !this.getExperimentStartDate(metricKey, environmentKey)
    );
  }

  isExperimentActive(metricKey: string, environmentKey: string) {
    const experiment = this.experiments.items.find((item) => item.metricKey === metricKey);
    return experiment ? !!experiment.get('environments').find((env) => env === environmentKey) : false;
  }

  isExperimentPaused(metricKey: string, environmentKey: string) {
    return (
      !this.isExperimentActive(metricKey, environmentKey) && !!this.getExperimentStartDate(metricKey, environmentKey)
    );
  }

  updateExperimentEnvironments(metricKey: string, updateFunction: (environments: List<string>) => List<string>) {
    return this.updateIn(['experiments', 'items'], (associatedMetrics: List<Experiment>) =>
      associatedMetrics.map((associated) => {
        if (associated.metricKey !== metricKey) {
          return associated;
        }

        return associated.update('environments', (envs) => updateFunction(envs));
        // };
      }),
    );
  }

  startExperiment(metricKey: string, environmentKey: string) {
    if (this.isExperimentActive(metricKey, environmentKey)) {
      return this;
    }

    return this.updateExperimentEnvironments(metricKey, (environments) => environments.push(environmentKey));
  }

  stopExperiment(metricKey: string, environmentKey: string) {
    if (!this.isExperimentActive(metricKey, environmentKey)) {
      return this;
    }

    return this.updateExperimentEnvironments(metricKey, (environments) =>
      environments.filter((env) => env !== environmentKey),
    );
  }

  selfLink() {
    return this._links?.get('self')?.get('href');
  }

  siteLink(environmentKey: string) {
    // Incident: https://launchdarkly.slack.com/archives/C03L9437YGG
    // At least one customer had a flag with missing environment configurations.
    // This ensures we don't give React Router (v6) an undefined link, which
    // results in a runtime exception.
    return this.readConfiguration(environmentKey, (cfg) => cfg._site.get('href')) || '';
  }

  getKind() {
    if (this.has('kind')) {
      return this.kind;
    }

    const variationType = inferVariationType(this);

    if (this.variations.size === 2 && variationType === vt.BOOLEAN) {
      return flagKinds.BOOLEAN;
    } else {
      return flagKinds.MULTIVARIATE;
    }
  }

  getVariations() {
    return this.variations;
  }

  getBaselineIndex() {
    return this.getIn(['experiments', 'baselineIdx']);
  }

  isBoolean() {
    return this.getKind() === flagKinds.BOOLEAN;
  }

  validate({ variationType }: { variationType?: VariationType } = {}) {
    let predicates = [
      isNotEmpty('name'),
      enforceResourceNameLength() ? isLength(1, MAX_FLAG_NAME_LENGTH)('name') : noop,
      isNotEmpty('key'),
      isValidKey('key'),
      isReservedKey('key'),
      isValidEachKeyed(
        (v: Variation) => v._key,
        (v: Variation) => createVariation(v).validate({ requiredType: variationType }).toJS(),
        'variations',
      ),
    ];

    predicates = [
      ...predicates,
      isValidTagList('tags'),
      areAllUnique(
        (v: Variation) => v.value,
        (v: Variation) => !createVariation(v).isEmpty() || v.value === '',
      )(() => "You can't create variations with the same value", 'variations'),

      // Empty strings for names are fine, but any non-empty string must be unique
      areAllUnique(
        (v) => v.name,
        (v) => !createVariation(v).isEmpty() && v.name !== '',
      )(() => "You can't create variations with the same name.", 'variations'),

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      isValidEach((m: any) => createCustomPropertyForm(m).validate().toJS(), 'customProperties'),
      areAllUnique(
        (p) => p.key,
        (p) => !Map(p).isEmpty(),
      )(() => "You can't create custom properties with the same key", 'customProperties'),
    ];
    return validateRecord(this, ...predicates);
  }

  addVariation(variation: Variation) {
    return this.update('variations', (variations) => variations.push(variation));
  }

  addNewVariation(variationType: VariationType) {
    if (variationType === VariationType.NUMBER && variationValueNumberField()) {
      return this.update('variations', (variations) =>
        variations.push(createVariation({ _isDraft: true, value: NaN })),
      );
    }
    return this.update('variations', (variations) => variations.push(createVariation({ _isDraft: true })));
  }

  editVariation(variation: Variation) {
    const index = this.variations.findIndex((it) => it._key === variation._key);
    return this.updateIn(['variations', index], (it) => it.merge(variation));
  }

  removeVariation(variation: Variation) {
    const index = this.variations.findIndex((it) => it._key === variation._key);
    return this.deleteIn(['variations', index]);
  }

  getVariationType() {
    return detectVariationType(this);
  }

  getPotentialVariationType() {
    return inferVariationType(this);
  }

  tryForcingVariationType(t: VariationType) {
    if (this.getVariationType() !== t && this.getPotentialVariationType() !== t) {
      return this;
    }

    return forceVariationType(this, t);
  }

  hasHomogeneousVariationTypes() {
    return hasHomogeneousVariationTypes(this);
  }

  getConfiguration(environmentKey: string) {
    const config = this.getIn(['environments', environmentKey]);

    if (!config) {
      throw createMissingEnvironmentError(environmentKey);
    }

    return createFlagConfiguration(config);
  }

  hasConfigurationForEnvironment(environmentKey: string) {
    return !!this.getIn(['environments', environmentKey]);
  }

  getFallthrough(environmentKey: string) {
    const config = this.getConfiguration(environmentKey);
    return config?.get('fallthrough');
  }

  setFallthrough(environmentKey: string, fallthrough: Fallthrough) {
    return this.updateConfiguration(environmentKey, (config) => config.set('fallthrough', fallthrough));
  }

  readConfiguration<T>(environmentKey: string, readerFn: (config: FlagConfiguration) => T) {
    const config = this.getIn(['environments', environmentKey]);
    return config ? readerFn(config) : undefined;
  }

  updateConfiguration(environmentKey: string, updaterFn: (config: FlagConfiguration) => FlagConfiguration) {
    return this.updateIn(['environments', environmentKey], (cfg) => updaterFn(cfg));
  }

  getEnvironmentSettingsForm(environmentKey: string) {
    const config = this.getConfiguration(environmentKey);
    const checkRatio = config?.getIn(['migrationSettings', 'checkRatio']);
    const finalConfig = {
      ...config?.toJS(),
      ...(checkRatio
        ? {
            migrationSettings: {
              checkRatio,
            },
          }
        : {}),
    };
    return createEnvFlagSettings(createFlagConfiguration(finalConfig));
  }

  getRuleExclusionForm(environmentKey: string) {
    return createRuleExclusionRecord(this.getConfiguration(environmentKey));
  }

  updateFromEnvironmentSettingsForm(environmentKey: string, nextSettings: EnvironmentFlagSettings) {
    return this.updateConfiguration(environmentKey, (cfg) => cfg.merge(nextSettings));
  }

  updateFromRuleExclusionForm(environmentKey: string, nextSettings: EnvironmentFlagSettings) {
    return this.updateConfiguration(environmentKey, (cfg) => cfg.merge(nextSettings));
  }

  toggle(environmentKey: string) {
    return this.updateConfiguration(environmentKey, (c) => c.update('on', (on) => !on));
  }

  isOn(environmentKey: string) {
    const config = this.getIn(['environments', environmentKey]);
    invariant(config, 'Missing flag configuration for environment');
    return isFlagOnForEnvironment(config);
  }

  getActiveVariationsFromSummary(environmentKey: string): Map<number, Variation> | undefined {
    const config = this.getIn(['environments', environmentKey]);
    return config
      ? Map(getFlagActiveVariationsFromSummary(config.toJS(), this.variations.toJS())).map((v) => createVariation(v))
      : undefined;
  }

  getActiveVariationsFromConfig(environmentKey: string): Map<number, Variation> | undefined {
    const config = this.getIn(['environments', environmentKey]);
    return config
      ? Map(
          getFlagActiveVariationsFromConfig(
            this.getIn(['environments', environmentKey]).toJS(),
            this.variations.toJS(),
          ),
        ).map((v) => createVariation(v))
      : undefined;
  }

  getActiveVariationsContainingSegment(environmentKey: string, segmentKey: string): Map<number, Variation> | undefined {
    return this.readConfiguration(environmentKey, (config) => {
      if (!config) {
        return Map();
      }

      if (!this.isOn(environmentKey)) {
        if (config.offVariation === undefined) {
          return Map();
        }
        const offVariation = this.variations.get(config.offVariation);
        if (offVariation === undefined) {
          return Map();
        }

        const variationIndex = this.getVariationIndexFromId(offVariation._id);
        return Map([[variationIndex, offVariation]]);
      }
      // This logic also lives on the backend in internal/reps2/flag_rep2.go#MakeConfigurationRep.
      // If you add or remove variation types here, you may also need to update on the backend.
      const activeVariationIndexes = this.getVariationsContainingSegment(config, segmentKey);

      // then turn it into a map with their full-fledged variation objects
      let variations = Map<number, Variation>();
      activeVariationIndexes.forEach((i) => {
        if (i !== undefined) {
          const variation = this.variations.get(i);
          if (variation) {
            variations = variations.set(i, variation);
          }
        }
      });

      return variations;
    });
  }

  getVariationsContainingSegment(config: FlagConfiguration, segmentKey: string): List<number> {
    let ruleVariationIndexes = List<number>();
    config.rules?.forEach((r) => {
      if (r.hasSegment(segmentKey)) {
        if (r.rollout?.variations?.size) {
          // filter out rules where weight is 0 to match backend logic
          const weightedVariations = r.rollout.variations.filter((wv) => wv.weight && wv.weight !== 0);
          ruleVariationIndexes = ruleVariationIndexes.concat(weightedVariations.map((wv) => wv.variation));
        }
        if (r.variation !== undefined) {
          ruleVariationIndexes = ruleVariationIndexes.push(r.variation);
        }
      }
    });
    return ruleVariationIndexes;
  }

  getFallthroughRolloutVariations(config: FlagConfiguration): List<number> {
    let fallthroughRolloutIndexes = List<number>();
    config.fallthrough?.rollout?.variations.forEach((wv) => {
      if (wv.variation !== undefined && wv.weight > 0) {
        fallthroughRolloutIndexes = fallthroughRolloutIndexes.push(wv.variation);
      }
    });
    return fallthroughRolloutIndexes;
  }

  clearOffVariation(environmentKey: string) {
    return this.updateConfiguration(environmentKey, (cfg) => cfg.set('offVariation', undefined));
  }

  setOffVariation(environmentKey: string, variation: Variation) {
    const index = this.variations.findIndex((v) => v._key === variation._key);
    return this.updateConfiguration(environmentKey, (cfg) => cfg.set('offVariation', index));
  }

  getOffVariation(environmentKey: string) {
    return this.readConfiguration(environmentKey, (c) =>
      c.offVariation !== undefined ? this.variations.get(c.offVariation) : undefined,
    );
  }

  getDefaultVariation(status?: FlagStatus) {
    if (!status || status.default === null) {
      return;
    }

    if (this.getVariationType() === variationTypes.JSON && typeof status.default === 'object') {
      // if it's a json flag the immutable maps will return false with ===
      return this.variations.find((v) => is(v.value, status.default));
    }
    return this.variations.find((v) => v.value === status.default);
  }

  addPrerequisite(environmentKey: string) {
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('prerequisites', (ps) => ps.push(createPrerequisite())),
    );
  }

  setPrerequisites(environmentKey: string, prerequisites: List<Prerequisite>) {
    return this.updateConfiguration(environmentKey, (cfg) => cfg.set('prerequisites', prerequisites));
  }

  deletePrerequisite(environmentKey: string, prerequisite: Prerequisite) {
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('prerequisites', (ps) => {
        const index = ps.findIndex((p) => p._key === prerequisite._key);
        if (index === -1) {
          return ps;
        }
        return ps.delete(index);
      }),
    );
  }

  editPrerequisite(environmentKey: string, prerequisite: Prerequisite) {
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('prerequisites', (ps) => {
        const index = ps.findIndex((p) => p._key === prerequisite._key);
        if (index === -1) {
          return ps;
        }
        return ps.set(index, prerequisite);
      }),
    );
  }

  getTargets(environmentKey: string) {
    return this.readConfiguration(environmentKey, (cfg) => cfg.targets);
  }

  getContextTargets(environmentKey: string) {
    return this.readConfiguration(environmentKey, (cfg) => cfg.contextTargets);
  }

  hasTargets(environmentKey: string) {
    const userTargets = this.getTargets(environmentKey);
    const contextTargets = this.getContextTargets(environmentKey);
    return (userTargets && !userTargets.isEmpty()) || (contextTargets && !contextTargets.isEmpty());
  }

  getTarget(environmentKey: string, variationIndex: number) {
    return this.readConfiguration(environmentKey, (cfg) => cfg.getTarget(variationIndex));
  }

  getContextTarget(environmentKey: string, variationIndex: number, contextKind: string) {
    return this.readConfiguration(environmentKey, (cfg) => cfg.getContextTarget(variationIndex, contextKind));
  }

  setTarget(environmentKey: string, variation: Variation, keys: List<string>) {
    return this.updateConfiguration(environmentKey, (cfg) => {
      const variationIndex = this.variations.findIndex((v) => v._key === variation._key);
      const index = cfg.targets.findIndex((t) => t.variation === variationIndex);

      if (keys.isEmpty()) {
        if (index >= 0) {
          return cfg.deleteIn(['targets', index]);
        } else {
          return cfg;
        }
      }

      if (index >= 0) {
        return cfg.updateIn(['targets', index], (t) => t.set('values', keys));
      } else {
        const target = createTarget({
          variation: variationIndex,
          values: keys,
          contextKind: 'user',
        });
        return cfg.update('targets', (ts) => ts.push(target));
      }
    });
  }

  setTargets(environmentKey: string, targets: List<Target>) {
    return this.updateConfiguration(environmentKey, (cfg) => cfg.set('targets', targets));
  }

  setContextTarget(environmentKey: string, variation: Variation, keys: List<string>, contextKind: string) {
    return this.updateConfiguration(environmentKey, (cfg) => {
      const variationIndex = this.variations.findIndex((v) => v._key === variation._key);
      const index = cfg.contextTargets.findIndex(
        (t) => t.variation === variationIndex && t.contextKind === contextKind,
      );
      if (keys.isEmpty()) {
        if (index >= 0) {
          return cfg.deleteIn(['contextTargets', index]);
        } else {
          return cfg;
        }
      }
      if (index >= 0) {
        return cfg.updateIn(['contextTargets', index], (t) => t.set('values', keys));
      } else {
        const target = createTarget({
          variation: variationIndex,
          values: keys,
          contextKind,
        });
        return cfg.update('contextTargets', (ts) => ts.push(target));
      }
    });
  }

  setFallthroughVariation(environmentKey: string, variation: Variation) {
    const index = this.variations.findIndex((v) => v._key === variation._key);

    if (index === -1) {
      return this;
    }

    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('fallthrough', (ft) => ft.delete('rollout').set('variation', index)),
    );
  }

  //allows multiple rollouts to be set at once
  setFallthroughExperimentRollout(
    environmentKey: string,
    rolloutData: List<WeightedVariation>,
    experimentData: ExperimentRollout,
  ) {
    const variations = this.variations;
    //check that all variations exist
    let variationExists = true;
    rolloutData.forEach((data) => {
      const variation = variations.get(data.variation);
      if (!variation && variationExists) {
        variationExists = false;
      }
    });

    if (!variationExists) {
      return this;
    }

    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('fallthrough', (ft) =>
        ft
          .delete('variation')
          .update('rollout', (r) => {
            const experimentRolloutInfo = { experimentAllocation: experimentData };
            return r && r.experimentAllocation
              ? r.set('experimentAllocation', experimentData)
              : createRollout(experimentRolloutInfo);
          })
          .update('rollout', (r) => {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            // We created a fresh rollout above so we can safely assert that it exists here.
            let newR = r!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
            rolloutData.forEach((data) => {
              newR = handleRolloutLogic(newR, data.variation, data.weight, variations);
            });
            return newR;
          }),
      ),
    );
  }

  setFallthroughRollout(environmentKey: string, variation: Variation, weight: number) {
    const variationIndex = this.variations.findIndex((v) => v._key === variation._key);
    if (variationIndex === -1) {
      return this;
    }

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('fallthrough', (ft) =>
        ft
          .delete('variation')
          .update('rollout', (r) => (r && !r.experimentAllocation ? r : createRollout()))
          // We initialized the rollout above so we can safely assert it exists here
          .update('rollout', (r) => r!.setWeightForVariation(this, variation, weight)),
      ),
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  // Get the weight of the true variation for the fallthrough rule of a boolean flag
  getReferenceWeightForFallthroughRule(environmentKey: string) {
    return this.readConfiguration(environmentKey, (cfg) => {
      if (!cfg.fallthrough.rollout) {
        return 0;
      }

      const truev = cfg.fallthrough.rollout.variations.find(
        (wv) => this.variations.getIn([wv.variation, 'value']) === true,
      );

      return truev ? truev.weight : 0;
    });
  }

  getReferenceWeightForFallthroughRuleByIndex(environmentKey: string, index: number) {
    return this.readConfiguration(environmentKey, (cfg) => {
      if (!cfg.fallthrough.rollout) {
        return 0;
      }

      const variation = cfg.fallthrough.rollout.variations.get(index);
      return variation ? variation.weight : 0;
    });
  }

  getReferenceWeightForRule(environmentKey: string, rule: Rule) {
    return this.readConfiguration(environmentKey, (cfg) => {
      const index = cfg.rules.findIndex((r) => r._key === rule._key);
      if (index === -1) {
        return 0;
      }

      if (!rule.rollout) {
        return 0;
      }

      const truev = rule.rollout.variations.find((wv) => {
        const v = this.variations.get(wv.variation);
        return v?.value === true;
      });

      return truev ? truev.weight : 0;
    });
  }

  getTrueVariation() {
    return this.variations.find((v) => v.value === true);
  }

  createWeightedRollout(refVariation: Variation, weight: number, bucketBy?: string, contextKind?: string) {
    const refIndex = this.variations.indexOf(refVariation);
    const otherIndex = this.variations.findIndex((v) => v !== refVariation);
    const refWeightedVariation = createWeightedVariation({
      variation: refIndex,
      weight,
    });
    const otherWeightedVariation = createWeightedVariation({
      variation: otherIndex,
      weight: 100000 - weight,
    });
    return createRollout({
      // The audit log backend expects the true variation to be first, so make sure we're not reordering the variations
      variations:
        refIndex < otherIndex
          ? List.of(refWeightedVariation, otherWeightedVariation)
          : List.of(otherWeightedVariation, refWeightedVariation),
      bucketBy,
      contextKind,
    });
  }

  setFallthroughBucket(environmentKey: string, bucketBy?: string, contextKind?: string) {
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('fallthrough', (ft) =>
        ft
          .delete('variation')
          .update('rollout', (r) =>
            r
              ? r.set('bucketBy', bucketBy).set('contextKind', contextKind || '')
              : createRollout({ bucketBy, contextKind }),
          ),
      ),
    );
  }

  hasTargetsOrRules(environmentKey: string) {
    if (!this.getConfiguration(environmentKey)) {
      return false;
    }
    return flagConfigContainsTargetsOrRules(this.getConfiguration(environmentKey)?.toJS() as FeatureFlagConfig);
  }

  getRules(environmentKey: string) {
    return this.readConfiguration(environmentKey, (cfg) => cfg.rules);
  }

  setRules(environmentKey: string, rules: List<Rule>) {
    return this.updateConfiguration(environmentKey, (cfg) => cfg.set('rules', rules));
  }

  // get the prerequisites from the flag's config
  // the config updates as the flag updates, so this is the most up-to-date list of prerequisites
  getPrerequisites(environmentKey: string) {
    return this.readConfiguration(environmentKey, (cfg) => cfg.prerequisites);
  }

  // get count of prerequisites from the flag's summary
  // _summary does NOT update as the flag updates, so consider this a snapshot of the flag's current prerequisites
  getPrerequisiteCount(environmentKey: string) {
    const summary = this.readConfiguration(environmentKey, (cfg) => cfg._summary);
    return summary?.prerequisites ?? 0;
  }

  hasPrerequisites(environmentKey: string, useFlagConfiguration?: boolean) {
    if (useFlagConfiguration) {
      const prerequisites = this.getPrerequisites(environmentKey);
      return prerequisites && prerequisites.size > 0;
    }
    return this.getPrerequisiteCount(environmentKey) > 0;
  }

  getRuleById(environmentKey: string, ruleId: string) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return this.getRules(environmentKey)!
      .filter((r) => idOrKey(r) === ruleId)
      .first<undefined>(); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  getRuleIndex(environmentKey: string, ruleId: string) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return this.getRules(environmentKey)!.findIndex(
      (r) => idOrKey(r) === ruleId,
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  addRule(environmentKey: string, defaultClause?: Clause | Clause[]) {
    let defaultClauses: List<Clause> | undefined;
    if (defaultClause) {
      defaultClauses = Array.isArray(defaultClause) ? List(defaultClause) : List.of(defaultClause);
    }

    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('rules', (rs) => rs.push(createRule({ clauses: defaultClauses }))),
    );
  }

  deleteRule(environmentKey: string, rule: Rule) {
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('rules', (rs) => {
        const index = rs.findIndex((r) => r._key === rule._key);
        if (index === -1) {
          return rs;
        }

        return rs.delete(index);
      }),
    );
  }

  duplicateRule(environmentKey: string, rule: Rule) {
    const duplicatedRule = rule
      .set('_key', undefined)
      .set('_id', undefined)
      // Ensures we don't have different rules with the same clause._id or ._key
      .update('clauses', (clauses) => clauses.map((c) => c.set('_key', '').set('_id', '')));

    const newRuleIndex = this.getRuleIndex(environmentKey, idOrKey(rule)) + 1;
    const newRule = createRule(duplicatedRule);
    return {
      updatedFlag: this.updateConfiguration(environmentKey, (cfg) =>
        cfg.update('rules', (rs) => rs.insert(newRuleIndex, newRule)),
      ) as Flag,
      newRule,
      newRuleIndex,
    };
  }

  setRuleIndex(environmentKey: string, ruleKey: string, index: number) {
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('rules', (rules) => {
        const previousIndex = rules.findIndex((r) => r._key === ruleKey);
        if (previousIndex === -1 || index === -1) {
          return rules;
        }

        const rule = rules.get(previousIndex);

        if (!rule) {
          return rules;
        }

        return rules.delete(previousIndex).insert(index, rule);
      }),
    );
  }

  setRuleVariation(environmentKey: string, rule: Rule, variation: Variation) {
    const variationIndex = this.variations.findIndex((v) => v._key === variation._key);
    if (variationIndex === -1) {
      return this;
    }

    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('rules', (rules) => {
        const index = rules.findIndex((r) => r._key === rule._key);
        if (index === -1) {
          return rules;
        }

        return rules.update(index, (r) => r.delete('rollout').set('variation', variationIndex));
      }),
    );
  }

  setRuleRollout(environmentKey: string, rule: Rule, variation: Variation, weight: number) {
    const variationIndex = this.variations.findIndex((v) => v._key === variation._key);
    if (variationIndex === -1) {
      return this;
    }

    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('rules', (rules) => {
        const index = rules.findIndex((r) => r._key === rule._key);
        if (index === -1) {
          return rules;
        }
        return rules.update(index, (updatedRule) => updatedRule.setRollout(this, variation, weight));
      }),
    );
  }

  setRuleExperimentRollout(
    environmentKey: string,
    rule: Rule,
    rolloutData: List<WeightedVariation>,
    experimentData: ExperimentRollout,
  ) {
    const variations = this.variations;
    //check that all variations exist
    let variationExists = true;
    rolloutData.forEach((data) => {
      const variation = variations.get(data.variation);
      if (!variation && variationExists) {
        variationExists = false;
      }
    });

    if (!variationExists) {
      return this;
    }

    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('rules', (rules) => {
        const index = rules.findIndex((r) => r._key === rule._key);
        if (index === -1) {
          return rules;
        }

        return rules.update(index, (updatedRule) =>
          updatedRule
            .delete('variation')
            .update('rollout', (r) => {
              const experimentRolloutInfo = { experimentAllocation: experimentData };
              return r && r.experimentAllocation
                ? r.set('experimentAllocation', experimentData)
                : createRollout(experimentRolloutInfo);
            })
            .update('rollout', (r) => {
              /* eslint-disable @typescript-eslint/no-non-null-assertion */
              // We created the rollout above so we can safely assert it exists here
              let newR = r!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
              rolloutData.forEach((data) => {
                newR = handleRolloutLogic(newR, data.variation, data.weight, variations);
              });
              return newR;
            }),
        );
      }),
    );
  }

  editRuleDescription(environmentKey: string, rule: Rule, description: string) {
    return this.updateConfiguration(environmentKey, (cfg) => {
      const index = cfg.rules.findIndex((r) => r._key === rule._key);
      if (index === -1) {
        return cfg;
      }
      return cfg.setIn(['rules', index, 'description'], description);
    });
  }

  editRuleTrackEvents(environmentKey: string, rule: Rule, value: boolean) {
    return this.updateConfiguration(environmentKey, (cfg) => {
      const index = cfg.rules.findIndex((r) => r._key === rule._key);
      if (index === -1) {
        return cfg;
      }
      return cfg.setIn(['rules', index, 'trackEvents'], value);
    });
  }

  setRuleBucket(environmentKey: string, rule: Rule, bucketBy?: string, contextKind?: string) {
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('rules', (rules) => {
        const index = rules.findIndex((r) => r._key === rule._key);
        if (index === -1) {
          return rules;
        }

        return rules.update(index, (r) =>
          r
            .delete('variation')
            .update('rollout', (ro) =>
              ro
                ? ro.set('bucketBy', bucketBy).set('contextKind', contextKind || '')
                : createRollout({ bucketBy, contextKind }),
            ),
        );
      }),
    );
  }

  addRuleClause(environmentKey: string, rule: Rule, clause?: Clause) {
    return this.updateConfiguration(environmentKey, (cfg) => {
      const index = cfg.rules.findIndex((r) => r._key === rule._key);
      if (index === -1) {
        return cfg;
      }

      const ruleClauses = cfg.rules.get(index)?.clauses;

      let contextKind: string = USER_CONTEXT_KIND;

      if (!!ruleClauses && !ruleClauses?.isEmpty()) {
        contextKind = ruleClauses.last<Clause>().contextKind;
      }

      return cfg.updateIn(['rules', index], (r: Rule) =>
        r.update('clauses', (cs) => cs.push(clause ? clause : createClause({ contextKind }))),
      );
    });
  }

  deleteRuleClause(environmentKey: string, rule: Rule, clause: Clause) {
    return this.updateConfiguration(environmentKey, (cfg) => {
      const index = cfg.rules.findIndex((r) => r._key === rule._key);
      if (index === -1) {
        return cfg;
      }

      return cfg.updateIn(['rules', index], (r: Rule) => {
        const clauseIndex = r.clauses.findIndex((c) => c._key === clause._key);
        if (clauseIndex === -1) {
          return r;
        }

        return r.update('clauses', (cs) => cs.delete(clauseIndex));
      });
    });
  }

  editRuleClause(environmentKey: string, rule: Rule, clause: Clause) {
    return this.updateConfiguration(environmentKey, (cfg) => {
      const index = cfg.rules.findIndex((r) => r._key === rule._key);
      if (index === -1) {
        return cfg;
      }

      const existingRule = cfg.rules.get(index);

      if (!existingRule) {
        return cfg;
      }

      const clauseIndex = existingRule.clauses.findIndex((c) => c._key === clause._key);
      if (clauseIndex === -1) {
        return cfg;
      }

      return cfg.setIn(['rules', index, 'clauses', clauseIndex], clause);
    });
  }

  findVariationByValue(value: VariationValue) {
    if (isJSONValue(value)) {
      // Handling JSON variation
      return this.variations.find((v) => isEqual(v.value.toJS(), toJS(value)));
    } else {
      // Handling Boolean, String, and Number variations
      const asString = stringifyValue(value);
      return this.variations.find((v) => v.getValueString() === asString);
    }
  }

  getDebugUntil(environmentKey: string) {
    return this.readConfiguration(environmentKey, (cfg) => cfg._debugEventsUntilDate);
  }

  getIsDebugging(environmentKey: string) {
    // be extra conservative by 30s to avoid false positives
    return (this.getDebugUntil(environmentKey) ?? 0) - Date.now() > 30 * 1000;
  }

  getDebugMinsRemaining(environmentKey: string) {
    return Math.round(((this.getDebugUntil(environmentKey) ?? 0) - Date.now()) / (60 * 1000));
  }

  toGlobalRep() {
    return fromJS(this.toJSON()).withMutations(
      (
        map: ImmutableMap<FlagType>,
        // map: ImmutableMap<
        //   Omit<FlagType, 'customProperties'> & { customProperties: OrderedMap<string, CustomProperty> }
        // >,
      ) => {
        if (map.get('maintainerId') === null) {
          map.delete('maintainerId');
        }
        if (map.get('maintainerTeamKey') === null) {
          map.delete('maintainerTeamKey');
        }
        map.update('environments', (es: Map<string, FlagConfiguration>) => es.map((e) => e.toRep()));
        map.update('variations', (vs: List<Variation>) => vs.map((v) => v.toRep()));
        // CHEAT: The UI uses a List<CustomPropertyForm> but the API expects a map[string]CustomProperty.
        //        We use a cast here because we assume toGlobalRep is only called before sending data to the API.
        map.update(
          'customProperties',
          (m) => customPropertiesFormToRep(List(m)) as unknown as List<CustomPropertyForm>,
        );
        map.update('experiments', (experimentInfo) => experimentInfo.toRep());
      },
    );
  }

  getVariationIndexFromId(variationId: string) {
    return getFlagVariationIndexFromId(this.variations.toArray(), variationId);
  }

  getVariationIndexFromValue(variationValue: VariationValue) {
    return this.variations.findIndex((v) => v.value === variationValue);
  }

  canBypassRequiredApprovals(envKey?: string) {
    if (isBypassApprovalsEnabled() && envKey) {
      const flagConfig = this.environments.get(envKey);
      for (const access of flagConfig?._access?.get('allowed') ?? []) {
        if (access.get('action') === 'bypassRequiredApproval') {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Determines whether a flag will require approval for changes
   * based off of a given environment's settings
   */
  isApprovalRequired(approvalSettings?: ApprovalSettings): boolean {
    if (!approvalSettings) {
      return false;
    }
    if (approvalSettings.required) {
      return true;
    }
    // This mirrors the AreApprovalsRequiredByTag method on the backend
    // https://github.com/launchdarkly/gonfalon/blob/master/internal/shared/approvals/check_tags.go#L3
    if (approvalSettings.requiredApprovalTags.size > 0) {
      const requiredApprovalTagsMap = approvalSettings.requiredApprovalTags.reduce(
        (accum, tag) => ({ ...accum, [tag]: true }),
        {} as { [key: string]: boolean },
      );
      for (const tag of this.tags) {
        if (requiredApprovalTagsMap.hasOwnProperty(tag)) {
          return true;
        }
      }
    }
    return false;
  }

  hasProjectDefaults() {
    return (
      this.getIn(['defaults', 'onVariation']) === 1 ||
      this.getIn(['defaults', 'offVariation']) === 0 ||
      this.getIn(['clientSideAvailability', 'usingEnvironmentId']) ||
      this.getIn(['clientSideAvailability', 'usingMobileKey']) ||
      this.variations.get(0)?.name !== '' ||
      this.variations.get(0)?.description !== '' ||
      this.variations.get(1)?.name !== '' ||
      this.variations.get(1)?.description !== '' ||
      !this.temporary ||
      !this.tags.isEmpty()
    );
  }

  /**
   * Returns the flag updated to match the configuration settings
   * of the provided original flag
   */
  resetConfigurationSetting(originalFlag: Flag, setting: FlagConfigSetting, environmentKey: string) {
    const originalConfiguration = originalFlag.getConfiguration(environmentKey);
    if (!originalConfiguration) {
      return this;
    }
    const originalSettingValue = originalConfiguration.get(setting);
    return this.updateConfiguration(environmentKey, (cfg) => cfg.set(setting, originalSettingValue));
  }

  updateEvaluation(environmentKey: string, evaluation?: FlagContextsEvaluation) {
    return this.updateConfiguration(environmentKey, (cfg) => cfg.set('evaluation', evaluation));
  }

  preserveFlagContextsEvaluation(flagWithEvaluationInfo: Flag) {
    let updatedFlag: Flag | undefined;
    flagWithEvaluationInfo.environments.forEach((config, environmentKey) => {
      if (this.environments.has(environmentKey)) {
        updatedFlag = this.updateConfiguration(environmentKey, (cfg) => cfg.set('evaluation', config.evaluation));
      }
    });
    return updatedFlag || this;
  }

  countIndividualTargetsForAllVariations(envKey: string) {
    const config = this.getConfiguration(envKey);
    return this.variations
      .map((__, idx) => (config ? config.getCountOfTargetsForVariation(idx) : 0))
      .reduce((a, b) => a + b, 0);
  }

  canDeprecateFlag() {
    return canDeprecateFlag(this.toJS());
  }

  isFlagDeprecated() {
    return isFlagDeprecated(this.toJS());
  }

  getConfigValidation(envKey: string) {
    const config = this.getConfiguration(envKey);
    return validateFlagConfiguration(config, this.variations, {
      userTargetAlertThreshold: userTargetAlertThreshold(),
      userTargetWarningThreshold: userTargetWarningThreshold(),
    });
  }

  setRuleMeasuredRolloutConfig(environmentKey: string, rule: Rule, config?: MeasuredRolloutConfig) {
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('rules', (rules) => {
        const index = rules.findIndex((r) => r._key === rule._key);
        if (index === -1) {
          return rules;
        }

        return rules.update(index, (r) => r.set('measuredRolloutConfig', config));
      }),
    );
  }

  setFallthroughMeasuredRolloutConfig(environmentKey: string, config?: MeasuredRolloutConfig) {
    return this.updateConfiguration(environmentKey, (cfg) =>
      cfg.update('fallthrough', (fallthrough) => fallthrough.set('measuredRolloutConfig', config)),
    );
  }
}

type VariationSummaryProps = {
  rules: number;
  nullRules: number;
  contextTargets: number;
  targets: number;
  isFallthrough?: boolean;
  isOff?: boolean;
  rollout?: number;
  bucketBy?: string;
};

class VariationSummary extends Record<VariationSummaryProps>({
  isFallthrough: false,
  isOff: false,
  nullRules: 0,
  rollout: 0,
  rules: 0,
  targets: 0,
  contextTargets: 0,
}) {}

type TargetingSummaryProps = {
  prerequisites: number;
  variations: Map<number, VariationSummary>;
};

class TargetingSummary extends Record<TargetingSummaryProps>({
  prerequisites: 0,
  variations: Map(),
}) {}

export class CustomPropertyConnectForm extends Record<{
  flag?: Flag;
  customPropertyKey: string;
  customPropertyValue: string;
}>({
  flag: undefined,
  customPropertyKey: '',
  customPropertyValue: '',
}) {
  validate() {
    const isValidLength = isLength(0, 64);
    const predicates = [isNotEmpty('flag'), isValidLength('customPropertyKey'), isValidLength('customPropertyValue')];

    return validateRecord(this, ...predicates);
  }
}

type TargetProps = {
  variation: number;
  values: List<string>;
  contextKind: string;
};

export class Target extends Record<TargetProps>({
  variation: 0,
  values: List(),
  contextKind: USER_CONTEXT_KIND,
}) {
  isEmpty() {
    return !this.values || this.values.isEmpty();
  }
  getContextKind() {
    return this.contextKind || USER_CONTEXT_KIND;
  }
}

export const getTargetByContextAndVariation = (targets: List<Target>, contextKind: string, variationIndex: number) => {
  const target = targets.find((t) => t.variation === variationIndex && t.contextKind === contextKind);
  return target ? target.values : List();
};

export const getTargetListForVariation = (targets: List<Target> | List<SegmentTarget>, variationIndex: number) => {
  let contextsWithTargets = targets;
  if (variationIndex !== -1) {
    contextsWithTargets = (targets as List<Target>).filter(
      (target) => target.variation === variationIndex && !target.values.isEmpty(),
    );
  } else {
    contextsWithTargets = (targets as List<SegmentTarget>).filter((target) => !target.values.isEmpty());
  }
  return contextsWithTargets;
};

export const getKeysForContextKindsWithTargets = (
  contextKinds: ContextKind[],
  targets: List<Target> | List<SegmentTarget>,
  variationIndex: number,
) => {
  const contextsWithTargets = getTargetListForVariation(targets, variationIndex).map((target) => target.contextKind);
  return contextKinds.filter((ck) => contextsWithTargets.includes(ck.key));
};

// type WeightedVariation struct {
// 	Variation int  `json:"variation" bson:"variation"`
// 	Weight    int  `json:"weight" bson:"weight"` // Ranges from 0 to 100000
//  Untracked bool `json:"_untracked"
// }
type WeightedVariationProps = { variation: number; weight: number; _untracked?: boolean };

export class WeightedVariation extends Record<WeightedVariationProps>({
  variation: 0,
  weight: 0,
  _untracked: undefined,
}) {}

export type ExperimentRollout = {
  canReshuffle: boolean;
  defaultVariation: number;
  type?: string;
};

type RolloutProps = {
  variations: List<WeightedVariation>;
  bucketBy?: string;
  experimentAllocation?: ExperimentRollout;
  contextKind: string;
};
export class Rollout extends Record<RolloutProps>({
  bucketBy: undefined,
  experimentAllocation: undefined,
  variations: List(),
  contextKind: '',
}) {
  getWeightForIndex(variationIndex: number) {
    const match = this.variations.find((wv) => wv.variation === variationIndex && !wv._untracked);
    return match ? match.weight : null;
  }

  getTotalRolloutPercentage() {
    return this.variations.reduce((sum, wv) => (!wv._untracked ? sum + wv.weight : sum + 0), 0);
  }

  setWeightForVariation(flag: Flag, variation: Variation, weight: number) {
    const variations = flag.getVariations();
    const variationIndex = variations.findIndex((v) => v._key === variation._key);
    if (variationIndex === -1) {
      return this;
    }

    if (flag.getKind() === flagKinds.BOOLEAN || variations.size === 2) {
      return flag.createWeightedRollout(variation, weight, this.bucketBy, this.contextKind);
    }

    return handleRolloutLogic(this, variationIndex, weight, variations);
  }

  getContextKind() {
    return this.contextKind || USER_CONTEXT_KIND;
  }

  setContextKind(contextKind: string = '') {
    return this.set('contextKind', contextKind);
  }

  hasNonUserContext() {
    return this.getContextKind() !== USER_CONTEXT_KIND;
  }

  toRep(): Rollout {
    return fromJS(this.toJSON()).withMutations((map: Rollout) => {
      map.get('bucketBy') === undefined && map.remove('bucketBy');
    });
  }
}

type VariationOrRollout = {
  variation?: number;
  rollout?: Rollout;
};

export interface MeasuredRolloutConfigProps {
  randomizationUnit?: string;
  /* OnRegression is Deprecated in V2 */
  onRegression?: {
    notify: boolean;
    rollback: boolean;
  };
  onProgression: {
    notify: boolean;
    rollForward: boolean;
  };
  monitoringWindowMilliseconds: number;
  rolloutWeight: number;
  /* Stages and metrics are only available in V2 */
  stages?: List<{
    rolloutWeight: number;
    monitoringWindowMilliseconds: number;
  }>;
  metrics?: List<{
    metricKey: string;
    regressionThreshold: number;
    onRegression: {
      notify: boolean;
      rollback: boolean;
    };
  }>;
}

export class MeasuredRolloutConfig extends Record<MeasuredRolloutConfigProps>({
  randomizationUnit: undefined,
  onRegression: { notify: true, rollback: false },
  onProgression: { notify: true, rollForward: true },
  monitoringWindowMilliseconds: 0,
  rolloutWeight: 0,
  stages: undefined,
  metrics: undefined,
}) {
  toRep() {
    const rep = this.toJSON();
    return {
      ...rep,
      randomizationUnit: nullthrows(rep.randomizationUnit, 'randomizationUnit is required'),
    };
  }
}

type RuleProps = {
  _id?: string;
  _key?: string;
  ref?: string;
  clauses: List<Clause>;
  trackEvents: boolean;
  isEditing: boolean;
  description?: string;
  measuredRolloutConfig?: MeasuredRolloutConfig;
} & VariationOrRollout;

export class Rule extends Record<RuleProps>({
  _id: undefined,
  _key: undefined,
  ref: '',
  clauses: List.of(createClause()),
  trackEvents: false,
  isEditing: false,
  description: '',
  variation: undefined,
  rollout: undefined,
  measuredRolloutConfig: undefined,
}) {
  toRep() {
    return fromJS(this.toJSON()).withMutations((map: Rule) => {
      map.remove('_key');
      map.get('rollout') === undefined ? map.remove('rollout') : map.update('rollout', (r?: Rollout) => r?.toRep());
      map.get('variation') === undefined && map.remove('variation');
      map.update('clauses', (cs: List<Clause>) => cs.map((c) => c.toRep()));
      map.get('description');
    });
  }

  setRollout(flag: Flag, variation: Variation, weight: number) {
    const variations = flag.getVariations();
    const variationIndex = variations.findIndex((v) => v._key === variation._key);
    if (variationIndex === -1) {
      return this;
    }

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return (
      this.delete('variation')
        .update('rollout', (r) => (r && !r.experimentAllocation ? r : createRollout()))
        // We can safely assert the rollout exists here because we created it above
        .update('rollout', (r) => r!.setWeightForVariation(flag, variation, weight))
    ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  hasNonUserContextClause() {
    return this.clauses.some((c) => !!c.contextKind && c.contextKind !== USER_CONTEXT_KIND);
  }
  hasNonUserRollout() {
    return !!this.rollout && this.rollout?.hasNonUserContext();
  }

  hasSegment(segmentKey: string) {
    return this.clauses.some((c) => c.hasSegment(segmentKey));
  }

  isSegmentOnlyRule() {
    return this.clauses.every((c) => c.hasSegmentOp());
  }
  setIsEditing(val: boolean) {
    return this.set('isEditing', val);
  }

  isMobileRule() {
    return isMobileRule(this.toJS());
  }
  getRuleKind() {
    return getRuleKind(this.toJS());
  }
}

type FallthroughProps = {
  measuredRolloutConfig?: MeasuredRolloutConfig;
} & VariationOrRollout;

export class Fallthrough extends Record<FallthroughProps>({
  rollout: undefined,
  variation: undefined,
  measuredRolloutConfig: undefined,
}) {
  toRep() {
    return fromJS(this.toJSON()).withMutations((map: Fallthrough) => {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      map.get('rollout') === null || map.get('rollout') === undefined
        ? map.remove('rollout')
        : map.update('rollout', (r) => r!.toRep()); /* eslint-enable @typescript-eslint/no-non-null-assertion */
      (map.get('variation') === null || map.get('variation') === undefined) && map.remove('variation');
    });
  }
  hasNonUserRollout() {
    return !!this.rollout && this.rollout?.hasNonUserContext();
  }

  hasVariationOrRollout() {
    return this.variation !== undefined || (this.rollout && !this.rollout?.experimentAllocation);
  }
}

export class Prerequisite extends Record<{
  _key?: string;
  // This will always exist for prereqs coming from the server, but will not for prerequisites that were
  // just added via the UI -- should we rely on the empty-string default or undefined?
  key: string;
  variation: number;
  variationId: string; // What is this?
}>({
  _key: undefined,
  key: '',
  variation: 0,
  variationId: '',
}) {
  toRep() {
    return fromJS(this.toJSON()).withMutations((map: Prerequisite) => {
      map.remove('_key');
    });
  }
  getPrerequisiteState(originalPrereqList: List<Prerequisite>) {
    const originalPrereq = originalPrereqList.find((p) => p._key === this._key);
    if (!originalPrereq) {
      return 'new';
    }
    if (!is(this, originalPrereq)) {
      return 'modified';
    }
    return 'unmodified';
  }
}

export type EnvironmentFlagSettingsProps = {
  trackEvents: boolean;
  trackEventsFallthrough: boolean;
  salt: string;
  migrationSettings?: FlagConfigMigrationSettings;
  rules: List<Rule>;
};

export class EnvironmentFlagSettings extends Record<EnvironmentFlagSettingsProps>({
  trackEvents: false,
  trackEventsFallthrough: false,
  rules: List(),
  salt: '',
  migrationSettings: undefined,
}) {
  validate() {
    const isValidLength = isLength(4);
    const predicates = [isValidLength('salt')];
    if (this.migrationSettings) {
      predicates.push(checkRadioIsInRange({ min: 0, max: 4294967295 })('migrationSettings'));
    }
    return validateRecord(this, ...predicates);
  }
}

export type RuleExclusionSettingsProps = {
  trackEvents: boolean;
  trackEventsFallthrough: boolean;
  rules: List<Rule>;
};

export class RuleExclusionSettings extends Record<RuleExclusionSettingsProps>({
  trackEvents: false,
  trackEventsFallthrough: false,
  rules: List(),
}) {
  validate() {
    return Map();
  }
}

// type DependentFlag struct {
// 	Name  string               `json:"name"`
// 	Key   string               `json:"key"`
// 	Links map[string]core.Link `json:"_links"`
// 	Site  core.Link            `json:"_site"`
// }
type DependentFlagByEnvProps = {
  key: string;
  name: string;
  archived?: boolean;
  archivedDate?: number;
  _links: ImmutableMap<{
    self: Link;
    flag: Link;
  }>;
  _site: Link;
};
class DependentFlagByEnv extends Record<DependentFlagByEnvProps>({
  key: '',
  name: '',
  _links: Map(),
  _site: Map(),
}) {}

// type DependentFlagsCollectionRep struct {
// 	Items []DependentFlag      `json:"items"`
// 	Links map[string]core.Link `json:"_links"`
// 	Site  core.Link            `json:"_site"`
// }
type DependentFlagByEnvCollectionProps = {
  _links: ImmutableMap<{
    self: Link;
    parent: Link;
  }>;
  items: List<DependentFlagByEnv>;
};
class DependentFlagByEnvCollection extends Record<DependentFlagByEnvCollectionProps>({
  items: List(),
  _links: Map(),
}) {}

// type DependentFlagWithEnvs struct {
// 	Name         string                     `json:"name"`
// 	Key          string                     `json:"key"`
// 	Environments []DependentFlagEnvironment `json:"environments"`
// }
type DependentFlagProps = {
  key: string;
  name: string;
  archived?: boolean;
  archivedDate?: number;
  environments: List<DependentFlagByEnv>;
};
class DependentFlag extends Record<DependentFlagProps>({
  key: '',
  name: '',
  environments: List(),
}) {
  // selfLink() {
  //   return this._links.getIn(['self', 'href']);
  // }
}

// type MultiEnvDependentFlagsCollectionRep struct {
// 	Items []DependentFlagWithEnvs `json:"items"`
// 	Links map[string]core.Link    `json:"_links"`
// 	Site  core.Link               `json:"_site"`
// }
type DependentFlagCollectionProps = {
  _links: ImmutableMap<{
    self: Link;
    parent: Link;
  }>;
  _site: Link;
  items: List<DependentFlag>;
};
class DependentFlagCollection extends Record<DependentFlagCollectionProps>({
  items: List(),
  _links: Map(),
  _site: Map(),
}) {}

export type FlagConfigMigrationSettings = {
  checkRatio: number;
};

type FlagConfigurationProps = {
  on: boolean;
  archived: boolean;
  salt: string;
  sel: string;
  lastModified: number;
  version: number;
  contextTargets: List<Target>;
  targets: List<Target>;
  rules: List<Rule>;
  fallthrough: Fallthrough;
  offVariation?: number;
  prerequisites: List<Prerequisite>;
  _site: Link;
  _environmentName: string;
  trackEvents: boolean;
  trackEventsFallthrough: boolean;
  _debugEventsUntilDate?: number;
  _summary?: TargetingSummary;
  _access?: AccessChecks;
  evaluation?: FlagContextsEvaluation;
  migrationSettings?: Map<string, FlagConfigMigrationSettings>;
  measuredRolloutStatus?: MeasuredRolloutStatus;
};

const flagConfigSettings = {
  on: false,
  contextTargets: List(),
  targets: List(),
  rules: List(),
  fallthrough: new Fallthrough(),
  prerequisites: List(),
  offVariation: undefined,
};

export type FlagConfigSetting = keyof typeof flagConfigSettings;

type FlagContextsEvaluationProps = {
  contextKinds: List<string>;
};
export class FlagContextsEvaluation extends Record<FlagContextsEvaluationProps>({
  contextKinds: List(),
}) {}

export function createContextsEvaluation(props?: CreateFunctionInput<FlagContextsEvaluation>) {
  return props instanceof FlagContextsEvaluation ? props : new FlagContextsEvaluation(fromJS(props));
}

export class FlagConfiguration extends Record<FlagConfigurationProps>({
  ...flagConfigSettings,
  ...{
    _site: Map(),
    archived: false,
    salt: '',
    sel: '',
    version: 0,
    trackEventsFallthrough: false,
    trackEvents: false,
    _environmentName: '',
    lastModified: 0,
    _debugEventsUntilDate: undefined,
    _summary: undefined,
    _access: undefined,
    evaluation: undefined,
    migrationSettings: undefined,
    measuredRolloutStatus: undefined,
  },
}) {
  getTarget(variationIndex: number) {
    const index = this.targets.findIndex((t) => t.variation === variationIndex);
    return index === -1 ? undefined : this.targets.get(index);
  }

  getContextTarget(variationIndex: number, contextKind: string) {
    const index = this.contextTargets.findIndex((t) => t.variation === variationIndex && t.contextKind === contextKind);
    return index === -1 ? undefined : this.contextTargets.get(index);
  }
  // Combines user and context targets
  getAllTargets() {
    let allTargets = this.contextTargets;
    const variationToUserTargetValues: { [key: number]: List<string> } = {};
    this.targets.forEach((t) => {
      // Build map of variation to user target values
      if (variationToUserTargetValues.hasOwnProperty(t.variation)) {
        variationToUserTargetValues[t.variation] = variationToUserTargetValues[t.variation].merge(t.values);
      } else {
        variationToUserTargetValues[t.variation] = t.values;
      }
      // Account for user targets not represented in contextTargets
      const isFoundInContextTargets = allTargets.find(
        (c) => c.contextKind === USER_CONTEXT_KIND && c.variation === t.variation,
      );
      if (!isFoundInContextTargets) {
        allTargets = allTargets.push(t);
      }
    });

    return allTargets.map((t) => {
      let target = t;
      if ((t.contextKind === USER_CONTEXT_KIND || '') && variationToUserTargetValues.hasOwnProperty(t.variation)) {
        target = target.set('values', variationToUserTargetValues[t.variation]);
      }
      return target;
    });
  }

  getAllTargetsForVariation(variationIdx: number) {
    const allTargets = getAllTargetsForVariation(this.toJS(), variationIdx);
    const targetRecords = allTargets.map((t) => createTarget(t));
    return List(targetRecords);
  }

  // Returns all values regardless of context kind
  getTargetValuesForVariation(variationIdx: number) {
    const targets = this.getAllTargetsForVariation(variationIdx);
    let targetValues: List<string> = List();
    targets.forEach((target) => {
      targetValues = targetValues.concat(target.values);
    });
    return targetValues;
  }

  getCountOfTargetsForVariation(variationIdx: number) {
    return getCountOfTargetsForVariation(this.toJS(), variationIdx);
  }

  getRuleById(ruleId: string) {
    return this.rules.filter((r) => idOrKey(r) === ruleId).first<undefined>();
  }

  getRuleIndex(ruleKey: string | undefined) {
    return this.rules.findIndex((r) => r._key === ruleKey);
  }

  hasTargets(variationIndex: number) {
    const targets = this.getTarget(variationIndex);
    const contextTargetsIndex = this.contextTargets.findIndex((t) => t.variation === variationIndex);
    const contextTargets = contextTargetsIndex === -1 ? undefined : this.contextTargets.get(contextTargetsIndex);

    return (targets && !targets.isEmpty()) || (contextTargets && !contextTargets.isEmpty());
  }

  countTargetingRules() {
    const numberOfTargets = this.targets.isEmpty() ? 0 : 1;
    const numberOfCustomRules = this.rules.size;
    const fallthrough = 1; //fallthrough always exists and there is only one
    return numberOfTargets + numberOfCustomRules + fallthrough;
  }

  countTargetingRulesTrackingEvents() {
    if (this.trackEvents) {
      return this.countTargetingRules();
    }

    if (!this.trackEvents) {
      let count = 0;
      if (this.trackEventsFallthrough) {
        count++;
      }
      if (this.rules.size) {
        this.rules.forEach((rule) => {
          if (rule.trackEvents) {
            count++;
          }
        });
      }
      return count;
    }

    return 0;
  }

  toRep() {
    return fromJS(this.toJSON()).withMutations((map: FlagConfiguration) => {
      map.get('offVariation') === undefined && map.remove('offVariation');
      map.update('fallthrough', (f) => f.toRep());
      map.update('rules', (rs) => rs.map((r) => r.toRep()));
      map.update('prerequisites', (pr) => pr.map((p) => p.toRep()));
    });
  }
}

export class VersionResults extends Record<{
  conversions: number;
  impressions: number;
  conversionRate: number;
  standardError: number;
  confidenceInterval: number;
}>({
  conversions: 0,
  impressions: 0,
  conversionRate: 0,
  standardError: 0,
  confidenceInterval: 0,
}) {}

export class ExperimentResults extends Record<{
  change: number;
  confidenceScore: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  z_score: number;
  control: VersionResults;
  experiment: VersionResults;
}>({
  change: 0,
  confidenceScore: 0.5,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  z_score: 0,
  control: new VersionResults(),
  experiment: new VersionResults(),
}) {
  isExperimentFavored() {
    return this.z_score >= 0;
  }

  hasEnoughData() {
    return this.experiment.impressions >= 1000 && this.control.impressions >= 1000;
  }

  doesExperimentWin() {
    return this.confidenceScore >= 0.95 && this.hasEnoughData();
  }

  doesControlWin() {
    return this.confidenceScore < 0.05 && this.hasEnoughData();
  }

  hasWinner() {
    return this.doesExperimentWin() || this.doesControlWin();
  }
}

export function createExperimentV1Results(props: CreateFunctionInput<ExperimentResults> = {}) {
  const ex = props instanceof ExperimentResults ? props : new ExperimentResults(fromJS(props));
  return ex.withMutations((map) => {
    map.update('control', (c) => new VersionResults(c));
    map.update('experiment', (e) => new VersionResults(e));
  });
}

export function createVariation(props: CreateFunctionInput<Variation> = {}) {
  const variation = props instanceof Variation ? props : new Variation(fromJS(props));
  return variation
    .update('_key', (key) => (key.length === 0 ? v4() : key))
    .update('_id', (id) => (id.length === 0 ? v4() : id));
}

export function createWeightedVariation(props: CreateFunctionInput<WeightedVariation> = {}) {
  return props instanceof WeightedVariation ? props : new WeightedVariation(fromJS(props));
}

export function createExperimentRollout(
  props: { canReshuffle?: boolean; defaultVariation?: number } = {},
): ExperimentRollout {
  return {
    canReshuffle: true,
    defaultVariation: 0,
    ...toJS(props),
  };
}

export function createDependentFlagByEnv(props: CreateFunctionInput<DependentFlagByEnvCollection> = {}) {
  return props instanceof DependentFlagByEnvCollection ? props : new DependentFlagByEnvCollection(fromJS(props));
}

export function createDependentFlag(props: CreateFunctionInput<DependentFlagCollection> = {}) {
  return props instanceof DependentFlagCollection ? props : new DependentFlagCollection(fromJS(props));
}

export function createRollout(props: CreateFunctionInput<Rollout> = {}) {
  const rollout = props instanceof Rollout ? props : new Rollout(fromJS(props));

  if (rollout.experimentAllocation) {
    const variations: { [key: string]: { weight: number; variation: number; _untracked?: boolean } } = {};
    rollout.variations.forEach((v) => {
      const wv = createWeightedVariation(v);
      const key = `${wv.get('variation')}:${wv.get('_untracked')}`;
      if (variations[key]) {
        variations[key].weight += wv.get('weight');
      } else {
        variations[key] = {
          weight: wv.get('weight'),
          variation: wv.get('variation'),
          _untracked: wv.get('_untracked'),
        };
      }
    });
    const weightedVariations = Object.values(variations).map((v) => createWeightedVariation(v));
    return rollout
      .set('variations', List.of(...weightedVariations))
      .update('experimentAllocation', (alloc) => (alloc ? createExperimentRollout(alloc) : alloc));
  }
  return rollout.update('variations', (vs) => vs.map(createWeightedVariation));
}

export function createRule(props: CreateFunctionInput<Rule> = {}) {
  const rule = props instanceof Rule ? props : new Rule(fromJS(props));
  const key = rule._key || v4();
  return rule.withMutations((map) => {
    map.set('_key', key);
    map.set('ref', key);
    map.update('clauses', (cs) => cs.map(createClause));
    map.update('rollout', (r) => (r ? createRollout(r) : r));
    map.update('measuredRolloutConfig', (m) => (m ? new MeasuredRolloutConfig(m) : m));
  });
}

export function createFallthrough(props: CreateFunctionInput<Fallthrough> = {}) {
  const fallthrough = props instanceof Fallthrough ? props : new Fallthrough(fromJS(props));
  return fallthrough.update('rollout', (r) => (r ? createRollout(r) : r));
}

export function createTarget(props: CreateFunctionInput<Target> = {}) {
  return props instanceof Target ? props : new Target(fromJS(props));
}

export function createPrerequisite(props: CreateFunctionInput<Prerequisite> = {}) {
  const p = props instanceof Prerequisite ? props : new Prerequisite(fromJS(props));
  return p.update('_key', (key) => (key ? key : v4()));
}

export function createFlagConfiguration(props: CreateFunctionInput<FlagConfiguration> = {}) {
  const cfg = props instanceof FlagConfiguration ? props : new FlagConfiguration(fromJS(props));
  return cfg.withMutations((c) => {
    c.update('targets', (ts) => ts?.map(createTarget));
    c.update('contextTargets', (ct) => ct?.map(createTarget));
    c.update('fallthrough', createFallthrough);
    c.update('rules', (rs) => rs && rs.map(createRule));
    c.update('prerequisites', (ps) => (ps ? ps.map(createPrerequisite) : List()));
    c.update('_summary', createTargetingSummary);
    c.update('evaluation', createContextsEvaluation);
  });
}

export function createEnvFlagSettings(props?: CreateFunctionInput<EnvironmentFlagSettings>) {
  return props instanceof EnvironmentFlagSettings ? props : new EnvironmentFlagSettings(fromJS(props));
}

export function createRuleExclusionRecord(props?: CreateFunctionInput<RuleExclusionSettings>) {
  const cfg = props instanceof RuleExclusionSettings ? props : new RuleExclusionSettings(fromJS(props));
  return cfg.withMutations((c) => {
    c.update('trackEvents', (trackEvents) => trackEvents);
    c.update('trackEventsFallthrough', (trackEventsFallthrough) => trackEventsFallthrough);
    c.update('rules', (rules) => rules && rules.map(createRule));
  });
}

export function createCustomPropertyForm(props: CreateFunctionInput<CustomPropertyForm> = {}) {
  return props instanceof CustomPropertyForm ? props : new CustomPropertyForm(fromJS(props));
}

export function createCustomPropertyConnectForm(props: CreateFunctionInput<CustomPropertyConnectForm> = {}) {
  return props instanceof CustomPropertyConnectForm ? props : new CustomPropertyConnectForm(fromJS(props));
}

function getMetricItems(items: List<Experiment>) {
  // metrics from the get all flags endpoint only contain metric ids and not keys
  const createExperimentItemsByKey = items?.filter((metric) => !!metric.metricKey).map(createExperimentItem);
  if (items) {
    if (!createExperimentItemsByKey.isEmpty()) {
      return createExperimentItemsByKey;
    } else {
      return items?.filter((metric) => metric.getIn(['_metric', '_id'])).map(createExperimentItem);
    }
  }
  return List<Experiment>();
}

export function createExperimentInfo(props?: CreateFunctionInput<ExperimentInfo>) {
  return new ExperimentInfo(fromJS(props)).update('items', (items) => getMetricItems(items.map(createExperiment)));
}

export function createExperiment(props?: CreateFunctionInput<Experiment>) {
  return createExperimentItem(props);
}

export function createTargetingSummary(props?: CreateFunctionInput<TargetingSummary>) {
  const summary = props instanceof TargetingSummary ? props : new TargetingSummary(fromJS(props));
  return summary.withMutations((s) => {
    s.update('variations', (vs) => (vs ? vs.map(createVariationSummary) : Map()));
  });
}

export function createVariationSummary(props?: CreateFunctionInput<VariationSummary>) {
  return props instanceof VariationSummary ? props : new VariationSummary(props);
}

export function createExperimentItem(props?: CreateFunctionInput<Experiment>) {
  return new Experiment(fromJS(props))
    .update('_metric', (m) => createGoal(m))
    .update('_environmentSettings', (es) => es.map((v) => new EnvironmentSettings(v)));
}

export function createFlag(props: CreateFunctionInput<Flag> = {}): Flag {
  let flag = props instanceof Flag ? props : new Flag(fromJS(props));

  flag = flag.withMutations((f) => {
    f.update('variations', (vs) => vs.map(createVariation));
    f.update('defaults', (ds) => (ds ? new Defaults(ds) : undefined));
    f.update('environments', (es) => es.map(createFlagConfiguration));
    f.update('tags', (tags) => tags.toSet());
    f.update('goalIds', (goalIds) => goalIds.toSet());
    f.update('_maintainer', (m) => (m ? createMemberSummary(m) : undefined));
    f.update('_maintainerTeam', (m) => (m ? createTeam(m) : undefined));
    // CHEAT: The API returns a map[string]CustomProperty, but we deal with a List<CustomPropertyForm> in the UI
    //        We use a cast here because we assume createFlag is only called with data from the API.
    f.update('customProperties', (customProperties) =>
      customPropertiesToForm(customProperties as unknown as Map<string, CustomProperty>),
    );
    f.update('experiments', (e) => createExperimentInfo(e.toJS()));
    f.update('codeReferences', (c) => (c ? createAllCodeRefStatsRecord(c) : undefined));
  });

  return flag;
}

// seed default variations for flag  creation with constant ids to make handling changes simpler.
// this id is never stored by the backend.
const defaultIds = [v4(), v4()];

export const defaultBooleanVariations = (
  trueName: string = '',
  trueDescription: string = '',
  falseName: string = '',
  falseDescription: string = '',
) =>
  List.of(
    createVariation({ _id: defaultIds[0], value: true, name: trueName, description: trueDescription }),
    createVariation({ _id: defaultIds[1], value: false, name: falseName, description: falseDescription }),
  );

export const defaultStringVariations = () =>
  List.of(createVariation({ _id: defaultIds[0], value: '' }), createVariation({ _id: defaultIds[1], value: '' }));

export const createDefaultBooleanFlag = (props = {}) =>
  createFlag({
    ...props,
    kind: flagKinds.BOOLEAN,
    variations: defaultBooleanVariations(),
  });

export function groupDependentFlagsByEnv(props: DependentFlagByEnvCollectionProps, env?: string) {
  const flagsWithDependents: FlagAndEnvKeys = {};
  if (env) {
    props.items.forEach((dependentFlag) => {
      const dependentFlagKey = dependentFlag.get('key');
      flagsWithDependents[dependentFlagKey] = [
        {
          flagKey: dependentFlag.get('key'),
          archived: dependentFlag.get('archived'),
          archivedDate: dependentFlag.get('archivedDate'),
          env,
          selfLink: dependentFlag.getIn(['_site', 'href']),
        },
      ];
    });
  }
  return flagsWithDependents;
}

export function groupDependentFlags(props: DependentFlagCollectionProps) {
  const flagsWithDependents: FlagAndEnvKeys = {};
  props.items.forEach((dependentFlag) => {
    const dependentFlagKey = dependentFlag.get('key');
    flagsWithDependents[dependentFlagKey] = [];
    const envs = dependentFlag.get('environments').map((environment) => ({
      flagKey: dependentFlag.get('key'),
      archived: dependentFlag.get('archived'),
      archivedDate: dependentFlag.get('archivedDate'),
      env: environment.get('key'),
      selfLink: environment.getIn(['_site', 'href']),
    }));
    flagsWithDependents[dependentFlagKey] = envs.toArray();
  });
  return flagsWithDependents;
}

export function detectVariationType(flag: Flag) {
  const vs = flag.variations
    .filter((v) => !v.isEmpty())
    .map((v) => v.value)
    .toArray();

  if (!vs.length) {
    return vt.STRING;
  }

  if (areAllBooleans(vs)) {
    return vt.BOOLEAN;
  }

  if (areAllNumbers(vs)) {
    return vt.NUMBER;
  }

  if (areAllJSON(vs)) {
    return vt.JSON;
  }

  return vt.STRING;
}

export function inferVariationType(flag: Flag, isClone?: boolean) {
  const vs: List<Object | string> = flag.variations
    .filter((v) => !v.isEmpty())
    .map((v) => {
      // if the value is an immutable Record or other object, or a clone of an existing flag, return it (because toString will return undesirable results)
      if (v.value instanceof Object || isClone) {
        return v.value;
      }
      // otherwise try to reset everything to strings so the following checks work properly
      return v.value.toString ? v.value.toString() : v.value;
    });

  const booleans = Set.of(true, false);

  if (vs.isEmpty()) {
    return vt.STRING;
  }

  const asBooleans: Set<boolean> = vs
    .map((v) => {
      switch (v) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return undefined;
      }
    })
    .filter(nilFilter)
    .toSet();

  if (is(vs.toSet(), booleans) || (is(asBooleans.toSet(), booleans) && !isClone)) {
    return vt.BOOLEAN;
  }

  if (vs.every(couldBeNumerical)) {
    return vt.NUMBER;
  }

  if (couldAllBeJSON(vs)) {
    return vt.JSON;
  }

  return vt.STRING;
}

export function getFlagRules(flag: Flag, environment: Environment) {
  const env = environment.key;
  const flagEvn = flag.environments.get(env);
  if (flagEvn && flagEvn.rules && flagEvn.rules.size > 0) {
    return flagEvn.rules.map((rule) => rule.clauses.map((c) => c.attribute));
  }
  return List<List<string>>();
}

const scientificNotation = /^-?\d+(\.\d+)?e-?\d+$/;
export function forceVariationType(flag: Flag, t: VariationType) {
  return flag.update('variations', (vs) =>
    vs.map((v) => {
      if (!v.isEmpty()) {
        if (t === VariationType.NUMBER) {
          const asString = coerceToType(v.value, 'number').toString();
          if (asString !== v.value && !scientificNotation.test(asString)) {
            // don't prevent the ability to enter leading or trailing zeroes for number variations
            return v;
          }
        }
        return v.update('value', (value) => coerceToType(value, t));
      }
      return v;
    }),
  );
}

export function hasHomogeneousVariationTypes(flag: Flag) {
  const vs = flag.variations.filter((v) => !v.isEmpty()).map((v) => v.value);
  return vs.every(isString) || vs.every(couldBeNumerical) || vs.every(isJSONValue) || vs.every(isBoolean);
}

export function idOrKey<T extends { _id?: string; _key?: string }>(obj: T): string {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return (obj._id || obj._key)!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

export function filterPendingRemovedTargets(targets: List<string>, pendingRemovedTargets: List<string>) {
  return targets.filter((target: string) => !pendingRemovedTargets.find((removed: string) => removed === target));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackFlagEvent = (event: string, attributes?: any) => createTrackerForCategory('Flags')(event, attributes);
export const trackEditMaintainerHeaderButtonClicked = (kind?: 'member' | 'team') =>
  trackFlagEvent('Edit Maintainer Header Button Clicked', { kind });
export const trackEditMaintainerNameLinkClicked = (kind: 'member' | 'team') =>
  trackFlagEvent('Maintainer Name Link Clicked', { kind });

export const trackNewMaintainerSelectedFromDropdown = (kind: 'team' | 'member') =>
  trackFlagEvent('New Maintainer Selected From Dropdown', { kind });
// Event for Track Filter Value Change
export const trackFilterValueChange = (kind?: 'me' | 'member' | 'team', filterKind?: FilterKind) => {
  trackFlagEvent('Filter Value Change', { kind, filterKind });
};

export const trackFlagMaintainerUpdated = (
  kind: 'team' | 'member' | undefined,
  location: 'maintainer modal' | 'flag settings form',
) => trackFlagSettingsEvent('Flag Maintainer Updated UI', { location, kind });

export const trackFlagTargetingEvent = createTrackerForCategory('FlagsTargeting');
export const trackFlagListToolbarEvent = createTrackerForCategory('FlagListToolbar');
export const trackFlagListEvent = createTrackerForCategory('FlagList');
export const trackFlagOverviewEvent = createTrackerForCategory('FlagOverview');
export const trackFlagDashboardEvent = createTrackerForCategory('Flags Dashboard');
export const trackFlagSettingsEvent = createTrackerForCategory('Flag Settings');

// Indicates whether the selected value is a team or a member or the currently logged in member "me".
export const getSelectedMaintainerKindForAnalytics = (
  maintainerKind: 'member' | 'team',
  value?: string,
  loggedInProfile?: Member,
) => (maintainerKind === 'member' && value === loggedInProfile?._id ? 'me' : maintainerKind);

// This is tricky: value can be any valid property type on flag, including on nested properties (since field can point to nested properties).
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FlagValue = any;

export interface UpdateFlagMaintainerProps {
  disabled?: boolean;
  id: string;
  onChange: (selectedMember: Member) => void;
  placeholder: string;
  value: string;
}

export const getActiveVariations = ({
  environmentKey,
  flag,
  segmentKey,
  useFlagConfiguration,
}: {
  environmentKey: string;
  flag: Flag;
  segmentKey?: string;
  useFlagConfiguration: boolean;
}) => {
  if (useFlagConfiguration) {
    return flag.getActiveVariationsFromConfig(environmentKey);
  }
  if (segmentKey) {
    return flag.getActiveVariationsContainingSegment(environmentKey, segmentKey);
  }
  return flag.getActiveVariationsFromSummary(environmentKey);
};

export function getControlAndTestVariations(
  originalFlag: Flag,
  draftFlag: Flag,
  environmentKey: string,
  rule: Rule | Fallthrough,
) {
  const originalConfig = originalFlag.getConfiguration(environmentKey);

  const flagIsOff = !originalConfig.on;
  const offVariation = originalFlag.getOffVariation(environmentKey);
  const existingRule =
    rule instanceof Fallthrough
      ? originalConfig.fallthrough
      : originalFlag.getRules(environmentKey)?.find((r) => r._id === rule._id);

  const controlVariation = flagIsOff
    ? offVariation
    : existingRule && typeof existingRule.variation === 'number'
      ? draftFlag.variations.get(existingRule.variation)
      : typeof originalConfig.fallthrough.variation === 'number'
        ? draftFlag.variations.get(originalConfig.fallthrough.variation)
        : offVariation;
  const testVariation = typeof rule.variation === 'number' ? draftFlag.variations.get(rule.variation) : undefined;

  return { controlVariation, testVariation };
}

export function clearPercentageRollout(flag: Flag, environmentKey: string, rule: Rule | Fallthrough) {
  return flag.updateConfiguration(environmentKey, (draftFlagConfig) => {
    if (rule instanceof Fallthrough) {
      return draftFlagConfig.deleteIn(['fallthrough', 'rollout']);
    } else {
      const ruleIndex = draftFlagConfig.rules.findIndex((r) => r._id === rule._id || r._key === rule._key);
      return draftFlagConfig.deleteIn(['rules', ruleIndex, 'rollout']);
    }
  });
}

export function createRuleMeasuredRolloutInstruction(
  ruleId: string,
  testVariationId: string,
  controlVariationId: string,
  config?: MeasuredRolloutConfig,
): SemanticInstruction {
  if (!config) {
    return makeUpdateRuleVariationInstruction(ruleId, testVariationId);
  }

  return enableReleaseGuardianRefreshedUI()
    ? makeUpdateRuleWithMeasuredRolloutV2Instruction(ruleId, testVariationId, controlVariationId, config)
    : makeUpdateRuleWithMeasuredRolloutInstruction(ruleId, testVariationId, controlVariationId, config);
}

export function setDefaultVariationForRollout(
  originalFlag: Flag,
  draftFlag: Flag,
  environmentKey: string,
  rule: Rule | Fallthrough,
): Flag {
  // Get control and test variations, so we can autoselect a valid non-control variation
  const { controlVariation, testVariation } = getControlAndTestVariations(
    originalFlag,
    draftFlag,
    environmentKey,
    rule,
  );
  const newVariation = draftFlag.getDefaultRolloutVariation(controlVariation?._id, testVariation?._id);

  if (!newVariation) {
    return draftFlag;
  }

  // Apply the autoselected variation to the rule or fallthrough
  if (rule instanceof Rule) {
    return draftFlag.setRuleVariation(environmentKey, rule, newVariation);
  } else {
    return draftFlag.setFallthroughVariation(environmentKey, newVariation);
  }
}

import { Suspense } from 'react';
import { Placement } from 'react-aria';
import { Link } from 'react-router';
import { enableMetricEventActivity } from '@gonfalon/dogfood-flags';
import { ErrorBoundary } from '@gonfalon/error-boundaries';
import { toMetric } from '@gonfalon/navigator';
import { useSuspenseMetric } from '@gonfalon/rest-api';
import { useProjectKey } from '@gonfalon/router';
import { Alert, OverlayArrow, Pressable, ProgressBar, Tooltip, TooltipTrigger } from '@launchpad-ui/components';

import { MetricDefinition } from '../MetricDefinition';
import MetricKind from '../MetricKind';
import { MetricLabel } from '../MetricLabel';
import { MetricLastSeenInfo } from '../MetricLastSeenInfo';
import { Metric } from '../types';
import { useSuspenseEventActivityByEventKey } from '../useSuspenseEventActivityByEventKey';

import styles from './MetricInfoHover.module.css';

interface MetricInfoHoverProps {
  children: React.ReactNode;
  placement?: Placement;
  metricVersionId?: string;
}
interface MetricInfoHoverWithMetricProps extends MetricInfoHoverProps {
  metric: Metric;
  metricKey?: never;
}
interface MetricInfoHoverWithMetricKeyProps extends MetricInfoHoverProps {
  metric?: never;
  metricKey: string;
}

const MetricInfoHoverLastSeen: React.FC<{ eventKey: string }> = ({ eventKey }) => {
  const projectKey = useProjectKey();
  const { data: eventActivity } = useSuspenseEventActivityByEventKey(projectKey, eventKey ?? '', {});

  const lastSeen = eventActivity.items?.[0]?.lastSeen;
  const lastSeenEnvironment = eventActivity.items?.[0]?.environment;

  const icon = lastSeen ? (Date.now() - lastSeen < 86400000 ? 'status-active' : 'half-circle') : 'circle-dashed';
  return (
    <MetricLastSeenInfo
      lastSeen={lastSeen}
      icon={icon}
      environmentName={lastSeenEnvironment?.name}
      environmentColor={lastSeenEnvironment?.color}
    />
  );
};

const MetricInfoHoverContent: React.FC<{ metric: Metric; projectKey: string }> = ({ metric, projectKey }) => (
  <div className={styles.infoContainer}>
    <div className={styles.titleRow}>
      {metric.name}{' '}
      <MetricLabel
        kind={(metric.kind as MetricKind) ?? 'click'}
        isNumeric={metric.isNumeric}
        unitAggregationType={metric.unitAggregationType}
      />
    </div>
    {metric.description && <div className={styles.description}>{metric.description}</div>}
    <div className={styles.definitionContainer}>
      Metric definition
      <div className={styles.defPadding}>
        <MetricDefinition metric={metric} />
      </div>
      <div className={styles.lastSeenAndLink}>
        {metric.eventKey && enableMetricEventActivity() && (
          <Suspense fallback={<ProgressBar aria-label="Loading…" isIndeterminate size="small" />}>
            <ErrorBoundary
              severity="medium"
              fallbackRender={() => (
                <Alert status="error">An unexpected error has occurred checking CUPED status for this experiment</Alert>
              )}
            >
              <MetricInfoHoverLastSeen eventKey={metric.eventKey} />
            </ErrorBoundary>
          </Suspense>
        )}
        <Link to={toMetric({ projectKey, metricKey: metric.key })} className={styles.metricLink}>
          View metric
        </Link>
      </div>
    </div>
  </div>
);

const MetricInfoHoverSuspenseMetric: React.FC<{ projectKey: string; metricKey: string; metricVersionId?: string }> = ({
  metricKey,
  metricVersionId,
  projectKey,
}) => {
  const { data: metric } = useSuspenseMetric({
    projectKey,
    metricKey: metricKey ?? '',
    params: { versionId: metricVersionId },
  });
  return <MetricInfoHoverContent metric={metric} projectKey={projectKey} />;
};

export const MetricInfoHover: React.FC<MetricInfoHoverWithMetricProps | MetricInfoHoverWithMetricKeyProps> = ({
  children,
  metric,
  placement = 'top',
  metricKey,
  metricVersionId,
}) => {
  const projectKey = useProjectKey();
  return (
    <TooltipTrigger>
      <Pressable>{children}</Pressable>
      <Tooltip className={styles.tooltip} variant="popover" placement={placement} offset={16}>
        <OverlayArrow />
        {metric ? (
          <MetricInfoHoverContent metric={metric} projectKey={projectKey} />
        ) : (
          <Suspense fallback={<ProgressBar aria-label="Loading…" isIndeterminate size="large" />}>
            <ErrorBoundary
              severity="medium"
              fallbackRender={() => (
                <Alert status="error">An unexpected error has occurred checking CUPED status for this experiment</Alert>
              )}
            >
              <MetricInfoHoverSuspenseMetric
                metricKey={metricKey ?? ''}
                metricVersionId={metricVersionId}
                projectKey={projectKey}
              />
            </ErrorBoundary>
          </Suspense>
        )}
      </Tooltip>
    </TooltipTrigger>
  );
};

import { useStore } from 'react-redux';
import { Path, useNavigate } from 'react-router';
import { isArray, kebabCase, noop } from '@gonfalon/es6-utils';
import { toProjectEnvironments, toProjects } from '@gonfalon/navigator';

import { useDispatch } from 'hooks/useDispatch';
import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { reportAccesses } from 'sources/AccountAPI';
import {
  createProject as projectAPICreateProject,
  deleteProject as projectAPIDeleteProject,
  fetchProjectEnvironments as projectAPIFetchProjectEnvironments,
  getAllProjects as projectAPIGetAllProjects,
  getAllProjectsV3 as projectAPIGetAllProjectsV3,
  getProject as projectAPIGetProject,
  updateProject as projectAPIUpdateProject,
} from 'sources/ProjectAPI';
import { accessResponseDeniesUpdateTags, makeProjectSpec } from 'utils/accessUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import {
  createEnvironmentsFilters,
  EnvironmentsFilters,
  NewProject,
  NormalizedEnvironmentsCollectionResponse,
  NormalizedProjectCollectionResponse,
  NormalizedProjectResponse,
  Project,
  ProjectFilters,
  truncateKey,
} from 'utils/projectUtils';
import { GenerateActionType } from 'utils/reduxUtils';

const requestProjects = () => ({ type: 'projects/REQUEST_PROJECTS' }) as const;

const requestProject = (projectKey: string) => ({ type: 'projects/REQUEST_PROJECT', projectKey }) as const;

const requestProjectsDone = (response: NormalizedProjectCollectionResponse) =>
  ({ type: 'projects/REQUEST_PROJECTS_DONE', response }) as const;

const requestPaginatedProjects = () => ({ type: 'projects/REQUEST_PAGINATED_PROJECTS' }) as const;

const requestPaginatedProjectsDone = (response: NormalizedProjectCollectionResponse) =>
  ({ type: 'projects/REQUEST_PAGINATED_PROJECTS_DONE', response }) as const;

const requestPaginatedProjectsFailed = (error: ImmutableServerError) =>
  ({ type: 'projects/REQUEST_PAGINATED_PROJECTS_FAILED', error }) as const;

const requestProjectDone = (projectKey: string, response: NormalizedProjectResponse) =>
  ({ type: 'projects/REQUEST_PROJECT_DONE', projectKey, response }) as const;

const requestProjectEnvironments = (projectKey: string) =>
  ({ type: 'projects/REQUEST_PROJECT_ENVIRONMENTS', projectKey }) as const;

const requestProjectEnvironmentsDone = (response: NormalizedEnvironmentsCollectionResponse) =>
  ({ type: 'projects/REQUEST_PROJECT_ENVIRONMENTS_DONE', response }) as const;

const requestProjectEnvironmentsFailed = (error: ImmutableServerError) =>
  ({ type: 'projects/REQUEST_PROJECT_ENVIRONMENTS_FAILED', error }) as const;

const requestProjectsFailed = (error: ImmutableServerError) =>
  ({ type: 'projects/REQUEST_PROJECTS_FAILED', error }) as const;
const requestProjectFailed = (projectKey: string, error: ImmutableServerError) =>
  ({ type: 'projects/REQUEST_PROJECT_FAILED', projectKey, error }) as const;

export function fetchProject(projectKey: string) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestProject(projectKey));
    return new Promise((resolve, reject) => {
      projectAPIGetProject(projectKey)
        .then((response) => {
          dispatch(requestProjectDone(projectKey, response));
          resolve(response);
        })
        .catch((error) => {
          dispatch(requestProjectFailed(projectKey, error));
          reject(error);
        });
    });
  };
}

export function fetchPaginatedProjects(projectFilters?: ProjectFilters) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestPaginatedProjects());
    return new Promise((resolve, reject) => {
      projectAPIGetAllProjectsV3(projectFilters)
        .then((response) => {
          dispatch(requestPaginatedProjectsDone(response));
          resolve(response);
        })
        .catch((error) => {
          dispatch(requestPaginatedProjectsFailed(error));
          reject(error);
        });
    });
  };
}

function fetchProjects() {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestProjects());
    return new Promise<NormalizedProjectCollectionResponse>((resolve, reject) => {
      projectAPIGetAllProjects()
        .then((response) => {
          dispatch(requestProjectsDone(response));
          resolve(response);
        })
        .catch((error) => {
          dispatch(requestProjectsFailed(error));
          reject(error);
        });
    });
  };
}

function _fetchProjectEnvironments(projectKey: string, filters?: EnvironmentsFilters) {
  return async (dispatch: GlobalDispatch) => {
    dispatch(requestProjectEnvironments(projectKey));
    return new Promise((resolve, reject) => {
      projectAPIFetchProjectEnvironments(projectKey, createEnvironmentsFilters(filters))
        .then((response) => {
          dispatch(requestProjectEnvironmentsDone(response));
          resolve(response);
        })
        .catch((error) => {
          dispatch(requestProjectEnvironmentsFailed(error));
          reject(error);
        });
    });
  };
}

export function fetchProjectEnvironments(projectKey: string, filters?: EnvironmentsFilters) {
  return async (dispatch: GlobalDispatch) => dispatch(_fetchProjectEnvironments(projectKey, filters));
}

function shouldFetchProjects(state: GlobalState) {
  return !state.projects.get('lastFetched') && !state.projects.get('isFetching') && !state.projects.get('doNotFetch');
}

function fetchProjectsIfNeeded() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldFetchProjects(getState())) {
      return dispatch(fetchProjects());
    } else {
      return Promise.resolve();
    }
  };
}

export { fetchProjectsIfNeeded as fetchProjects };

const asArray = (field: string | string[]) => (isArray(field) ? field : [field]);

const checkResourceAccess = (willRemoveEditingAbility: boolean) =>
  ({ type: 'projects/CHECK_ACCESS_RESOURCE', willRemoveEditingAbility }) as const;

const edit = (field: string | string[], project: Project) =>
  ({ type: 'projects/EDIT_PROJECT', field, project }) as const;

export function editProject(
  field: string | string[],
  value: $TSFixMe,
  options: { isAccessWarningEnabled?: boolean } = {},
) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    const form = getState().projectForm;

    let modified = form.modified;
    const original = form.original;
    if (field === 'key') {
      modified = modified.set(field, truncateKey(value));
    } else {
      modified = modified.setIn(asArray(field), value);
    }

    if (field === 'name' && !form.wasChanged('key') && modified.isNew()) {
      modified = modified.set('key', truncateKey(kebabCase(value)));
    }

    dispatch(edit(field, modified));

    const { isAccessWarningEnabled } = options;
    if (isAccessWarningEnabled && field === 'tags' && !!original._access) {
      const projectKey = original.get('key');
      const newResourceSpec = makeProjectSpec(projectKey, value);
      const oldAccessDenied = original._access.get('denied');
      reportAccesses(newResourceSpec).then((accesses) => {
        const willRemoveEditingAbility = accessResponseDeniesUpdateTags(accesses, newResourceSpec, oldAccessDenied);
        dispatch(checkResourceAccess(willRemoveEditingAbility));
      }, noop);
    }
  };
}

const create = (project: NewProject) => ({ type: 'projects/CREATE_PROJECT', project }) as const;

const createDone = (response: NormalizedProjectResponse) =>
  ({ type: 'projects/CREATE_PROJECT_DONE', response }) as const;

const createFailed = (project: NewProject, error: ImmutableServerError) =>
  ({
    type: 'projects/CREATE_PROJECT_FAILED',
    project,
    error,
  }) as const;

export function useCreateProject(options?: { onPermissionsError?: () => void }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useStore();

  return async (filters: ProjectFilters) => {
    const projectsUrl = toProjects({ search: filters.toQueryString() });
    const formState = store.getState().projectForm;
    const { modified } = formState;
    dispatch(create(modified));
    return projectAPICreateProject(modified)
      .then(async (response) => {
        await navigate(toProjectEnvironments({ projectKey: modified.key }));
        dispatch(createDone(response));
      })
      .catch(async (error) => {
        if (error.get('status') === 403) {
          if (options && options.onPermissionsError) {
            options.onPermissionsError();
          }

          await navigate(projectsUrl);
        }
        dispatch(createFailed(modified, error));
      });
  };
}

const update = (original: Project, modified: Project) =>
  ({ type: 'projects/UPDATE_PROJECT', original, modified }) as const;

const updateDone = (response: NormalizedProjectResponse) =>
  ({ type: 'projects/UPDATE_PROJECT_DONE', response }) as const;

const updateFailed = (modified: Project, error: ImmutableServerError) =>
  ({
    type: 'projects/UPDATE_PROJECT_FAILED',
    project: modified,
    error,
  }) as const;

export function updateProjectSettings() {
  return async (dispatch: GlobalDispatch, getState: GetState) => {
    const formState = getState().projectForm;
    const { original, modified } = formState;
    dispatch(update(original, modified));
    return projectAPIUpdateProject(original, modified)
      .then((response) => {
        dispatch(updateDone(response));
      })
      .catch((error) => {
        dispatch(updateFailed(modified, error));
      });
  };
}

const del = (project: Project) => ({ type: 'projects/DELETE_PROJECT', project }) as const;

const deleteDone = (project: Project) => ({ type: 'projects/DELETE_PROJECT_DONE', project }) as const;

const deleteFailed = (project: Project, error: ImmutableServerError) =>
  ({ type: 'projects/DELETE_PROJECT_FAILED', project, error }) as const;

export function useDeleteProject() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async (project: Project, filters: ProjectFilters, redirectUrl?: Path) => {
    const projectsUrl = toProjects({ search: filters.toQueryString() });
    const redirect = redirectUrl ?? projectsUrl;
    dispatch(del(project));
    return projectAPIDeleteProject(project)
      .then(async () => {
        await navigate(redirect);
        dispatch(deleteDone(project));
      })
      .catch(async (error) => {
        if (error.get('status') === 403 || error.get('status') === 404) {
          await navigate(redirect);
        }
        dispatch(deleteFailed(project, error));
      });
  };
}

const ProjectActionCreators = {
  requestPaginatedProjects,
  requestPaginatedProjectsDone,
  requestPaginatedProjectsFailed,
  requestProjects,
  requestProjectsDone,
  requestProject,
  requestProjectDone,
  requestProjectFailed,
  requestProjectsFailed,
  requestProjectEnvironments,
  requestProjectEnvironmentsDone,
  requestProjectEnvironmentsFailed,
  checkResourceAccess,
  create,
  createDone,
  createFailed,
  edit,
  update,
  updateDone,
  updateFailed,
  del,
  deleteDone,
  deleteFailed,
};

export type ProjectAction = GenerateActionType<typeof ProjectActionCreators>;

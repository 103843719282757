// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
import { profile as _profile } from '@gonfalon/bootstrap-data';
// eslint-disable-next-line no-restricted-imports
import { fromJS, Map } from 'immutable';
import { createSelector } from 'reselect';

import { FormAction } from 'actions/forms';
import { ProfileAction } from 'actions/profile';
import { GlobalState } from 'reducers';
import { createMember, createPasswordUpdate, ImmutableNotificationsSettingsType, Member } from 'utils/accountUtils';
import { createFormState } from 'utils/formUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';

import { getProfileInitialState } from './getProfileInitialState';
import registry from './registry';

function getUpdateProfileFormInitialState() {
  const state = createFormState(createMember());
  const p = _profile();

  if (p) {
    return state.reset(createMember(fromJS(p)));
  }

  return state;
}

export function updateProfileForm(state = getUpdateProfileFormInitialState(), action: ProfileAction | FormAction) {
  switch (action.type) {
    case 'profile/REQUEST_PROFILE_DONE':
      return state.reset(action.profile);
    case 'profile/EDIT_PROFILE':
      return state.trackField(action.field).revalidate(action.profile);
    case 'profile/UPDATE_PROFILE':
      return state.submitting();
    case 'profile/UPDATE_PROFILE_DONE':
      return state.submitted(action.profile);
    case 'profile/UPDATE_PROFILE_FAILED':
      return state.submitFailed(action.profile, action.error, action.keepState);
    case 'forms/BLUR':
      if (action.model !== 'updateProfileForm') {
        return state;
      }
      return state.handleBlur(action.field, state.modified);
    default:
      return state;
  }
}

export function updatePasswordForm(
  state = createFormState(createPasswordUpdate()),
  action: ProfileAction | FormAction,
) {
  switch (action.type) {
    case 'profile/EDIT_PASSWORD':
      return state.trackField(action.field).revalidate(action.password);
    case 'profile/UPDATE_PASSWORD':
      return state.submitting();
    case 'profile/UPDATE_PASSWORD_DONE':
      return state.submitted(action.password);
    case 'profile/UPDATE_PASSWORD_FAILED':
      return state.submitFailed(action.password, action.error);
    case 'forms/BLUR':
      if (action.model !== 'updatePasswordForm') {
        return state;
      }
      return state.handleBlur(action.field, state.modified);
    default:
      return state;
  }
}

export type ProfileNotificationsState = ImmutableMap<{
  isFetching: boolean;
  error?: ImmutableServerError;
  lastFetched?: number;
  entity: ImmutableNotificationsSettingsType;
  doNotFetch?: boolean;
  topic?: string;
  oldNotificationSettings?: ImmutableNotificationsSettingsType;
}>;

export function profileNotificationSettings(
  state: ProfileNotificationsState = Map({ entity: undefined, lastFetched: null, isFetching: false }),
  action: ProfileAction,
): ProfileNotificationsState {
  switch (action.type) {
    case 'profile/REQUEST_NOTIFICATION_SETTINGS':
      return state.set('isFetching', true);
    case 'profile/REQUEST_NOTIFICATION_SETTINGS_DONE':
      return state.merge({
        isFetching: false,
        entity: action.notificationSettings,
        lastFetched: Date.now(),
      });
    case 'profile/REQUEST_NOTIFICATION_SETTINGS_FAILED':
      return state.merge({
        isFetching: false,
        error: action.error,
        doNotFetch: true,
      });
    case 'profile/UPDATE_NOTIFICATION_SETTINGS':
      return state.mergeIn(['entity', action.topic], action.notificationSettings);
    case 'profile/UPDATE_NOTIFICATION_SETTINGS_FAILED':
      return state.merge(
        fromJS({
          ...action.notificationSettings,
        }),
      );
    default:
      return state;
  }
}

export type ProfileState = ImmutableMap<{
  entity: Member;
  lastFetched?: number;
  isFetching: boolean;
  error?: ImmutableServerError;
  doNotFetch?: boolean;
}>;

export function profile(state: ProfileState = getProfileInitialState(), action: ProfileAction) {
  switch (action.type) {
    case 'profile/REQUEST_PROFILE':
      return state.set('isFetching', true);
    case 'profile/REQUEST_PROFILE_FAILED':
      return state.merge({
        isFetching: false,
        error: action.error,
        doNotFetch: true,
      });
    case 'profile/REQUEST_PROFILE_DONE':
      return state.merge({
        isFetching: false,
        entity: action.profile,
        lastFetched: action.timestamp,
      });
    default:
      return state;
  }
}

registry.addReducers({ profile, updateProfileForm, updatePasswordForm, profileNotificationSettings });

export const profileSelector = (state: GlobalState) => state.profile;

export const notificationSettingsSelector = (state: GlobalState) => state.profileNotificationSettings;

export const appNotificationsEnabledSelector = createSelector(
  notificationSettingsSelector,
  (state: ProfileNotificationsState) => {
    const settings = state.get('entity');
    if (!settings) {
      return false;
    }

    const getIfNotificationsDisabled = (notificationSettings: ImmutableNotificationsSettingsType) => {
      const categoriesEnabled = notificationSettings.filter((categorySettings) => !!categorySettings.get('app'));

      return categoriesEnabled.size > 0;
    };

    return getIfNotificationsDisabled(settings);
  },
);

export function useProfile() {
  return useSelector(profileSelector);
}
export function useProfileEntity() {
  return useProfile().get('entity');
}

export const updateProfileFormSelector = (state: GlobalState) => state.updateProfileForm;
export const updatePasswordFormSelector = (state: GlobalState) => state.updatePasswordForm;

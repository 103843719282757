type StateChart<State extends string, Transition extends string> = {
  [state in State]: {
    transitions?: {
      [transition in Transition]?: Partial<State>;
    };
  };
};

export function createStateChartReducer<State extends string, Transition extends string>(
  chart: StateChart<State, Transition>,
) {
  return function reducer(state: State, transition: Transition) {
    const transitions = chart[state].transitions;
    const nextState = transitions?.[transition];
    return (nextState ?? state) as State;
  };
}

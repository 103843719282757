import { enableAmberfloOveragesUI } from '@gonfalon/dogfood-flags';
import { DateFormat, format, formatNumber } from '@gonfalon/format';
import { pluralize } from '@gonfalon/strings';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record } from 'immutable';

import { amberfloMeter, AmberfloMeterName } from 'components/amberflo/utils/amberfloUtils';
import { NEAR_OVERAGES_THRESHOLD } from 'components/usage/constants';
import { load, save } from 'sources/AccountLocalStorage';
import {
  computeLimitRatio,
  getAmberfloMeterNameForLimit,
  getLimitNameForOverageType,
  isAmberfloMeter,
  limitNames,
  PlanLimits,
  SubscriptionUsage,
} from 'utils/billingUtils';
import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';

export type DismissedOverages = ImmutableMap<{
  [K in limitNames]?: string;
}>;

export type Overage = ImmutableMap<{
  key: limitNames;
  usageOver: string;
  usageType: string;
}>;

export type OveragesType = {
  over: List<Overage>;
  near: List<Overage>;
  canUpdatePlan: boolean;
  resetDate?: string;
  hasSubscription: boolean;
};

export class Overages extends Record<OveragesType>({
  over: List<Overage>(),
  near: List<Overage>(),
  canUpdatePlan: false,
  resetDate: undefined,
  hasSubscription: false,
}) {}

export function createOverages(props?: CreateFunctionInput<Overages>) {
  return props ? new Overages(fromJS(props)) : new Overages();
}

export const computeOverage = ({
  usage,
  limit,
  usageType,
  key,
  usagePeriodEndDate,
  dismissedOverages,
}: {
  usage: number;
  limit: number;
  usageType: string;
  key: limitNames;
  usagePeriodEndDate?: string;
  dismissedOverages?: DismissedOverages;
}) => {
  const usageLimit = computeLimitRatio(usage, limit);
  if (usageLimit === Infinity) {
    return;
  }

  const metricUsagePercent = Math.round(usageLimit * 100);

  const overageInfo = {
    usageOver: key === 'SeatCount' ? `${usage} / ${limit}` : `${metricUsagePercent}%`,
    usageType: pluralize(usageType, metricUsagePercent),
    key,
  };
  if (usageLimit > 1) {
    return { over: overageInfo };
  }
  if (usageLimit > NEAR_OVERAGES_THRESHOLD) {
    if (dismissedOverages?.get(key) === usagePeriodEndDate) {
      return;
    }
    if (key !== 'SeatCount') {
      return { near: overageInfo };
    }
  }
};

export enum overageNames {
  Hosts = 'hosts',
  EventsReceived = 'experimentation event',
  EventsPublished = 'data export event',
  ExperimentationKeys = 'experimentation keys',
  MonthlyActiveUsers = 'client MAU',
  SeatCount = 'seat count',
  SSO = 'single sign on',
  ServiceConnections = 'service connections',
}

const amberfloOverageNames: { [key in AmberfloMeterName]?: string } = {
  [amberfloMeter.EXPERIMENTATION_KEYS]: 'experimentation key',
  [amberfloMeter.CLIENT_SIDE_CONTEXTS]: 'monthly client-side context',
};

function getOverageName(limitName: limitNames): string {
  if (isAmberfloMeter(limitName) && enableAmberfloOveragesUI()) {
    const meterName = getAmberfloMeterNameForLimit(limitName);
    if (meterName) {
      const name = amberfloOverageNames[meterName];
      if (name) {
        return name;
      }
    }
  }

  return overageNames[limitName];
}

export const formatResetDate = (d: string | number | Date) => format(new Date(d), DateFormat.MMM_D);

export const findOverages = ({
  usages,
  limits,
  usagePeriodEndDate,
  dismissedOverages,
  canUpdatePlan,
  hasSubscription,
  isAmberfloSourcedUsage,
  specificOverageAlerts,
  amberfloUsageTotals,
}: {
  usages: SubscriptionUsage;
  limits: PlanLimits;
  usagePeriodEndDate?: string;
  dismissedOverages?: DismissedOverages;
  canUpdatePlan: boolean;
  hasSubscription: boolean;
  isAmberfloSourcedUsage: boolean;
  specificOverageAlerts: string[];
  amberfloUsageTotals?: { [key: string]: number };
}) => {
  let overages = createOverages({
    over: [],
    near: [],
    resetDate: usagePeriodEndDate,
    canUpdatePlan,
    hasSubscription,
  });
  specificOverageAlerts.forEach((ot) => {
    const limitName = getLimitNameForOverageType(ot);
    if (!limitName) {
      return;
    }

    let usage = 0;
    if (
      // Only use Amberflo usage for MONTHLY_ACTIVE_USERS if the usage is sourced from Amberflo.
      // Otherwise, if the subscription is cMau based, use cMetrics usage.
      isAmberfloMeter(limitName) &&
      amberfloUsageTotals &&
      (limitName !== limitNames.MONTHLY_ACTIVE_USERS ||
        (limitName === limitNames.MONTHLY_ACTIVE_USERS && isAmberfloSourcedUsage))
    ) {
      const meterName = getAmberfloMeterNameForLimit(limitName);
      if (meterName) {
        usage = amberfloUsageTotals[meterName];
      }
    } else {
      usage = usages.get(limitName);
    }

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const limit = computeOverage({
      usage,
      // In this context, limits will always exist since they come from a subscription object: they will never be null, so we assert that here.
      limit: limits.get(limitName)!,
      usageType: getOverageName(limitName),
      key: limitName,
      usagePeriodEndDate,
      dismissedOverages,
    }); /* eslint-enable @typescript-eslint/no-non-null-assertion */

    if (limit && limit.over) {
      overages = overages.update('over', (over) => over.push(Map(limit.over)));
    } else if (limit && limit.near) {
      overages = overages.update('near', (near) => near.push(Map(limit.near)));
    }
  });

  return overages;
};

export const formatOverageValue = (usageOver: string) => {
  let formattedUsageOver = '';

  if (usageOver.endsWith('%')) {
    usageOver.slice(0, -1);
    formattedUsageOver = formatNumber(parseInt(usageOver, 10));
    formattedUsageOver += '%';
  } else {
    formattedUsageOver = usageOver;
  }

  return formattedUsageOver;
};

export const saveOveragesToCache = (overages: Overages) => save(load().set('overages', overages));
export const getOveragesFromCache = () => {
  const cached = load().get('overages');
  if (cached) {
    return createOverages(cached);
  }

  return createOverages();
};

export const getDismissedOveragesFromCache = () => load().get('dismissedOverages') || (Map() as DismissedOverages);

export const saveDismissedOveragesToCache = (dismissedOverages: List<Overage>, usagePeriodEndDate: string) =>
  dismissedOverages.forEach((o) =>
    save(load().update('dismissedOverages', (all) => (all || Map()).set(o.get('key'), usagePeriodEndDate)))?.get(
      'dismissedOverages',
    ),
  );

import { useEffect } from 'react';
import { enableSuggestInvites as enableSuggestInvitesContext } from '@gonfalon/dogfood-flags';
import { LinkButton, LinkButtonProps } from '@launchpad-ui/components';
import { Button, ButtonProps as LaunchpadButtonProps, Tooltip, TooltipProps } from 'launchpad';

import { useInviteMembersModal } from 'hooks/useInviteMembersModal';
import { useInviteMembersOption } from 'hooks/useInviteMembersOption';
import { useSuggestInviteMembersModal } from 'hooks/useSuggestInviteMembersModal';
import { trackAddMembersList } from 'utils/accountUtils';

import RequestSeatsControl from '../requestSeats/RequestSeatsControl';

import { InviteMembersModalProps } from './InviteMembersModal';

type OwnButtonProps = {
  cta?: string;
  tooltipContent?: TooltipProps['content'];
};

type ButtonProps = OwnButtonProps & (LaunchpadButtonProps & LinkButtonProps);

type AnalyticsProps = {
  componentName: string;
  type: 'link' | 'button';
};

type OwnModalProps = {
  additionalShowModalCheck?: boolean;
  showModalOnRender?: boolean;
};

type ModalProps = OwnModalProps & InviteMembersModalProps;

export type InviteMembersControlProps = {
  buttonProps?: ButtonProps;
  analyticsProps: AnalyticsProps;
  modalProps: ModalProps;
};

// A single source of truth that serves as the control for opening the InviteMembersModal.
// It is composed of a Button, Tooltip, and the redux actions that handle opening the InviteMembersModal.
// It accepts analytics attributes passed for event 'trackAddMembersList'
export const InviteMembersControl = ({ buttonProps = {}, analyticsProps, modalProps }: InviteMembersControlProps) => {
  const { cta = 'Invite members', kind = 'primary', tooltipContent, onClick, disabled, ...restBtnProps } = buttonProps;
  const { componentName, type } = analyticsProps;
  const { additionalShowModalCheck, showModalOnRender = false, ...restModalProps } = modalProps;

  const suggestInvitesEnabled = enableSuggestInvitesContext();
  const { canInviteMembers, expiredTrialOrCanceled, renderRequestSeats, shouldDisableButton } =
    useInviteMembersOption();
  const { openModal: openInviteMembersModal } = useInviteMembersModal();
  const { openModal: openSuggestInvitesModal } = useSuggestInviteMembersModal();

  const disableButton = disabled || shouldDisableButton;
  const openModal = () => {
    if (suggestInvitesEnabled && !canInviteMembers) {
      openSuggestInvitesModal(restModalProps);
      return;
    }

    openInviteMembersModal(restModalProps);
  };

  useEffect(() => {
    // undefined is a valid value here because it's optional, so need to explicitly check for false
    if (additionalShowModalCheck === false) {
      return;
    }

    if (showModalOnRender) {
      openModal();
    }
  }, [additionalShowModalCheck]);

  if (renderRequestSeats) {
    return (
      <RequestSeatsControl
        analyticsProps={{ componentName: 'InviteMembersControl' }}
        buttonProps={{ kind: 'primary', ...buttonProps }}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - ignoring because button and linkbutton take slightly differently typed onClick events
  const handleBtnClicked = (e) => {
    if (additionalShowModalCheck === false) {
      return;
    }

    openModal();
    trackAddMembersList({
      url: window.location.pathname,
      component: componentName,
      type,
      hasInviteMemberAccess: canInviteMembers,
    });

    onClick?.(e);
  };

  const renderButton = () => {
    if (
      'href' in restBtnProps &&
      kind !== 'link' &&
      kind !== 'close' &&
      kind !== 'primaryFlair' &&
      kind !== 'defaultFlair' &&
      kind !== 'minimalFlair'
    ) {
      return (
        <LinkButton variant={kind} onPress={handleBtnClicked} isDisabled={disableButton} {...restBtnProps}>
          {cta}
        </LinkButton>
      );
    }

    return (
      <Button kind={kind} onClick={handleBtnClicked} disabled={disableButton} {...restBtnProps}>
        {cta}
      </Button>
    );
  };

  const render = () => {
    // before suggest invites was conceptualized, we only showed the invite members control if the user could invite members
    if (!suggestInvitesEnabled && !canInviteMembers) {
      return null;
    }

    // shown to any type of role regardless of whether they can invite members or not
    if (expiredTrialOrCanceled) {
      return (
        <Tooltip content="You are on a lapsed account and cannot invite members. Please select a plan.">
          {renderButton()}
        </Tooltip>
      );
    }

    if (tooltipContent) {
      return <Tooltip content={tooltipContent}>{renderButton()}</Tooltip>;
    }

    return renderButton();
  };

  return render();
};

import { useSearchParams } from 'react-router';
import nullthrows from 'nullthrows';

/**
 * Returns the "current" environment key from URL search params
 */
export function useSelectedEnvironmentKey(): string;
export function useSelectedEnvironmentKey(options: { optional: boolean }): string | undefined;
export function useSelectedEnvironmentKey({ optional }: { optional?: boolean } = {}): string | undefined {
  const [searchParams] = useSearchParams();

  const selectedEnvParam = searchParams.get('selected-env');
  return optional
    ? selectedEnvParam || undefined
    : nullthrows(selectedEnvParam, 'Expected selected environment key to be defined');
}

import { useEffect, useState } from 'react';
import { isModernSubscription } from '@gonfalon/billing';
import { trackCreateSupportTicket } from '@gonfalon/contact-support';
import { useAccountContext } from '@gonfalon/context';
import { NewTabLink } from '@gonfalon/launchpad-experimental';
import cx from 'clsx';
import { Button, ModalBody, ModalHeader } from 'launchpad';

import { trackContactSalesFormSubmitted } from 'utils/contactSalesUtils';

import 'components/SkeletonLoader/styles.css';
import styles from './styles/ContactUsForm.module.css';

const baseUrl = '//go.launchdarkly.com';
const munchkinId = '850-KKH-319';
const formId = '1941';

export type ContactUsFormProps = {
  headerText?: string;
  requestedFeature?: string;
  productInterest?: string;
  onFormSubmit?(): void;
};

export function ContactUsForm({ requestedFeature, headerText, productInterest, onFormSubmit }: ContactUsFormProps) {
  const { profile, account, subscription } = useAccountContext();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
  useEffect(() => {
    if (scriptLoaded) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, (form: HTMLFormElement) => {
        if (isModernSubscription(subscription)) {
          form.addHiddenFields({
            requestedfeature: requestedFeature,
            planType: subscription.planType,
            subscriptionState: subscription.state,
          });
        }
        // eslint-disable-next-line no-param-reassign
        form = form.setValues({
          Email: profile.email || '',
          FirstName: profile.firstName || '',
          LastName: profile.lastName || '',
          Company: account?.organization || '',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Website_Dr_Button__c: productInterest || '',
        });

        form.onSuccess(() => {
          form.getFormElem().hide();
          setSuccessfullySubmitted(true);
          trackContactSalesFormSubmitted({ url: window.location.href, path: window.location.pathname });
          onFormSubmit?.();

          return false;
        });
      });
    }
    if (window.MktoForms2) {
      return setScriptLoaded(true);
    }

    const script = document.createElement('script');
    script.src = '//go.launchdarkly.com/js/forms2/js/forms2.min.js';
    script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
    document.body.appendChild(script);
  }, [requestedFeature, scriptLoaded]);

  const renderSkeletonFormRow = () => (
    <div className={styles.skeletonScreen}>
      <div className={cx('SkeletonApp--loading', styles.skeletonField)}></div>
      <div className={cx('SkeletonApp--loading', styles.skeletonField)}></div>
    </div>
  );

  window.MktoForms2?.onFormRender(() => {
    //hacky way to hide the skeleton screen once the form finally loads
    if (document.getElementById('renderSkeletonBody')) {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      document.getElementById('renderSkeletonBody')!.style.display =
        'none'; /* eslint-enable @typescript-eslint/no-non-null-assertion */
    }
    const element = document.getElementById('mktoForm_1941');
    element?.classList.remove(styles.hidden);
  });

  const renderPolicyLinks = () => (
    <p className="u-fs-sm">
      By submitting you agree to LaunchDarkly's{' '}
      <NewTabLink
        className={styles.marketoLinks}
        href="https://launchdarkly.com/policies/terms-of-service/"
        text="terms"
      />{' '}
      and{' '}
      <NewTabLink
        className={styles.marketoLinks}
        href="https://launchdarkly.com/policies/privacy/"
        text="privacy policies"
      />
      .
    </p>
  );

  const renderSkeletonBody = () => (
    <div id="renderSkeletonBody">
      {renderSkeletonFormRow()}
      {renderSkeletonFormRow()}
      <div className={cx('SkeletonApp--loading', 'u-flex-center', styles.skeletonCommentField)}></div>
      <div className={styles.skeletonButton}>
        <Button kind="primary">Submit</Button>
      </div>
    </div>
  );

  const renderHeader = () => <h2 className={styles.marketoTitle}>{headerText ?? "We're here to help."}</h2>;

  const renderSkeletonScreen = () => (
    <div>
      {renderHeader()}
      {renderSkeletonBody()}
    </div>
  );

  return (
    <>
      <ModalHeader className={styles.modalHeader} title={null} />
      <ModalBody className={styles.modalBody}>
        <div className={cx(styles.form, { [styles.marketoSubmission]: successfullySubmitted })}>
          {scriptLoaded ? (
            <div className={styles.marketoForm}>
              {!successfullySubmitted ? (
                <>
                  {renderHeader()}
                  <p className={styles.contactSales}>
                    Looking to contact our support team?{' '}
                    <a
                      onClick={() => trackCreateSupportTicket('ContactUsForm')}
                      href="https://support.launchdarkly.com/hc/en-us/requests/new"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Create a support ticket
                    </a>
                  </p>
                  {!window.MktoForms2._polyfillsLoaded && renderSkeletonBody()}
                  <form id={`mktoForm_${formId}`} className={styles.hidden} />
                  {renderPolicyLinks()}
                </>
              ) : (
                <div className={styles.marketoSubmission}>
                  <div>
                    <h1 className="u-fs-xxxl u-pb-s u-fw-bold u-white">Thank you</h1>
                    <p className={styles.expertText}>An expert will be in touch as soon as possible.</p>
                    <p className="u-fs-sm u-pb-s">
                      Our experts typically respond within 2 hours (Mon - Fri, 9am - 6pm PST).
                    </p>
                  </div>
                  <p>
                    <span className="u-fw-bold">Want to learn more?</span> Head over to{' '}
                    <a className={styles.marketoLinks} href="https://launchdarkly.com/pricing/">
                      launchdarkly.com/pricing
                    </a>
                  </p>
                </div>
              )}
            </div>
          ) : (
            <>
              {renderSkeletonScreen()}
              {renderPolicyLinks()}
            </>
          )}
        </div>
      </ModalBody>
    </>
  );
}

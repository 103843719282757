import { Chip } from '@launchpad-ui/core';
import { Icon } from '@launchpad-ui/icons';

import MetricKind, { getMetricKindDisplay, MetricGroupKind } from '../MetricKind';

type MetricLabelProps = {
  kind: MetricKind | MetricGroupKind;
  isNumeric?: boolean;
  unitAggregationType?: 'sum' | 'average';
};

export const MetricLabel = ({ kind, isNumeric, unitAggregationType }: MetricLabelProps) => {
  const { displayIcon, displayName } = getMetricKindDisplay(kind, unitAggregationType, isNumeric);

  return (
    <Chip size="tiny" icon={<Icon name={displayIcon} />}>
      {displayName}
    </Chip>
  );
};

import { TagButton } from '@launchpad-ui/components';

import { SnowflakeSourceTag } from '../SnowflakeSourceTag/SnowflakeSourceTag';

import styles from './MetricSourceTags.module.css';

type MetricSourceTagProps = {
  sources: string[];
};

const getSourceTag = (source: string) => {
  if (source === 'snowflake-experimentation') {
    return <SnowflakeSourceTag key={source} />;
  } else {
    return (
      <TagButton key={source} className={styles.tagButton}>
        {source}
      </TagButton>
    );
  }
};

export const MetricSourceTags = ({ sources }: MetricSourceTagProps) => (
  <span className={styles.tagSpan}>{sources.map((s) => getSourceTag(s))}</span>
);

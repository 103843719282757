import { relativeDateString } from '@gonfalon/datetime';
import { EnvironmentMarker } from '@gonfalon/environments';
import { Icon, IconName } from '@launchpad-ui/icons';

import styles from './MetricLastSeenInfo.module.css';
interface MetricLastSeenInfoProps {
  icon: IconName;
  lastSeen?: number;
  environmentName?: string;
  environmentColor?: string;
}
export const MetricLastSeenInfo: React.FC<MetricLastSeenInfoProps> = ({
  icon,
  lastSeen,
  environmentName,
  environmentColor,
}) => {
  const envSpan = environmentColor ? (
    <span className={styles.lastSeenDesc}>
      <EnvironmentMarker color={environmentColor} /> {environmentName}{' '}
    </span>
  ) : (
    <span>{environmentName}</span>
  );
  return (
    <div className={styles.lastSeenDesc}>
      <Icon name={icon} className={icon === 'status-active' ? styles.active : ''} size="small" />{' '}
      {lastSeen === undefined
        ? 'No events have been recieved for this metric'
        : `Seen ${relativeDateString(lastSeen)} ago `}
      {environmentName && <span className={styles.lastSeenDesc}>in {envSpan}</span>}
    </div>
  );
};

import { Link, To } from 'react-router';

import { trackPendingChangesEvent, WorkflowKind, WorkflowNotificationKind } from 'utils/pendingChangesUtils';

export type WorkflowNotificationLinkProps = {
  forceRefresh?: boolean;
  workflowKind: WorkflowKind;
  notificationKind: WorkflowNotificationKind;
  workflowLink?: To;
  pendingChangesLink?: To;
};

export const LABELS = {
  VIEW_APPROVAL_REQUEST: 'View approval request',
  VIEW_SCHEDULED_CHANGE: 'View scheduled change',
  VIEW_PENDING_CHANGES: 'View pending changes',
} as const;

export function WorkflowNotificationLink({
  forceRefresh,
  workflowKind,
  notificationKind,
  workflowLink,
  pendingChangesLink,
}: WorkflowNotificationLinkProps) {
  let linkText, linkUrl;

  if (notificationKind === WorkflowNotificationKind.LINK_WORKFLOW) {
    linkText =
      workflowKind === WorkflowKind.APPROVAL_REQUEST ? LABELS.VIEW_APPROVAL_REQUEST : LABELS.VIEW_SCHEDULED_CHANGE;
    linkUrl = workflowLink;
  } else if (notificationKind === WorkflowNotificationKind.LINK_PENDING_CHANGES_DRAWER) {
    linkText = LABELS.VIEW_PENDING_CHANGES;
    linkUrl = pendingChangesLink;
  }

  const handleClick = () => {
    trackPendingChangesEvent('Pending Changes Toast Notification Link Clicked', {
      workflowKind,
    });
  };

  return linkText && linkUrl ? (
    <Link to={linkUrl} onClick={handleClick} reloadDocument={forceRefresh}>
      {linkText}
    </Link>
  ) : null;
}

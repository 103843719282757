import { useLocation, useSearchParams } from 'react-router';
import { DateFormat } from '@gonfalon/format';
import { format } from 'date-fns';

import { useCustomWorkflowsContext } from 'components/CustomWorkflows/CustomWorkflowsContext';
import { CustomWorkflowConfig, CustomWorkflowType } from 'components/CustomWorkflows/types';
import { useIsApprovalRequiredForFlagInCurrentEnvironment } from 'reducers/projects';
import { TEMPLATE_SEARCH_PARAM } from 'utils/customWorkflowUtils';
import { Flag } from 'utils/flagUtils';
import { WorkflowTemplateReport } from 'utils/workflowTemplateUtils';

import {
  createWorkflowBuilderApprovalStep,
  createWorkflowBuilderStage,
  doesWorkflowStartWithApprovalStep,
  WorkflowBuilderStateType,
} from '../utils';

import { getMaintenanceWindowInitialState } from './maintenanceWindowUtils';
import { getProgressiveRolloutInitialState } from './progressiveRolloutUtils';
import { convertWorkflowTemplateReportToWorkflowBuilderState } from './workflowFromTemplateUtils';

export type UseWorkflowBuilderInitialStateFromContextProps = { workflowTemplateReport?: WorkflowTemplateReport };
export function useWorkflowBuilderInitialStateFromContext(props: UseWorkflowBuilderInitialStateFromContextProps = {}) {
  const { flag, flagConfiguration } = useCustomWorkflowsContext();

  const startWorkflowWithApprovalIfRequired = useStartWorkflowWithApprovalIfRequiredCallback();
  const customWorkflowConfig = useCustomWorkflowConfigFromRouter();
  const isProgressiveRollout = customWorkflowConfig?.customWorkflowType === CustomWorkflowType.PROGRESSIVE_ROLLOUT;
  const isMaintenanceWindow = customWorkflowConfig?.customWorkflowType === CustomWorkflowType.MAINTENANCE_WINDOW;

  let initialState: WorkflowBuilderStateType = getDefaultInitialState({ flag });

  if (isProgressiveRollout) {
    initialState = getProgressiveRolloutInitialState({
      config: customWorkflowConfig.config,
      initialState,
      flag,
      flagConfiguration,
    });
  } else if (isMaintenanceWindow) {
    initialState = getMaintenanceWindowInitialState({ config: customWorkflowConfig.config, initialState, flag });
  } else if (props.workflowTemplateReport) {
    initialState = convertWorkflowTemplateReportToWorkflowBuilderState({
      name: initialState.name,
      description: initialState.description,
      workflowTemplateReport: props.workflowTemplateReport,
    });
  }

  initialState = startWorkflowWithApprovalIfRequired(initialState);

  return initialState;
}

function getDefaultInitialState({ flag }: { flag: Flag }): WorkflowBuilderStateType {
  return {
    name: `Workflow ${flag?.name} ${format(new Date(), DateFormat.MM_DD_YYYY)}`,
    stages: [createWorkflowBuilderStage('Stage 1', [])],
    type: CustomWorkflowType.CUSTOM_WORKFLOW,
  };
}

function useStartWorkflowWithApprovalIfRequiredCallback() {
  const { flag } = useCustomWorkflowsContext();
  const approvalsRequiredForFlag = useIsApprovalRequiredForFlagInCurrentEnvironment(flag);

  return (state: WorkflowBuilderStateType) => {
    if (approvalsRequiredForFlag && !doesWorkflowStartWithApprovalStep(state.stages)) {
      state.stages[0].steps.unshift(createWorkflowBuilderApprovalStep());
    }

    return state;
  };
}

function useCustomWorkflowConfigFromRouter(): CustomWorkflowConfig | undefined {
  const location = useLocation();
  const state = location.state as CustomWorkflowConfig | undefined;
  return state || undefined;
}

export function useWorkflowTemplateKeyURLSearchParam() {
  const [searchParams] = useSearchParams();

  return searchParams.get(TEMPLATE_SEARCH_PARAM) ?? undefined;
}
